import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import { Container } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import "../../reacttable/reactTable.css"
import { fetchAllProjectApi, getNewTicketListAction, updateProjectStatusAction } from "../../../../store/actions/projectAction";
import moment from "moment";
import Table from "../../reacttable/table";
import { FaEdit, FaEye } from "react-icons/fa";
import TanStackTable from "../../reacttable/TanStackTable";

function ProjectList(props) {
    useEffect(() => {
        props.fetchAllProject()
    }, [])

    const data = props.list && props.list.data
    // console.log("data", data)

    const handleStatusChange = (id, e) => {
        const status = e.target.value;
        if (status) props.updateProjectStatus(id, status);
    };

    if (props.updateProjectStatusRes.statuscode === 200) {
        window.location.reload();
    }

    const columns = useMemo(
        () => [
            {
                header: 'First Name',
                accessorKey: 'contact_data.firstname',
            },
            {
                header: 'Last Name',
                accessorKey: 'contact_data.lastname',
            },
            {
                header: 'Email',
                accessorKey: 'contact_data.email',
            },
            {
                header: 'Project Title',
                accessorKey: 'project_title',
            },
            {
                header: 'Mobile',
                accessorKey: 'contact_data.mobile',
            },
            {
                header: 'Address',
                accessorKey: 'contact_data.address',
            },
            {
                header: 'Creation Date',
                id: 'createat',
                accessorFn: (d) => moment(d.createat).local().format('DD-MM-YYYY'),
            },
            {
                header: 'Last Updated',
                id: 'updateat',
                accessorFn: (d) => moment(d.updateat).local().format('DD-MM-YYYY'),
            },
            {
                header: 'Status',
                id: 'status',
                accessorKey: 'status',
                cell: ({ row }) => {
                    const { status, id } = row.original;
                    return (
                        <select
                            onChange={(e) => handleStatusChange(id, e)}
                            defaultValue={status}
                        >
                            <option value="" disabled hidden>
                                {status}
                            </option>
                            <option value="New">New</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Lost">Lost</option>
                            <option value="Completed">Completed</option>
                        </select>
                    );
                },
            },
            {
                header: 'Actions',
                accessorKey: 'id',
                cell: ({ row }) => {
                    const { id } = row.original;
                    return (
                        <div>
                            <a href={`/dashboard/view_project/viewSingleProject/${id}`} title="View">
                                <FaEye />
                            </a>
                            &nbsp;&nbsp;
                            <a href={`/dashboard/updateProject/${id}`} title="Edit">
                                <FaEdit />
                            </a>
                        </div>
                    );
                },
            },
        ],
        []
    );


    return (
        <Container fluid >

            <div className="">
                {props.loading ? (
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh" }}>
                        <h3>Loading</h3>
                        <Loader
                            type="ThreeDots"
                            color="#232959"
                            height="40"
                            width="40"
                        />
                    </div>
                ) : data && data.length > 0 ? (
                    // <Table data={data} columns={columns} />
                    <TanStackTable data={data} columns={columns} />
                ) : (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
                        <h3>No data found</h3>
                    </div>
                )}
            </div>
        </Container>
    )

}

const mapStateToProps = (state) => {
    return {
        list: state.project.fetchAllProject,
        updateProjectStatusRes: state.project.updateProjectStatus
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchAllProject: () => dispatch(fetchAllProjectApi()),
        updateProjectStatus: (id, status) => dispatch(updateProjectStatusAction(id, status)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);