import { CUSTOMER_BASE_URL, FETCH_ALL_FINANCE_URL, FETCH_FINANCE_NUMBER_OF_MONTHS, FETCH_PROJECT_LIST_WITH_EMAIL, FETCH_SINGLE_FINANCE_URL, PAYMENT_URL, SAVE_FINANCE_URL, UPDATE_FINANCE_URL } from "../../components/config/config";

// FETCH_MONTH_COUNT_API
export const fetchFinanceNoOfMonthsAction = () => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;

    return async dispatch => {
        dispatch({ type: 'FINANCE_LOADING', payload: '' });

        try {
            const response = await fetch(PAYMENT_URL + FETCH_FINANCE_NUMBER_OF_MONTHS, {
                method: "GET",
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            dispatch({ type: 'FETCH_NO_OF_MONTHS', payload: data });
        } catch (error) {
            console.error('Error fetching finance number of months:', error);
        }
    }
}

// FETCH PROJECT LIST VIA EMAIL
export const fetchProjectListWithEmailAction = (email) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;

    return async dispatch => {
        dispatch({ type: 'FINANCE_LOADING', payload: '' });

        try {
            const response = await fetch(CUSTOMER_BASE_URL + FETCH_PROJECT_LIST_WITH_EMAIL + '?email=' + email, {
                method: "GET",
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            dispatch({ type: 'FETCH_PROJECT_LIST_REDUCER', payload: data });
        } catch (error) {
            console.error('Error fetching project list:', error);
        }
    }
}

// FETCH_ALL_FINANCE_API
export const fetchAllFinanceAction = () => {
    const { token, role } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;

    return async dispatch => {
        dispatch({ type: 'FINANCE_LOADING', payload: '' });

        try {
            const response = await fetch(PAYMENT_URL + FETCH_ALL_FINANCE_URL + "?role=" + role, {
                method: "GET",
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            dispatch({ type: 'FETCH_ALL_FINANCE_REDUCER', payload: data });
        } catch (error) {
            console.error('Error fetching finance number of months:', error);
        }
    }
}

// Save FINANCE 
export const saveFinanceAction = (data) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    var bearer = 'basic ' + token;

    const {
        payment_due_date,
        payment_type,
        is_interest_free,
        interest_rate,
        final_project_amount,
        project_amount,
        no_of_months,
        project_title,
        emailid
    } = data;

    return async dispatch => {
        dispatch({ type: 'FINANCE_LOADING', payload: '' });

        try {
            const response = await fetch(CUSTOMER_BASE_URL + SAVE_FINANCE_URL, {
                method: "POST",
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({
                    "emailid": emailid,
                    "project_title": project_title,
                    "project_amount": project_amount,
                    "payment_type": payment_type,
                    "final_project_amount": final_project_amount,
                    "no_of_months": no_of_months,
                    "is_interest_free": is_interest_free === 'true' ? true : false,
                    "interest_rate": interest_rate,
                    "payment_due_date": payment_due_date,
                    "role": role,
                    "organisation": organisation
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            dispatch({ type: 'SAVE_FINANCE_REDUCER', payload: data });
        } catch (error) {
            console.error('Error posting finance data:', error);
        }
    }
}

// FETCH_SINGLE_FINANCE_API
export const fetchSingleFinanceAction = (id) => {
    const { token, role } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;

    return async dispatch => {
        dispatch({ type: 'FINANCE_LOADING', payload: '' });

        try {
            const response = await fetch(PAYMENT_URL + FETCH_SINGLE_FINANCE_URL + "?id=" + id, {
                method: "GET",
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            // console.log("data", data)
            dispatch({ type: 'FETCH_SINGLE_FINANCE_REDUCER', payload: data });
        } catch (error) {
            console.error('Error fetching single finance', error);
        }
    }
}

// UPDATE FINANCE 
export const updateFinanceAction = (id, data) => {
    const { token, role, organisation, userid } = JSON.parse(localStorage.getItem('user'));
    var bearer = 'basic ' + token;
    const {
        payment_due_date,
        payment_type,
        is_interest_free,
        interest_rate,
        final_project_amount,
        project_amount,
        no_of_months,
        project_title,
        emailid
    } = data;

    // Get today's date in the required format
    const today = new Date();
    const updatedAt = today.toISOString(); // This gives the format "YYYY-MM-DDTHH:MM:SSZ"
    const finance_updates = [
        {
            updated_by: userid,
            updatedat: updatedAt
        }
    ];

    return async dispatch => {
        dispatch({ type: 'FINANCE_LOADING', payload: '' });

        try {
            const response = await fetch(CUSTOMER_BASE_URL + UPDATE_FINANCE_URL, {
                method: "POST",
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({
                    "id": id,
                    "emailid": emailid,
                    "project_title": project_title,
                    "project_amount": project_amount,
                    "payment_type": payment_type,
                    "final_project_amount": final_project_amount,
                    "no_of_months": no_of_months,
                    "is_interest_free": is_interest_free === 'true' ? true : false,
                    "interest_rate": interest_rate,
                    "payment_due_date": payment_due_date,
                    "finance_updates": finance_updates,
                    "role": role,
                    "organisation": organisation
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            // console.log("update data", data);
            dispatch({ type: 'UPDATE_FINANCE_REDUCER', payload: data });
        } catch (error) {
            console.error('Error updating finance data:', error);
        }
    }
}