import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { fetchCityAction, fetchCoutryAction, fetchNewPrivilegeAction, fetchPrivilegeAction, fetchRoleAction, fetchStateAction, fetchSubRolesAction } from "../../../../store/actions/mainAction";
import { saveAccessControlAction } from "../../../../store/actions/accessAction";
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import Geocode from "react-geocode";
import "./accessManagement.css"

// var privilegeArray = [];
var privilegeArray = [];
var menu = [];

function AccessManagement(props) {
    const [address, setAddress] = useState("")
    const [globalPrivilageParentKey, setGlobalPrivilageParentKey] = useState("")
    const [newRole, setNewRole] = useState("");
    const [newPrivilege, setNewPrivilege] = useState([]);
    const [newSubPrivilege, setSubNewPrivilege] = useState([]);
    const [apiLoaded, setApiLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Define the initMap function that the Google Maps script will call
        window.initMap = () => {
            setApiLoaded(true);
        };

        // Check if the script is already loaded
        if (window.google && window.google.maps) {
            setApiLoaded(true);
        }
    }, [window]);


    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required('Firstname is required'),
        last_name: Yup.string().required('Lastname is required'),
        userid: Yup.string().required('Unique UserID is required'),
        password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters').max(40, 'Password must not exceed 40 characters'),
        role: Yup.string().required('Role is required'),
        address: Yup.string().required('Address is required'),
        email: Yup.string().required('Email is required').email('Email is invalid'),
        phone: Yup.number().required('Phone number is a required').typeError('Phone number can only be a number').test('len', 'Phone number must be greater than  6 digits and less than or equal to 10 digits', val => val?.toString().length > 6 && val?.toString().length <= 10),
        organisation: Yup.string().required('Organisation is required'),
        organisation_type: Yup.string().required('Organisation Type is required'),
        city: Yup.string().required('City  is required'),
        state: Yup.string().required('State/Province  is required'),
        country: Yup.string().required('Country is required'),
        postal_code: Yup.string().required('Zip code is a required').typeError('Zip code can only be a number')
    });

    useEffect(() => {
        props.getrole();
        reset();
    }, [])

    // This logic is used for selecting all sub-privileges by default when you checked privilages
    useEffect(() => {

        const filteredPrivileges = props.allNewPrivileges?.privileges?.filter(item => item.key === globalPrivilageParentKey);
        // console.log("filteredPrivileges: ", filteredPrivileges)
        // Storing an empty array for sub-role in privilageData state
        const separatedMenu = filteredPrivileges && filteredPrivileges.map(item => {
            const { subrole: itemMenu, ...rest } = item;
            return { ...rest, subrole: [] };
        });
        // console.log("separated menu", menu)
        privilegeArray = privilegeArray.concat(separatedMenu);
        // setPrivilageData(prevState => prevState.concat(separatedMenu));
        setNewPrivilege(privilegeArray);

        // Extract and store menu array separately
        const extractedMenu = filteredPrivileges && filteredPrivileges.flatMap(item => item.subrole);
        menu = menu.concat(extractedMenu);
        // setSubMenu(prevState => prevState.concat(extractedMenu));
        setSubNewPrivilege(menu)


        // this logic is here is used to checked all the sub-privileges after you just checked the privilages
        if (globalPrivilageParentKey && filteredPrivileges && filteredPrivileges.length > 0) {
            filteredPrivileges.forEach(item => {
                const subPrivilegeCheckboxes = document.querySelectorAll(`.${item.key}`);
                if (subPrivilegeCheckboxes.length > 0) {
                    subPrivilegeCheckboxes.forEach(checkbox => {
                        checkbox.checked = true;
                    });
                }
            });
        }

    }, [globalPrivilageParentKey, props.allNewPrivileges]);

    // This useEffect is used to prevent the checked issue after you select the privilage and change the role 
    useEffect(() => {
        if (newRole && props.allNewPrivileges?.privileges?.length > 0) {
            props.allNewPrivileges?.privileges.forEach(item => {
                const subPrivilegeCheckboxes = document.querySelectorAll(`.${item.key}`);
                if (subPrivilegeCheckboxes.length > 0) {
                    subPrivilegeCheckboxes.forEach(checkbox => {
                        checkbox.checked = false;
                        checkbox.disabled = true;

                    });
                }
            });
        }
        setNewPrivilege([])
        setSubNewPrivilege([])
        privilegeArray = [];
        menu = [];

    }, [newRole, props.allNewPrivileges])


    const { register, handleSubmit, setValue, getValues, reset, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema), mode: "all" });


    // Geolocation placeAutoComplete logic
    const handleSelect = async (address) => {
        try {
            const results = await geocodeByAddress(address);
            if (!results || results.length === 0) {
                throw new Error('No results found');
            }

            const latLng = await getLatLng(results[0]);
            const { lat, lng } = latLng;

            const res = await latiLongi(lat, lng);
            if (!res || !res.results || res.results.length === 0) {
                throw new Error('No response from latiLongi');
            }

            setError('')
            setAddress(address);
            setValue("address", address);
            setValue("latitude", lat);
            setValue("longitude", lng);

            let city, state, country, postCode;
            res.results[0].address_components.forEach((component) => {
                component.types.forEach((type) => {
                    switch (type) {
                        case "locality":
                            city = component.long_name;
                            setValue("city", city);
                            break;
                        case "administrative_area_level_1":
                            state = component.long_name;
                            setValue("state", state);
                            break;
                        case "country":
                            country = component.long_name;
                            setValue("country", country);
                            break;
                        case "postal_code":
                            postCode = component.long_name;
                            setValue("postal_code", postCode);
                            break;
                        default:
                            break;
                    }
                });
            });
        } catch (error) {
            console.error('Error:', error);
            setError('Failed to retrieve location details. Please try again.');
        }
    };


    const handleOnchange = (e) => {
        // setGlobalSubPrivilageKey("")
        const singleValue = e.target.value;
        setNewRole(singleValue)
        setValue("userRole", singleValue)
        setNewPrivilege([])
        setSubNewPrivilege([])
        privilegeArray = [];
        menu = [];
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach(checkbox => {
            checkbox.checked = false;
        });

        const result = props.roles && props.roles.roles.filter((rp => rp.value === singleValue));
        // console.log(result)
        if (singleValue !== "" && result.length) {
            setNewRole(result[0].key)
            props.get_new_privileges(result[0].value)
        }
    }

    const handleOnPrivilegechange = (e) => {
        const target = e.target
        let privilegeKey = e.target.id;
        // console.log("privilegeKey", privilegeKey)
        if (target.checked) {
            setGlobalPrivilageParentKey(privilegeKey)
        } else {
            const removeIndex = privilegeArray.findIndex(item => item.key === privilegeKey);
            privilegeArray.splice(removeIndex, 1);
            setGlobalPrivilageParentKey("")
        }
        setNewPrivilege(privilegeArray)

    }
    // console.log("newPrivilege", newPrivilege)
    // console.log("newSubPrivilege", newSubPrivilege)
    // console.log("privilegeArray", privilegeArray)
    // console.log("menu", menu)

    const handleOnSubPrivilegechange = (e) => {
        const target = e.target;
        let subprivilegeKey = target.id;

        const result = props.allNewPrivileges && props.allNewPrivileges.privileges.flatMap((privilege) => {
            return privilege.subrole && privilege.subrole.filter((subPrivilege) => subPrivilege.name === subprivilegeKey);
        });

        if (target.checked) {
            menu.push({ parent_key: result[0].parent_key, name: result[0].name, value: result[0].value, icon: result[0].icon })
        } else {
            for (let i = menu.length - 1; i >= 0; i--) {
                if (menu[i].name === subprivilegeKey) {
                    menu.splice(i, 1);
                }
            }
        }
        setSubNewPrivilege(menu)
    }

    const onSubmit = async data => {

        // here we are using Set Data structure to remove the duplicates from the array with the help of parent_key and key
        const uniqueKeys = new Set();
        const uniqueParentkey = new Set();

        const uniquePrivilageData = newPrivilege && newPrivilege.reduce((acc, curr) => {

            if (!uniqueParentkey.has(curr.key)) {
                uniqueParentkey.add(curr.key);
                acc.push(curr);
            }
            return acc;
        }, []);

        const uniqueMenuData = newSubPrivilege && newSubPrivilege.reduce((acc, curr) => {
            if (!uniqueKeys.has(curr.name)) {
                uniqueKeys.add(curr.name);
                acc.push(curr);
            }
            return acc;
        }, []);
        // console.log("uniquePrivilageData", uniquePrivilageData)
        // console.log("uniqueMenuData", uniqueMenuData)

        // remove duplicates logic Ends here...........

        let cartCopy = [...uniquePrivilageData]
        uniquePrivilageData.forEach(el => {
            let subrole = []
            uniqueMenuData.forEach(sel => {
                if (el.key === sel.parent_key) {
                    subrole.push(sel)
                }
            })
            el.subrole = subrole
            setNewPrivilege(cartCopy)
        })

        const emptysubPrivilege = newPrivilege && newPrivilege.filter((privilege) => {
            return privilege.subrole && privilege.subrole.length === 0;
        });
        // console.log("emptysubPrivilege", emptysubPrivilege);

        const filterPrivilege = newPrivilege && newPrivilege.filter((privilege) => {
            return privilege.subrole && privilege.subrole.length > 0;
        });
        // console.log("filterPrivilege", filterPrivilege);

        if (emptysubPrivilege.length > 0) {
            alert('Please select the sub-privileges')
        } else if (filterPrivilege !== "") {
            // console.log("data", data, filterPrivilege)
            props.saveUser(data, filterPrivilege)
        } else if (filterPrivilege.length === 0) {
            alert('Please select at-least One Privilege and sub-privileges')
        }
    };


    if (props.saveuser && props.saveuser.statuscode === 200) {
        alert("Account has been saved successfully!")
        window.location.reload();
    }
    if (props.saveuser && props.saveuser.statuscode === 405) {
        alert(props.saveuser.result)
        window.location.reload();
    }
    // console.log("props.saveuser", props.saveuser)

    // console.log("privilages", props.allNewPrivileges && props.allNewPrivileges.privileges)

    return (
        <div>
            <form onSubmit={e => e.preventDefault()}>
                <>

                    <Row>
                        <Col xs={12} md={6} className="mt-3">
                            <div>
                                <input type="text" className={` ${errors.first_name ? 'is-invalid' : ''}`} name="first_name" id="floatingInputGridFName" placeholder="First Name" {...register("first_name")} />
                                <div className="invalid-feedback">{errors.first_name?.message}</div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="mt-3">
                            <div>
                                <input type="text" className={` ${errors.last_name ? 'is-invalid' : ''}`} name="last_name" id="floatingInputGridLName" placeholder="Last Name" {...register("last_name")} />
                                <div className="invalid-feedback">{errors.last_name?.message}</div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} className="mt-3">
                            <div className="form-floating">
                                <input type="text" className={` ${errors.userid ? 'is-invalid' : ''}`} name="userid" id="floatingInputGridUn" placeholder="User ID" {...register("userid")} />
                                <div className="invalid-feedback">{errors.userid?.message}</div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="mt-3">
                            <div className="form-floating">
                                <input type="password" className={` ${errors.password ? 'is-invalid' : ''}`} id="floatingInputGridPassword" name="password" placeholder="Temporary password" {...register("password")} />
                                <div className="invalid-feedback">{errors.password?.message}</div>
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col xs={12} md={6} className="mt-3">
                            <input type="email" className={` ${errors.email ? 'is-invalid' : ''}`} id="floatingInputGridEmail" name="email" placeholder="Email" {...register("email")} />
                            <div className="invalid-feedback">{errors.email?.message}</div>
                        </Col>
                        <Col xs={12} md={6} className="mt-3">
                            <input type="number" className={` ${errors.phone ? 'is-invalid' : ''}`} name="phone" id="floatingInputGridPhone" placeholder="Phone no" {...register("phone")} />
                            <div className="invalid-feedback">{errors.phone?.message}</div>
                        </Col>
                    </Row>
                    <Row >
                        <Col xs={12} md={6} className="mt-3">
                            <input type="text" className={` ${errors.organisation ? 'is-invalid' : ''}`} id="floatingInputGridOrganisation" name="organisation" placeholder="Organisation" {...register("organisation")} />
                            <div className="invalid-feedback">{errors.organisation?.message}</div>
                        </Col>
                        <Col xs={12} md={6} className="mt-3">
                            <input type="text" className={` ${errors.organisation_type ? 'is-invalid' : ''}`} name="organisation_type" id="floatingInputGridOrganisation_type" placeholder="Organisation Type" {...register("organisation_type")} />
                            <div className="invalid-feedback">{errors.organisation_type?.message}</div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={12}>
                            <Card.Title>
                                <span>Address Information</span>
                            </Card.Title>
                            {error && <div className="alert alert-danger">{error}</div>}
                            {apiLoaded ? (
                                <PlacesAutocomplete
                                    value={address}
                                    onChange={(value) => setAddress(value)}
                                    onSelect={handleSelect}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Search Places ...',
                                                    className: `location-search-input ${errors.address ? 'is-invalid' : ''}`
                                                })}
                                                name="address"
                                            />
                                            <div className="invalid-feedback">{errors.address?.message}</div>
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}

                                                {suggestions.map((suggestion, index) => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                    return (
                                                        <div
                                                            key={index}
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                            })}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            ) : (
                                <div>Loading Google Maps...</div>
                            )}
                        </Col>
                    </Row>
                    <Row >
                        <Col xs={12} md={3} className="mt-3">
                            <input
                                type="text"
                                className={` ${errors.country ? 'is-invalid' : ''}`}
                                name="country"
                                id="floatingInputcountry"
                                placeholder="Country"
                                {...register("country")}

                            />
                            <div className="invalid-feedback">{errors.country?.message}</div>
                        </Col>
                        <Col xs={12} md={3} className="mt-3">
                            <input
                                type="text"
                                className={` ${errors.state ? 'is-invalid' : ''}`}
                                name="state"
                                id="floatingInputstate"
                                placeholder="State / Province"
                                {...register("state")}

                            />
                            <div className="invalid-feedback">{errors.state?.message}</div>
                        </Col>
                        <Col xs={12} md={3} className="mt-3">
                            <input
                                type="text"
                                className={` ${errors.city ? 'is-invalid' : ''}`}
                                name="city"
                                id="floatingInputcity"
                                placeholder="City"
                                {...register("city")}

                            />
                            <div className="invalid-feedback">{errors.city?.message}</div>
                        </Col>
                        <Col xs={12} md={3} className="mt-3">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className={` ${errors.postal_code ? 'is-invalid' : ''}`}
                                    name="postal_code"
                                    id="floatingInputGridPostalcode"
                                    placeholder="Postal code"
                                    {...register("postal_code")}

                                />
                                <div className="invalid-feedback">{errors.postal_code?.message}</div>
                            </div>
                        </Col>
                    </Row>
                </>
                <div className="row d-flex justify-content-between">
                    <div className="col-lg-12 col-sm-12">
                        <Card.Text className="mt-2"><b>{("Roles")}  <span className='mandatory-field'>*</span></b></Card.Text>
                        <div className=''>
                            <div className="form-floating" >
                                <select className={`input_css ${errors.role ? 'is-invalid' : ''}`} name={`role`} id="floatingSelectGridrole" {...register("role", { onChange: (e) => handleOnchange(e) })}>
                                    <option value="">{("Select Role")}</option>
                                    {props.roles && props.roles.roles && props.roles.roles.map((item, index) => (
                                        <option key={index} value={item.value}>{item.key}</option>
                                    ))}
                                </select>
                                <div className="invalid-feedback">{errors.role?.message}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <>
                    {props.allNewPrivileges.statuscode === 200 &&
                        <Row className="mt-2">
                            <Col>
                                <h4>
                                    Privileges
                                </h4>
                            </Col>
                            <Col>
                                <h4>
                                    Sub-Privileges
                                </h4>
                            </Col>
                        </Row>
                    }
                    {props.allNewPrivileges && props.allNewPrivileges.privileges && props.allNewPrivileges.privileges.map((item, i) => (
                        <Row key={i}>
                            <Col lg={3}>
                                <div className="my_Privilagecontainer" key={i}>
                                    <div className="">
                                        <input className={``} style={{ marginTop: "1.1rem" }}
                                            type="checkbox"
                                            id={item.key}
                                            onChange={((e) => handleOnPrivilegechange(e))}
                                        />
                                        <label style={{ marginLeft: "1rem", marginTop: "0.9rem" }} className="form-check-label" htmlFor={item.key}>{item.value}</label>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={9}>
                                <div className="my_SubPrivilagecontainer" id='subcontainer'>
                                    {item.subrole && item.subrole.map((sub_item, index) => (
                                        <div className=" mr-2 " key={index}>
                                            &nbsp;
                                            <input className={` ${item.key}`}
                                                type="checkbox"
                                                id={sub_item.name}
                                                onChange={((e) => handleOnSubPrivilegechange(e))}
                                                disabled={globalPrivilageParentKey !== sub_item.parent_key}
                                            />
                                            &nbsp;
                                            <label style={{ marginLeft: "0.5rem" }} className="form-check-label" htmlFor={sub_item.name}>{sub_item.value}</label>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    ))}

                </>

                <br />
                <Row className="mt-3">
                    <Col lg={6} md={6} className="mt-2">
                        <button className="lgn-btn" onClick={() => window.location.reload()}>RESET</button>
                    </Col>
                    <Col lg={6} md={6} className='mt-2 d-md-flex justify-content-end '>
                        {props.loading ?
                            <button className="lgn-btn" type="button" disabled>
                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                &nbsp;
                                {("Saving...")}
                            </button>
                            :
                            <button className="lgn-btn" type="submit" onClick={handleSubmit(onSubmit)}>{("CREATE ACCOUNT")}</button>
                        }
                    </Col>
                </Row>
            </form>

        </div >

    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.access.isLoading,
        roles: state.main.roles,
        saveuser: state.access.saveAccessControl,
        allNewPrivileges: state.main.getNewPrivileges,
        //   list: state.operator.organisationsList
    }
}
const mapDispatchToProps = dispatch => {
    return {

        getrole: () => dispatch(fetchRoleAction()),
        get_new_privileges: (role) => dispatch(fetchNewPrivilegeAction(role)),
        saveUser: (userdata, newPrivilege) => dispatch(saveAccessControlAction(userdata, newPrivilege))
        //   getOrg:()=>dispatch(allOrganisationAction()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccessManagement)
const latiLongi = (a, b) => {
    return Geocode.fromLatLng(a, b).then(res => { return res })
    // console.log("Gaadin",res); 
}