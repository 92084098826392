import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import './sideicons.css';
import { MdKeyboardArrowRight, MdKeyboardArrowDown, MdDoubleArrow } from "react-icons/md";
import { Col, Row } from "react-bootstrap";

const SideToolBar = () => {
  const { access } = JSON.parse(localStorage.getItem('user'));
  const [openDropdown, setOpenDropdown] = useState(null);
  const match = useRouteMatch();
  // console.log(access)


  // logic to add id key and sort it based on ID in the access JSON Starts here
  const keyToMapId = {
    'home': 1,
    'lead': 2,
    'pricing': 3,
    'project': 4,
    'customer': 5,
    'finance': 6,
    'campaign': 7,
    'admin': 8,
  };

  const modifiedAccess = access && access.map(item => ({
    ...item,
    id: keyToMapId[item.key] || null
  }));

  const newAccess = modifiedAccess && modifiedAccess.sort((a, b) => a.id - b.id);
  // console.log("newAccess", newAccess)

  // logic to add id key and sort it based on ID in the access JSON Ends here

  const handleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };
  // console.log("openDropdown", openDropdown)

  return (
    <ul className="unordered-list glassbackground pt-3">
      {newAccess && newAccess.map((item, index) => (
        <li className="list-item" key={index}>
          <div onClick={() => handleDropdown(index)} className="">
            <Row className="d-flex align-items-center">
              <Col xs="auto" className="d-flex align-items-center">
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + '/images/sidebar/' + item.icon}
                  style={{ width: "35px" }}
                  alt="sidebar"
                />
                <span className="title" style={{ color: "#000000", marginLeft: "10px", fontWeight: "700" }}>
                  {item.value}
                </span>
              </Col>
              <Col xs="auto" className="ml-auto">
                <span className="arrow-icon">
                  {openDropdown !== index ?
                    <MdKeyboardArrowRight />
                    :
                    <MdKeyboardArrowDown />
                  }
                </span>
              </Col>
            </Row>
          </div>
          {openDropdown === index && item.subrole && (
            <ul className="sub-menu">
              {item.subrole.map((sub, subIndex) => (
                <li key={subIndex} className="sub-list-item pl-4">
                  <Link to={`${match.url}/${sub.name}`}>
                    <span className="icon">
                      <MdDoubleArrow />
                    </span>
                    <span className="title" style={{ color: "#000000" }}>
                      {sub.value}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

export default SideToolBar;
