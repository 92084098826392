import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Geocode from "react-geocode";
import PureBreadcrumbs from "../../Breadcrums.js"
import "react-datepicker/dist/react-datepicker.css";
import "./leadGeneration.css"
import { fetchSingleLeadAction } from "../../../../store/actions/leadAction.js";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";


const ViewSingleLead = (props) => {
    const { id } = useParams();
    const [address, setAddress] = useState("")
    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`; // Return in dd/mm/yyyy hh:mm:ss format
    };

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required('Firstname is required'),
        lastname: Yup.string().required('Lastname is required'),
        email: Yup.string().required('Email is required').email('Email is invalid'),
        mobile: Yup.number().required('Phone number is a required').typeError('Phone number is a required'),
        address: Yup.string().required('Address is required'),
        country: Yup.string().required('Country is required'),
        state: Yup.string().required('State/Province  is required'),
        city: Yup.string().required('City  is required'),
        latitude: Yup.number().required('Latitude code is a required').typeError('Latitude code can only be a number'),
        longitude: Yup.number().required('Latitude code is a required').typeError('Longitude code can only be a number'),
        postal_code: Yup.string().required('Zip code is a required'),
        assign_to: Yup.string().required('Assign To is required'),
        description: Yup.string().required('Description Type is required'),
        project_status: Yup.string().required('Projet Status is required'),
        leadsource: Yup.string().required('Lead Source is required'),
    });

    useEffect(() => {
        props.fetchSingleLeadApi(id);
        reset();
    }, [id])

    const { register, reset, setValue, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema), mode: "all" });

    const data = props.fetchSingleLeadRes && props.fetchSingleLeadRes.data;
    // console.log("data", data);

    useEffect(() => {
        if (data) {
            const {
                firstname,
                lastname,
                email,
                mobile,
                address,
                country,
                state,
                city,
                postal_code,
                country_code,
                lead_status,
                leadsource,
                description,
                coordinates: { longitude, latitude } = {},
            } = data;

            setValue("firstname", firstname);
            setValue("lastname", lastname);
            setValue("email", email);
            setValue("mobile", mobile);
            setValue("address", address);
            setAddress(address)
            setValue("country", country);
            setValue("state", state);
            setValue("city", city);
            setValue("postal_code", postal_code);
            setValue("longitude", longitude);
            setValue("latitude", latitude);
            setValue("project_status", lead_status);
            setValue("description", description);
            setValue("countryCode", country_code);
            setValue("description", description);
            setValue("leadsource", leadsource)
        }
    }, [data, setValue]);

    return (
        <Container fluid>
            <div className="row">
                <div className="col-lg-12 col-sm-12 mt-2">
                    <PureBreadcrumbs />
                </div>
            </div>
            <Row className="mb-2">
                <Col className="mt-3">
                    <h4>View Lead</h4>
                </Col>
            </Row>
            <form onSubmit={e => e.preventDefault()} >
                <Row className="d-flex justify-content-between">
                    <Col lg={8} className="mb-2">
                        <Card className="tariffcard">
                            <Card.Body>
                                <Row className="py-2">
                                    <Col>
                                        <Card.Title>
                                            <span>Customer</span>
                                        </Card.Title>

                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <label htmlFor="floatingInputGridName" >First Name</label>
                                                <input
                                                    type="text"
                                                    className={` `}
                                                    name="firstname"
                                                    id="floatingInputGridName"
                                                    placeholder="Firstname"
                                                    {...register("firstname")}
                                                    disabled

                                                />
                                            </Col>
                                            <Col md={6}>
                                                <label htmlFor="floatingInputGridLName" >Last Name</label>

                                                <input
                                                    type="text"
                                                    className={` `}
                                                    name="lastname"
                                                    id="floatingInputGridLname"
                                                    placeholder="Lastname"
                                                    {...register("lastname")}
                                                    disabled

                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <label htmlFor="floatingInputGridEmail">Email</label>

                                                <input
                                                    type="text"
                                                    className={` `}
                                                    name="email"
                                                    id="floatingInputGridEmail"
                                                    placeholder="Email"
                                                    {...register("email")}
                                                    disabled

                                                />
                                            </Col>
                                            <Col md={6}>
                                                <label htmlFor="floatingInputGridMobile" >Mobile No.</label>

                                                <input
                                                    type="text"
                                                    className={` `}
                                                    name="mobile"
                                                    id="floatingInputGridMobile"
                                                    placeholder="Mobile number"
                                                    {...register("mobile")}
                                                    disabled

                                                />
                                            </Col>
                                        </Row>

                                        {/* <Row className="mt-3">
                                            <Col>
                                                <label htmlFor="floatingInputGridisResidential" >Is Residential ?</label>

                                                <select
                                                    className={`input_css `}
                                                    name="property_type"
                                                    id="floatingInputGridisResidential"
                                                    {...register("isResidential")}
                                                    disabled
                                                >
                                                    <option value="">Is Residential ?</option>
                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </select>
                                            </Col>
                                            <Col>
                                                <label htmlFor="floatingInputGridgender" >Gender</label>

                                                <select
                                                    className={`input_css`}
                                                    name="property_type"
                                                    id="floatingInputGridgender"
                                                    {...register("gender")}
                                                    disabled
                                                >
                                                    <option value={'0'}>Select Your Gender</option>
                                                    <option value={'2'}>Male</option>
                                                    <option value={'1'}>Female</option>
                                                </select>
                                            </Col>
                                            <Col className="">
                                                <label htmlFor="floatingInputGriddob" >Date of Birth</label>

                                                <input
                                                    type="text"
                                                    className={` `}
                                                    name="dob"
                                                    id="floatingInputGriddob"
                                                    placeholder="Date of birth"
                                                    value={dateOfBirth}
                                                    disabled
                                                />
                                            </Col>
                                        </Row> */}

                                        <Row className="mt-3">
                                            <Card.Title>
                                                <span>Address Information</span>
                                            </Card.Title>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <label htmlFor="floatingInputState" >State/Proviance</label>

                                                <input
                                                    type="text"
                                                    className={` `}
                                                    name="state"
                                                    id="floatingInputState"
                                                    placeholder="State / Province"
                                                    {...register("state")}
                                                    disabled
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <label htmlFor="floatingInputCountry" >Country</label>

                                                <input
                                                    type="text"
                                                    className={` `}
                                                    name="country"
                                                    id="floatingInputCountry"
                                                    placeholder="Country"
                                                    {...register("country")}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <label htmlFor="floatingInputCity">Country</label>
                                                <input
                                                    type="text"
                                                    className={``}
                                                    name="city"
                                                    id="floatingInputCity"
                                                    placeholder="City"
                                                    {...register("city")}
                                                    disabled

                                                />
                                            </Col>
                                            <Col md={6}>
                                                <label htmlFor="floatingInputGridPostalcode">Postal Code</label>

                                                <input
                                                    type="text"
                                                    className={` `}
                                                    name="postal_code"
                                                    id="floatingInputGridPostalcode"
                                                    placeholder="Postal code"
                                                    {...register("postal_code")}
                                                    disabled
                                                />
                                            </Col>

                                        </Row>

                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <label htmlFor="floatingInputGridLatitude">Latitude</label>

                                                <input
                                                    type="number"
                                                    className={` `}
                                                    name="latitude"
                                                    id="floatingInputGridLatitude"
                                                    placeholder="Latitude"
                                                    {...register("latitude")}
                                                    disabled
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <label htmlFor="floatingInputGridLongitude">Longitude</label>

                                                <input
                                                    type="number"
                                                    className={` `}
                                                    name="longitude"
                                                    id="floatingInputGridLongitude"
                                                    placeholder="Longitude"
                                                    {...register("longitude")}
                                                    disabled
                                                />
                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>

                                <Row className="py-2">
                                    <Col>
                                        {/* <div>
                                            <Card.Title><span className="">Assign to</span></Card.Title>
                                        </div> */}
                                        <Row className="">
                                            <Col>
                                                <div>
                                                    <label htmlFor="floatingInputGridStatus">Lead Source</label>
                                                    <select disabled className={`input_css `} name="leadsource" id="floatingInputGridStatus"  {...register("leadsource")} >
                                                        <option value={data?.leadsource}>{data?.leadsource}</option>
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <label htmlFor="floatingInputGridStatus">Lead status</label>
                                                    <select disabled className={`input_css `} name="project_status" id="floatingInputGridStatus"  {...register("project_status")} >
                                                        <option value={data?.lead_status}>{data?.lead_status}</option>
                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="mt-3">
                                            <label htmlFor="floatingInputGridDescription">Description</label>
                                            <input type="text" disabled className={` `} name="description" id="floatingInputGridDescription" placeholder="Description" {...register("description")} />
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card className="tariffcard shadow-sm border-0 rounded" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            <Card.Title className="text-center mb-4">
                                <span className="h5 text-primary">Activity Tab</span>
                            </Card.Title>
                            {/* <Card.Body> */}
                            {data?.lead_update && data?.lead_update.length > 0 ? (
                                data?.lead_update?.map((update, index) => (
                                    <div
                                        key={index}
                                        className="update-info row mb-3 p-3 rounded bg-light align-items-center"
                                    >
                                        <div className="col-12 col-md-6 text-md-start text-center mb-2 mb-md-0">
                                            <h6 className="mb-1 text-dark">Updated By:</h6>
                                            <span className="text-muted">{update?.updated_by}</span>
                                        </div>
                                        <div className="col-12 col-md-6 text-md-end text-center">
                                            <h6 className="mb-1 text-dark">Updated At:</h6>
                                            <span className="text-muted">{formatDateTime(update?.updatedat)}</span>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-center text-muted">No updates available</p>
                            )}

                            {/* </Card.Body> */}
                        </Card>
                    </Col>
                </Row>
            </form >
            <br />
        </Container >
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.lead.isLoading,
        fetchSingleLeadRes: state.lead.fetchSingleLead,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchSingleLeadApi: (id) => dispatch(fetchSingleLeadAction(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleLead)

const latiLongi = (a, b) => {
    return Geocode.fromLatLng(a, b).then(res => { return res })
    // console.log("Gaadin",res); 
}