import React, { useCallback, useEffect, useRef, useState } from "react"
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Geocode from "react-geocode";
import { fetchCountryCodeAction, fetchDataSourceAction, fetchRoofTypeAction, fetchSingleViewProjectAction, updateProjectAction } from "../../../../store/actions/projectAction.js";
import PureBreadcrumbs from "../../Breadcrums.js"
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import "react-datepicker/dist/react-datepicker.css";
import "../homecard.css";
import { fetchContactThroughEmailAction, generateProposalURL, triggerProposalEmail, validateProjectEmailAction } from "../../../../store/actions/customerAction.js";
import { fetchAllLeadAction } from "../../../../store/actions/leadAction.js";
import { fetchAllBatteryPricingAction, fetchAllInverterPricingAction, fetchAllPanelPricingAction, fetchSingleBatteryManufacturerAction, fetchSingleInverterManufacturerAction, fetchSinglePanelManufacturerAction } from "../../../../store/actions/modulePricingAction.js";
import ProposalSummary from "../createProposal/proposalSummary.js";
import MapComponent from "../createProposal/newProposalMap.js";
import ReactSelect from "react-select";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import UpdateProposalMap from "../createProposal/updateProposalMap.js";
import { getPortalUserListAction } from "../../../../store/actions/accessAction.js";

const UpdateProject = (props) => {
    const { id } = useParams();
    // console.log("id", id);
    const [address, setAddress] = useState("");
    const [formStep, setFormStep] = useState(0);
    const [solarPanels, setSolarPanels] = useState([]);
    const [mapCenter, setMapCenter] = useState({ lat: 28.535517, lng: 77.391029 });
    const [mapZoom, setMapZoom] = useState(19);
    const [mapAddress, setMapAddress] = useState('');
    const [panelSummary, setPanelSummary] = useState(null)
    const [inverterSummary, setInverterSummary] = useState([])
    const [batterySummary, setBatterySummary] = useState([])
    const [summaryData, setSummaryData] = useState({})
    const [calculateSummaryFlag, setCalculateSummaryFlag] = useState(false);
    const [TotalPanelPrice, setTotalPanelPrice] = useState(0);
    const [TotalBatteryPrice, setTotalBatteryPrice] = useState(0);
    const [TotalInverterPrice, setTotalInverterPrice] = useState(0);
    const [TotalCost, setTotalCost] = useState(0);
    const [TotalPanelPower, setTotalPanelPower] = useState(0);
    const [alertShown, setAlertShown] = useState(false);
    const currency = process.env.REACT_APP_CURRENCY;
    const [inverterQuantity, setInverterQuantity] = useState(0);
    const [batteryQuantity, setBatteryQuantity] = useState(0);
    const [isCapturing, setIsCapturing] = useState(false);
    const [isDesignSaved, setIsDesignSaved] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    // console.log("currency: ", currency)


    const handleDeleteAllPanels = useCallback(() => {
        setSolarPanels([]);
    }, []);

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required').typeError('Email is required'),
        project_title: Yup.string().required('Project title is required').typeError('Project title is required'),
        assigned_to: Yup.string().required('Assign to is required'),
        status: Yup.string().nullable().optional(),
        siteAndEnergyDetails: Yup.array().of(
            Yup.object().shape({
                roof_type: Yup.string().required('Roof Type  is required'),
                number_of_stories: Yup.number().required('Stories is required').typeError('Number of stories is required'),
                phase: Yup.string().required('phases Type is required'),
                cellular_coverage: Yup.string().required('Cellular Coverage is required'),
                data_source: Yup.string().required('Data Source is required'),
                electricity_utility: Yup.string().required('Electricity Utility is required'),
            })),
    });
    const { register, handleSubmit, reset, setValue, getValues, control, watch, formState: { errors, isValid } } = useForm({ resolver: yupResolver(validationSchema), mode: "all" });
    const contactAndSiteData = watch();

    const addAccessoriesSchema = Yup.object().shape({
        panels: Yup.string().required('Panel selection is required'),
        inverterComponents: Yup.array().of(
            Yup.object().shape({
                inverterName: Yup.string().nullable().optional(),
                quantity: Yup.number()
                    .transform((value, originalValue) =>
                        originalValue === '' ? null : value // this is for making quantity not required field for number type
                    )
                    .nullable(),

            })
        ),
        batteryComponents: Yup.array().of(
            Yup.object().shape({
                batteryName: Yup.string().nullable().optional(),
                quantity: Yup.number()
                    .transform((value, originalValue) =>
                        originalValue === '' ? null : value // this is for making quantity not required field for number type
                    )
                    .nullable(),
            })
        ),
    });
    const { register: registerAccessories, handleSubmit: handleSubmitAccessories, setValue: setAccessoriesValue, getValues: getAccessoriesValues, control: controlAccessories, watch: watchAccessories, formState: { errors: errorsAccessories, isValid: accessoriesIsValid } } = useForm({
        resolver: yupResolver(addAccessoriesSchema),
        mode: "all",
        defaultValues: {
            panels: '',
            inverterComponents: [{ inverterName: '', quantity: '' }],
            batteryComponents: [{ batteryName: '', quantity: '' }],
        },
    });

    const { fields: inverterFields, append: appendInverter, remove: removeInverter } = useFieldArray({
        control: controlAccessories,
        name: "inverterComponents"
    });
    const inverterElements = watchAccessories("inverterComponents");
    // console.log("inverterElements", inverterElements)
    const { fields: batteryFields, append: appendBattery, remove: removeBattery } = useFieldArray({
        control: controlAccessories,
        name: "batteryComponents"
    });
    const batteryElements = watchAccessories("batteryComponents");
    // console.log("batteryElements", batteryElements);

    const quantityOptions = ['1', '2', '3', '4', '5'];

    useEffect(() => {
        props.getSingleProjectData(id);
        props.getCountryCode();
        props.fetchAllLeadApi();
        props.fetchAllPrice();
        props.fetchAllInverterPrice();
        props.fetchAllBatteryPrice();
        props.getPortalUserList();
        props.getRoofTypeList();
        props.getDataSourceList();
        reset();
    }, [id])

    const singleProjectData = props.fetchSingleProjectRes && props.fetchSingleProjectRes.data;
    // console.log("singleProjectData", singleProjectData)
    const selectedEmail = getValues("email");
    const watchAcces = watchAccessories();
    const value = watch();


    // This logic is used to put single project value into there respective fields or state ****** Logic Start *****
    useEffect(() => {
        if (singleProjectData) {
            const {
                user_email,
                contact_data: {
                    firstname,
                    lastname,
                    mobile,
                    address,
                    coordinates: { lat, lng },
                    city,
                    state,
                    country,
                    postal_code,
                },
                site_details: {
                    roof_type,
                    energy_data_source,
                    number_of_stories,
                    cellular_coverage,
                    phase,
                    electricity_utility,
                },
                panel: {
                    manufacturer: panelManufacturer,
                    technology: panelTechnology,
                    rated_output_power,
                    width,
                    height,
                    thickness,
                    weight,
                    vmp,
                    voc,
                    isc,
                    imp,
                    price_per_module,
                    panel_additional_charges,
                    role: panelRole,
                    operator: panelOperator,
                    proposal_url: panelProposalUrl,
                },
                batteries: {
                    manufacturer: batteryManufacturer,
                    sku: batterySku,
                    total_energy,
                    max_continuous_power,
                    efficiency: batteryEfficiency,
                    price_per_battery,
                    battery_additional_charges,
                },
                inverters: {
                    manufacturer: inverterManufacturer,
                    technology: inverterTechnology,
                    rated_output_power: inverterRatedOutputPower,
                    rated_output_voltage,
                    number_of_mppts,
                    minimum_vmpp,
                    maximum_vmpp,
                    efficiency: inverterEfficiency,
                    phase: inverterPhase,
                    max_ac_output_current,
                    price_per_inverter,
                    inverter_additional_charges,
                },
                total_project_cost,
                panel_details,
                inverter_quantity,
                battery_quantity,
                assigned_to,
                project_title,
                status,
            } = singleProjectData;
            setValue("firstname", firstname);
            setValue("lastname", lastname);
            setValue("email", user_email);
            setValue("mobile", mobile);
            setValue("address", address);
            setAddress(address)
            setMapAddress(address)
            setMapZoom(21)
            setValue("country", country);
            setValue("state", state);
            setValue("city", city);
            setValue("postal_code", postal_code);
            setValue("longitude", lng);
            setValue("latitude", lat);
            setValue("assigned_to", assigned_to);
            setValue("project_title", project_title);
            setValue("status", status);
            setValue("siteAndEnergyDetails.[0].roof_type", roof_type);
            setValue("siteAndEnergyDetails.[0].number_of_stories", number_of_stories);
            setValue("siteAndEnergyDetails.[0].phase", phase);
            setValue("siteAndEnergyDetails.[0].cellular_coverage", cellular_coverage);
            setValue("siteAndEnergyDetails.[0].data_source", energy_data_source);
            setValue("siteAndEnergyDetails.[0].electricity_utility", electricity_utility);
            setAccessoriesValue("panels", panelManufacturer);
            setAccessoriesValue("inverterComponents.[0].inverterName", inverterManufacturer);
            setAccessoriesValue("batteryComponents.[0].batteryName", batteryManufacturer);
            setAccessoriesValue("inverterComponents.[0].quantity", inverter_quantity);
            setInverterQuantity(inverter_quantity);
            setAccessoriesValue("batteryComponents.[0].quantity", battery_quantity);
            setBatteryQuantity(battery_quantity);
            setMapCenter({ lat: lat, lng: lng });
            setPanelSummary(panelManufacturer)
            setInverterSummary(inverterManufacturer)
            setBatterySummary(batteryManufacturer)
            props.fetchAllPanelDetails(panelManufacturer);
            props.fetchAllInverterDetails(inverterManufacturer);
            props.fetchAllBatteryDetails(batteryManufacturer);
            // setSolarPanels(panel_details);
            setIsDesignSaved(false);
            onCalculateSummary();
            localStorage.removeItem('new_solar_panels');
        }
    }, [singleProjectData, setValue]);
    // This logic is used to put single project value into there respective fields or state ****** Logic End *******


    const data = props.list && props.list.data
    // console.log("data", data);

    const options = data && data.map(lead => ({
        value: lead.email,
        label: lead.email
    }));

    const assignToUser = props.assignToUserList && props.assignToUserList.data;

    // console.log(assignToUser)
    const assignToOptions = assignToUser && assignToUser.map(item => ({
        value: item.userid,
        label: item.userid
    }));

    const handleAssignToUserChange = (obj) => {
        const value = obj.value;
        // console.log("assign to: ", value);
        setValue("assigned_to", value);
    }

    const handleEmailChange = (obj) => {
        const value = obj.value;
        // console.log("email: ", value);
        setValue("email", value);
        if (value) {
            props.fetchContactDataThroughEmailApi(value);
            props.validateEmailApi(value);
        }
    }

    const completeFormStep = () => {
        setFormStep(cur => cur + 1)

        if (formStep === 3 && (inverterElements.length > 0 || batteryElements.length > 0)) {
            // Extract inverter names from inverterElements
            const inverterElementNames = inverterElements.map(element => element.inverterName);

            // Filter inverterData to remove items where manufacturer is not in inverterElementNames
            const filteredInverterData = inverterData.filter(inverter =>
                inverterElementNames.includes(inverter.manufacturer)
            );
            // console.log("filteredInverterData: ", filteredInverterData)

            // Update state with the filtered inverter data
            setInverterData(filteredInverterData);

            // Extract battery names from inverterElements
            const batteryElementNames = batteryElements.map(element => element.batteryName);

            // Filter inverterData to remove items where manufacturer is not in inverterElementNames
            const filteredBatteryData = batteryData.filter(inverter =>
                batteryElementNames.includes(inverter.manufacturer)
            );
            // console.log("filteredBatteryData: ", filteredBatteryData)

            // Update state with the filtered inverter data
            setBatteryData(filteredBatteryData);
        }

        if (formStep === 3) {
            // console.log("calculate summary is called")
            onCalculateSummary();
        }
    }

    const goToPrevStep = () => {
        setFormStep(cur => cur - 1)
    }

    // console.log("email validation", props.validateEmailRes?.statuscode)
    const renderButton = () => {
        if (formStep === 4) {
            return (
                <div>
                    <div className="d-md-flex justify-content-md-between">
                        <Col lg={4} md={4} sm={12} xs={12} className="mt-2">
                            <button className="lgn-btn " type="button" onClick={goToPrevStep}>{("Back")}</button>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={12} className=" d-md-flex justify-content-end mt-2">
                            {
                                isSaving ?
                                    <button className="lgn-btn" type="button" disabled>
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" > </span>
                                        {("Updating...")}
                                    </button>
                                    :
                                    <button className="lgn-btn" type="submit" onClick={handleSubmit(onSubmit)}>
                                        {("UPDATE")}
                                    </button>
                            }

                        </Col>
                    </div>
                </div>
            );

        } else if (formStep === 3) {
            return (
                <>
                    <div className="d-md-flex justify-content-md-between">
                        <Col lg={5} md={6} sm={12} xs={12} className="mt-2">
                            {formStep > 1 && <button className="lgn-btn " type="button" onClick={goToPrevStep}>{("Back")}</button>}
                        </Col>
                        <Col lg={7} md={6} sm={12} xs={12} className=" d-md-flex justify-content-end mt-2">
                            {
                                <button className="lgn-btn" type="button" onClick={completeFormStep} disabled={!accessoriesIsValid}>{("PROCEED TO NEXT STEP")}</button>
                            }
                        </Col>
                    </div>

                </>
            )
        } else if (formStep === 2) {
            return (
                <>
                    <Row className="my-2">
                        <Col lg={12} className="d-flex justify-content-center">
                            <h5 className="mandatory-field">
                                *Please save your solar panel design before proceeding. Click the "Save Design" button to continue.
                            </h5>
                        </Col>
                    </Row>
                    <div className="d-md-flex justify-content-md-between">
                        <Col lg={5} md={6} sm={12} xs={12} className="mt-2">
                            {formStep > 1 && <button className="lgn-btn " type="button" onClick={goToPrevStep}>{("Back")}</button>}
                        </Col>
                        <Col lg={7} md={6} sm={12} xs={12} className=" d-md-flex justify-content-end mt-2">
                            {
                                <button className="lgn-btn" type="button" onClick={completeFormStep} disabled={!isDesignSaved || isCapturing}>{("PROCEED TO NEXT STEP")}</button>
                            }
                        </Col>
                    </div>

                </>

            )
        } else if (formStep === 1) {
            return (
                <>

                    <div className="d-md-flex justify-content-md-between">
                        <Col lg={5} md={6} sm={12} xs={12} className="mt-2">
                            <button className="lgn-btn" type="button" onClick={goToPrevStep} disabled={!isValid}>{("Back")}</button>
                        </Col>
                        <Col lg={7} md={6} sm={12} xs={12} className=" d-md-flex justify-content-end mt-2">
                            <button className="lgn-btn" type="button" onClick={completeFormStep} disabled={!isValid} >{("PROCEED TO NEXT STEP")}</button>
                        </Col>
                    </div>
                </>
            )
        } else {
            return (

                <div className="d-grid gap-2 d-md-flex justify-content-md-end p-3">
                    <Col >
                        {formStep > 0 ? <button className="lgn-btn" type="button" onClick={goToPrevStep}>{("Back")}</button> : ''}
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        {<button className="lgn-btn" type="button" onClick={completeFormStep} disabled={props.validateEmailRes?.statuscode === 200}>{("PROCEED TO NEXT STEP")}</button>}
                    </Col>
                </div>


            )
        }
    }


    // *************************** Setting the value of Email data logic start here ******************************* //
    const emailContactData = props.fetchContactThroughEmailRes && props.fetchContactThroughEmailRes.data;

    useEffect(() => {
        if (props.fetchContactThroughEmailRes.statuscode === 200 && emailContactData) {
            setAddress('')
            const {
                firstname,
                lastname,
                email,
                mobile,
                address,
                country,
                state,
                city,
                postal_code,
                coordinates: { longitude, latitude } = {},
            } = emailContactData;

            setValue("firstname", firstname);
            setValue("lastname", lastname);
            setValue("email", email);
            setValue("mobile", mobile);
            setValue("address", address);
            setAddress(address)
            setMapAddress(address)
            setMapZoom(19)
            setValue("country", country);
            setValue("state", state);
            setValue("city", city);
            setValue("postal_code", postal_code);
            setValue("longitude", longitude);
            setValue("latitude", latitude);
            setMapCenter({ lat: latitude, lng: longitude });
        }
    }, [emailContactData, setValue]);

    // *************************** Complete the Setting of value of Email data Ends here ******************************* //


    const componentRef = useRef();
    const handleExportAsPDF = () => {
        const input = componentRef.current;
        const padding = 10; // Set padding here

        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * padding;
            const pdfHeight = pdf.internal.pageSize.getHeight() - 2 * padding;
            const imgHeight = (canvas.height * pdfWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = padding;
            pdf.addImage(imgData, 'PNG', padding, position, pdfWidth, imgHeight);

            while (heightLeft >= pdfHeight) {
                position -= pdfHeight;
                heightLeft -= pdfHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', padding, position, pdfWidth, imgHeight);
            }

            pdf.save('export.pdf');
            const pdfBlob = pdf.output('blob');
            // console.log("pdfBlobpdfBlobpdfBlob", pdfBlob)
            uploadToAzure(pdfBlob);
        });
    };

    const uploadToAzure = async (pdfBlob) => {
        // for image start//
        const response = await fetch(localStorage.getItem('imgData'));
        const blob = await response.blob();
        // for image end//

        const formData = new FormData();
        formData.append('image_url', blob, `${getValues("project_title")}_${new Date().toJSON()}.png`); // image url
        formData.append('proposal_url', pdfBlob, `${getValues("project_title")}_${new Date().toJSON()}.pdf`); //pdf url
        formData.append('user_email', getValues("email")); //project id
        formData.append('project_title', getValues("project_title")); // project_title
        props.urlGenerate(formData);
    };


    const onSubmit = (data) => {

        if (inverterElements.length > 0 || batteryElements.length > 0) {
            // Extract inverter names from inverterElements
            const inverterElementNames = inverterElements.map(element => element.inverterName);

            // Filter inverterData to remove items where manufacturer is not in inverterElementNames
            const filteredInverterData = inverterData.filter(inverter =>
                inverterElementNames.includes(inverter.manufacturer)
            );
            // console.log("filteredInverterData: ", filteredInverterData)

            // Update state with the filtered inverter data
            setInverterData(filteredInverterData);

            // Extract battery names from inverterElements
            const batteryElementNames = batteryElements.map(element => element.batteryName);

            // Filter inverterData to remove items where manufacturer is not in inverterElementNames
            const filteredBatteryData = batteryData.filter(inverter =>
                batteryElementNames.includes(inverter.manufacturer)
            );
            // console.log("filteredBatteryData: ", filteredBatteryData)

            // Update state with the filtered inverter data
            setBatteryData(filteredBatteryData);
        }
        handleExportAsPDF();
        setIsSaving(true)
        // console.log("data", id, data, panels, inverterData, batteryData, solarPanels, TotalCost, getValues("email"), inverterQuantity, batteryQuantity)
        props.updateProject(id, data, panels, inverterData, batteryData, solarPanels, TotalCost, getValues("email"), inverterQuantity, batteryQuantity)
        props.triggerEmail(name, organisation, getValues("firstname"), getValues("lastname"), getValues("email"), getValues("address"), localStorage.getItem("Proposal_URL"))
    }

    const { name, organisation } = JSON.parse(localStorage.getItem('user'))

    useEffect(() => {
        if (props.updateProjectRes && props.proposalurlRes && !alertShown) {
            // Check for successful responses
            if (props.updateProjectRes.statuscode === 200 && props.proposalurlRes.statuscode === 200) {
                alert("Project has been updated successfully");
                setAlertShown(true);
                setIsSaving(false);
                localStorage.removeItem("Proposal_URL");
                localStorage.removeItem("imgData");
                localStorage.removeItem('new_solar_panels');
                // return <Redirect to="/dashboard/view_project" />
                window.location.reload();
            }
            // Check for status code 405 (or any failure code)
            else if (props.updateProjectRes.statuscode === 405 || props.proposalurlRes.statuscode === 405) {
                // Show backend error message if available
                alert(`Error 405: ${props.updateProjectRes.error || props.proposalurlRes.error || "Method Not Allowed"}`);
                setIsSaving(false);
                window.location.reload();
            }
        }
    }, [props.updateProjectRes, props.proposalurlRes, alertShown]);

    const steps = [
        "Update Contact",
        "Site and Energy details",
        "Design",
        "Add Accessories",
        "Download Proposal"
    ];

    const [panels, setPanels] = useState([]);

    const handlePanelChange = (e) => {
        // console.log("panel value", e.target.value);
        setAccessoriesValue("panels", e.target.value);
        setPanels([])
        setPanelSummary(e.target.value)
        props.fetchAllPanelDetails(e.target.value);
    };
    const panelPrice = props.fetchPanelsRes && props.fetchPanelsRes.data && props.fetchPanelsRes.data.price_per_module

    // Panel logic ************************** Start ****************************** //
    useEffect(() => {
        if (props.fetchPanelsRes && props.fetchPanelsRes.statuscode === 200) {
            const responseData = props.fetchPanelsRes.data;

            // Extract the panels array from the responseData
            const newPanels = responseData ? [responseData] : [];
            // console.log("newPanels", newPanels);

            // Check if newPanels is an array
            if (Array.isArray(newPanels)) {
                // Convert existing panels array to a Map
                const panelsMap = new Map(panels.map(panel => [panel.manufacturer, panel]));
                // console.log("panelsMap", panelsMap);

                // Add new panels to the Map if not already present
                newPanels.forEach(panel => {
                    if (panel && !panelsMap.has(panel.manufacturer)) {
                        panelsMap.set(panel.manufacturer, panel);
                    }
                });

                // Convert the Map back to an array
                const updatedPanelsArray = Array.from(panelsMap.values());

                // Update state with the updated panels array
                setPanels(updatedPanelsArray);
            } else {
                console.error('Expected newPanels to be an array, but got:', newPanels);
            }
        }
    }, [props.fetchPanelsRes]);

    // Panel logic ************************** End ****************************** //

    // Inverter logic ************************** Start ****************************** //
    const [inverterData, setInverterData] = useState([])

    const handleInverterChange = (index, e) => {
        // console.log("inverter value", e.target.value);
        setAccessoriesValue(`inverterComponents.${index}.inverterName`, e.target.value);
        setInverterSummary(e.target.value)
        props.fetchAllInverterDetails(e.target.value);

        const inverterElementNames = inverterElements.map(element => element.inverterName);

        // Filter inverterData to remove items where manufacturer is not in inverterElementNames
        const filteredInverterData = inverterData.filter(inverter =>
            inverterElementNames.includes(inverter.manufacturer)
        );
        setInverterData(filteredInverterData);

    };
    const inverterPrice = props.fetchInverterRes && props.fetchInverterRes.data && props.fetchInverterRes.data.price_per_inverter

    // This logic is for extracting unique inverter data from the API response
    useEffect(() => {
        if (props.fetchInverterRes && props.fetchInverterRes.statuscode === 200) {
            const responseData = props.fetchInverterRes.data;
            // console.log("responseData", responseData);

            // Extract the Inverter array from the responseData
            const newInverter = responseData ? [responseData] : [];
            // console.log("newInverter", newInverter);

            // Check if newInverter is an array
            if (Array.isArray(newInverter)) {
                // Convert existing inverter array to a Map
                const inverterMaps = new Map(inverterData.map(inverter => [inverter.manufacturer, inverter]));
                // console.log("inverterMaps", inverterMaps);

                // Add new inverter to the Map if not already present
                newInverter.forEach(inverter => {
                    if (inverter && !inverterMaps.has(inverter.manufacturer)) {
                        inverterMaps.set(inverter.manufacturer, inverter);
                    }
                });

                // Convert the Map back to an array
                const updatedInverterArray = Array.from(inverterMaps.values());

                // Update state with the updated inverter array
                setInverterData(updatedInverterArray);
            } else {
                console.error('Expected newInverter to be an array, but got:', newInverter);
            }
        }
    }, [props.fetchInverterRes]);

    // Inverter logic ************************** End ******************************


    // Battery logic ************************** Start ******************************

    const [batteryData, setBatteryData] = useState([])

    const handleBatteryChange = (index, e) => {
        // console.log("battery value", e.target.value);
        setAccessoriesValue(`batteryComponents.${index}.batteryName`, e.target.value);
        setBatterySummary(e.target.value)
        props.fetchAllBatteryDetails(e.target.value);

        // Extract battery names from inverterElements
        const batteryElementNames = batteryElements.map(element => element.batteryName);

        // Filter inverterData to remove items where manufacturer is not in inverterElementNames
        const filteredBatteryData = batteryData.filter(inverter =>
            batteryElementNames.includes(inverter.manufacturer)
        );
        // console.log("filteredBatteryData: ", filteredBatteryData)

        // Update state with the filtered inverter data
        setBatteryData(filteredBatteryData);
    };
    const batteryPrice = props.fetchBatteryRes && props.fetchBatteryRes.data && props.fetchBatteryRes.data.price_per_battery

    // This logic is for extracting unique battery data from the API response
    useEffect(() => {
        if (props.fetchBatteryRes && props.fetchBatteryRes.statuscode === 200) {
            const responseData = props.fetchBatteryRes.data;
            // console.log("responseData", responseData);

            // Extract the Inverter array from the responseData
            const newBattery = responseData ? [responseData] : [];
            // console.log("newBattery", newBattery);

            // Check if newBattery is an array
            if (Array.isArray(newBattery)) {
                // Convert existing battery array to a Map
                const batteryMaps = new Map(batteryData.map(battery => [battery.manufacturer, battery]));
                // console.log("batteryMaps", batteryMaps);

                // Add new inverter to the Map if not already present
                newBattery.forEach(battery => {
                    if (battery && !batteryMaps.has(battery.manufacturer)) {
                        batteryMaps.set(battery.manufacturer, battery);
                    }
                });

                // Convert the Map back to an array
                const updatedBatteryArray = Array.from(batteryMaps.values());

                // Update state with the updated battery array
                setBatteryData(updatedBatteryArray);
            } else {
                console.error('Expected newBattery to be an array, but got:', newBattery);
            }
        }
    }, [props.fetchBatteryRes]);

    // Battery logic ************************** End ******************************

    // logic for setup final moduel for summary calculation
    useEffect(() => {
        const calculateSummary = () => {
            const solarPanelsCount = solarPanels.length;
            const inverterQuantity = inverterElements[0]?.quantity;
            const batteryQuantity = batteryElements[0]?.quantity;

            const { price_per_module, panel_additional_charges, rated_output_power } = panels[0] ? panels[0] : {};
            const { price_per_inverter, inverter_additional_charges } = inverterData[0] ? inverterData[0] : {};
            const { price_per_battery, battery_additional_charges } = batteryData[0] ? batteryData[0] : {};

            const finalModuleDetails = {
                "no_of_panel": solarPanelsCount,
                "price_per_panel": Number(price_per_module),
                "panel_power": Number(rated_output_power),
                "panel_additional_charges": Number(panel_additional_charges),
                "no_of_inverters": Number(inverterQuantity),
                "price_per_inverters": Number(price_per_inverter),
                "inverter_additional_charges": Number(inverter_additional_charges),
                "no_of_batteries": Number(batteryQuantity),
                "price_per_battery": Number(price_per_battery),
                "battery_additional_charges": Number(battery_additional_charges),
            };

            setSummaryData({
                ...finalModuleDetails,
            });

        };
        // console.log("WRGRHGERHERHEHGE", summaryData)

        // Only calculate summary if all relevant dependencies are present
        if (solarPanels.length > 0 && panels.length > 0) {
            if (calculateSummaryFlag) {
                calculateSummary();
                // Reset the flag
                setCalculateSummaryFlag(false);
            }
        }
    }, [calculateSummaryFlag, solarPanels, inverterElements, batteryElements, panels, inverterData, batteryData]);


    // Final summary calculation is here....
    useEffect(() => {
        if (summaryData) {
            // console.log("summaryDatasummaryData", summaryData)
            const {
                no_of_panel,
                price_per_panel,
                panel_power,
                panel_additional_charges,
                no_of_inverters,
                price_per_inverters,
                inverter_additional_charges,
                no_of_batteries,
                price_per_battery,
                battery_additional_charges
            } = summaryData;

            const totalPanelPrice = no_of_panel * (price_per_panel + panel_additional_charges);
            const totalInverterPrice = no_of_inverters * (price_per_inverters + inverter_additional_charges);
            const totalBatteryPrice = no_of_batteries * (price_per_battery + battery_additional_charges);
            const totalCost = totalPanelPrice + totalInverterPrice + totalBatteryPrice;
            const totalPanelPower = no_of_panel * (panel_power)

            setTotalPanelPrice(totalPanelPrice);
            setTotalInverterPrice(totalInverterPrice);
            setTotalBatteryPrice(totalBatteryPrice);
            setTotalCost(totalCost);
            setTotalPanelPower(totalPanelPower)
        }
    }, [summaryData]);

    // calculate summary submit btn
    const onCalculateSummary = (data) => {
        setCalculateSummaryFlag(true);
    };

    const handleInverterQuantityChange = (index, e) => {
        setInverterQuantity(e.target.value)
    }

    const handleBatteryQuantityChange = (index, e) => {
        setBatteryQuantity(e.target.value)
    }


    const handleDesignSaved = () => {
        setIsDesignSaved(true); // Enable the "Next" button when design is saved
    };
    // console.log("form step", formStep)

    return (
        <Container fluid>
            <div className="row">
                <div className="col-lg-6 col-sm-12 mt-2">
                    <PureBreadcrumbs />
                </div>
            </div>
            <form className="mt-3" autoComplete='off' onSubmit={e => e.preventDefault()}>
                <Card className='pt-3'>
                    <div>
                        <div className="form-header d-flex mb-4">
                            {steps.map((step, index) => (
                                <span
                                    key={index}
                                    className={`stepIndicator ${formStep === index ? 'active' : ''}`}
                                // onClick={() => setFormStep(index)} // This is optional, for demonstration purposes
                                >
                                    {step}
                                </span>
                            ))}
                        </div>
                    </div>

                    {/* ------------Contact and address------------- */}

                    {formStep === 0 && (
                        <Card.Body>
                            <Row className="mt-3">
                                <Col>
                                    <label htmlFor="email">Email <span className="mandatory-field">*</span></label>
                                    {/* React Select with React Hook Form */}
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                            <ReactSelect
                                                {...field}
                                                options={options ? options : []}
                                                className={` ${errors.email ? 'is-invalid' : ''}`}
                                                isDisabled={true}
                                                placeholder="Email"
                                                value={options && (options.find(option => option.value === (selectedEmail || singleProjectData?.contact_data?.email)) || null)}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: state.isFocused ? 'grey' : 'grey',
                                                    }),
                                                }}
                                                onChange={(selectedOption) => {
                                                    field.onChange(selectedOption);
                                                    handleEmailChange(selectedOption);
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.email && <span className="invalid-feedback">{errors.email.message}</span>}
                                    {props.validateEmailRes?.statuscode === 200 && <div className="text-danger">{props.validateEmailRes?.message}</div>}
                                </Col>
                                <Col>
                                    <div>
                                        <label htmlFor="data_source">Project Title<span className="mandatory-field">*</span></label>
                                        <input
                                            type="text"
                                            className={`  ${errors.project_title ? 'is-invalid' : ''}`}
                                            name="project_title"
                                            id="floatingInputGridDataSource"
                                            placeholder="Project Title"
                                            {...register("project_title")}
                                            disabled
                                        />
                                        <div className="invalid-feedback">
                                            {errors.project_title?.message}
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <label htmlFor="assigned_to">Assign To<span className="mandatory-field">*</span></label>
                                    {/* React Select with React Hook Form */}
                                    <Controller
                                        name="assigned_to"
                                        control={control}
                                        render={({ field }) => (

                                            <ReactSelect
                                                {...field}
                                                options={assignToOptions ? assignToOptions : []}
                                                className={`${errors.assigned_to ? 'is-invalid' : ''}`}
                                                placeholder={"Assign to"}
                                                // Remove `value` prop or set it to `field.value`
                                                // value={options && (options.find(option => option.value === (selectedEmail || singleProjectData?.contact_data?.email)) || null)}
                                                value={assignToOptions ? assignToOptions.find(option => option.value === getValues('assigned_to')) : []}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: state.isFocused ? 'grey' : 'grey',
                                                    }),
                                                }}
                                                onChange={(selectedOption) => {
                                                    field.onChange(selectedOption);
                                                    handleAssignToUserChange(selectedOption);
                                                }}

                                            />
                                        )}
                                    />

                                    {errors.assigned_to && <span className="invalid-feedback">{errors.assigned_to.message}</span>}

                                </Col>

                            </Row>

                            <Row className="mt-3">
                                <Col>
                                    <label htmlFor="firstname">Firstname</label>
                                    <input
                                        type="text"
                                        className={` `}
                                        name="firstname"
                                        id="floatingInputGridName"
                                        placeholder="Firstname"
                                        {...register("firstname")}
                                        disabled
                                    />
                                </Col>
                                <Col>
                                    <label htmlFor="lastname">Lastname</label>
                                    <input
                                        type="text"
                                        className={` `}
                                        name="lastname"
                                        id="floatingInputGridLname"
                                        placeholder="Lastname"
                                        {...register("lastname")}
                                        disabled
                                    />
                                </Col>
                                <Col>
                                    <label htmlFor="mobile">Mobile Number</label>
                                    <input
                                        type="number"
                                        className={``}
                                        name="mobile"
                                        id="floatingInputGridMobile"
                                        placeholder="Mobile number"
                                        {...register("mobile")}
                                        disabled
                                    />
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col>
                                    <label htmlFor="address">Address</label>
                                    <input
                                        type="text"
                                        className={`input_css`}
                                        defaultValue={address}
                                        placeholder="Address..."
                                        disabled
                                    />
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col md={6}>
                                    <label htmlFor="state">State / Province</label>
                                    <input
                                        type="text"
                                        className={` `}
                                        name="state"
                                        id="floatingInputzip"
                                        placeholder="State / Province"
                                        {...register("state")}
                                        disabled
                                    />
                                </Col>
                                <Col md={6}>
                                    <label htmlFor="country">Country</label>
                                    <input
                                        type="text"
                                        className={` `}
                                        name="country"
                                        id="floatingInputzip"
                                        placeholder="Country"
                                        {...register("country")}
                                        disabled
                                    />
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col md={6}>
                                    <label htmlFor="city">City</label>
                                    <input
                                        type="text"
                                        className={` `}
                                        name="city"
                                        id="floatingInputzip"
                                        placeholder="City"
                                        {...register("city")}
                                        disabled
                                    />
                                </Col>
                                <Col md={6}>
                                    <label htmlFor="postal_code">Postal Code</label>
                                    <input
                                        type="text"
                                        className={` `}
                                        name="postal_code"
                                        id="floatingInputGridPostalcode"
                                        placeholder="Postal code"
                                        {...register("postal_code")}
                                        disabled
                                    />
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col md={6}>
                                    <label htmlFor="latitude">Latitude</label>
                                    <input
                                        type="number"
                                        className={` `}
                                        name="latitude"
                                        id="floatingInputGridLatitude"
                                        placeholder="Latitude"
                                        {...register("latitude")}
                                        disabled
                                    />
                                </Col>
                                <Col md={6}>
                                    <label htmlFor="longitude">Longitude</label>
                                    <input
                                        type="number"
                                        className={``}
                                        name="longitude"
                                        id="floatingInputGridLongitude"
                                        placeholder="Longitude"
                                        {...register("longitude")}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <div>
                                        <label htmlFor="status">Select Project Status<span className="mandatory-field">*</span></label>
                                        <select
                                            className={`input_css  ${errors.status ? 'is-invalid' : ''}`}
                                            name="status"
                                            {...register("status")}
                                        >
                                            <option value="" disabled>Select Project Status</option>
                                            <option value="New">New</option>
                                            <option value="In Progress">In Progress</option>
                                            <option value="Lost">Lost</option>
                                            <option value="Completed">Completed</option>

                                        </select>
                                        <div className="invalid-feedback">
                                            {errors.status?.message}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>

                    )}
                </Card>

                {/* ------------site and energy details------------- */}

                <Card>
                    {formStep === 1 && (
                        <Card.Body>
                            <Row className="py-2">
                                <Col>
                                    <div>
                                        <Card.Title><span className="">Site Details</span></Card.Title>
                                    </div>
                                    <Row className="mt-3 ">
                                        <Col lg={6}>
                                            <div>
                                                <label htmlFor="roof_type">Roof Type <span className="mandatory-field">*</span></label>
                                                <select
                                                    className={`input_css  ${errors.siteAndEnergyDetails?.[0]?.roof_type ? 'is-invalid' : ''}`}
                                                    name="roof_type"
                                                    {...register("siteAndEnergyDetails.[0].roof_type")}
                                                >
                                                    <option value="">Select Roof Type</option>
                                                    {props.getRoofTypeListRes && props.getRoofTypeListRes.rooftypes && props.getRoofTypeListRes.rooftypes.map((v, k) =>
                                                        <option key={v.key} value={v.value}>{(v.value)}</option>
                                                    )}

                                                </select>
                                                <div className="invalid-feedback">
                                                    {errors.siteAndEnergyDetails?.[0]?.roof_type?.message}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="">
                                                <label htmlFor="cellular_coverage">Cellular Coverage <span className="mandatory-field">*</span></label>
                                                <select
                                                    className={`input_css  ${errors.siteAndEnergyDetails?.[0]?.cellular_coverage ? 'is-invalid' : ''}`}
                                                    name="cellular_coverage"
                                                    {...register("siteAndEnergyDetails.[0].cellular_coverage")}
                                                >
                                                    <option value="">Select Cellular Coverage</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                                <div className="invalid-feedback">
                                                    {errors.siteAndEnergyDetails?.[0]?.cellular_coverage?.message}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="py-3">
                                        <Col lg={6}>
                                            <div className="">
                                                <label htmlFor="number_of_stories">Number of Stories <span className="mandatory-field">*</span></label>
                                                <input
                                                    type="number"
                                                    className={`  ${errors.siteAndEnergyDetails?.[0]?.number_of_stories ? 'is-invalid' : ''}`}
                                                    name="number_of_stories"
                                                    id="floatingInputGridStories"
                                                    placeholder="No of Stories"
                                                    {...register("siteAndEnergyDetails.[0].number_of_stories")}
                                                />
                                                <div className="invalid-feedback">
                                                    {errors.siteAndEnergyDetails?.[0]?.number_of_stories?.message}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="">
                                                <label htmlFor="phase">Phase <span className="mandatory-field">*</span></label>
                                                <input
                                                    type="text"
                                                    className={`  ${errors.siteAndEnergyDetails?.[0]?.phase ? 'is-invalid' : ''}`}
                                                    name="phase"
                                                    id="floatingInputGridPhase"
                                                    placeholder="Phase"
                                                    {...register("siteAndEnergyDetails.[0].phase")}
                                                />
                                                <div className="invalid-feedback">
                                                    {errors.siteAndEnergyDetails?.[0]?.phase?.message}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="py-2">
                                <Col>
                                    <div>
                                        <Card.Title><span className="">Energy</span></Card.Title>
                                    </div>
                                    <Row className="mt-3 ">
                                        <Col>
                                            <div>
                                                <label htmlFor="data_source">Select Data Source <span className="mandatory-field">*</span></label>
                                                <select
                                                    className={`input_css  ${errors.siteAndEnergyDetails?.[0]?.data_source ? 'is-invalid' : ''}`}
                                                    name="data_source"
                                                    {...register("siteAndEnergyDetails.[0].data_source")}
                                                >
                                                    <option value="">Select Data Source</option>
                                                    {props.getDataSourceListRes && props.getDataSourceListRes.datasources && props.getDataSourceListRes.datasources.map((v, k) =>
                                                        <option key={v.key} value={v.value}>{(v.value)}</option>
                                                    )}
                                                </select>
                                                <div className="invalid-feedback">
                                                    {errors.siteAndEnergyDetails?.[0]?.data_source?.message}
                                                </div>
                                            </div>

                                            <div className="mt-3">
                                                <label htmlFor="electricity_utility">Electricity Utility <span className="mandatory-field">*</span></label>
                                                <select
                                                    className={`input_css  ${errors.siteAndEnergyDetails?.[0]?.electricity_utility ? 'is-invalid' : ''}`}
                                                    name="electricity_utility"
                                                    {...register("siteAndEnergyDetails.[0].electricity_utility")}
                                                >
                                                    <option value="">Select Electricity Utility</option>
                                                    <option value="Solar">Solar</option>
                                                    <option value="Main Power">Main Power</option>
                                                </select>
                                                <div className="invalid-feedback">
                                                    {errors.siteAndEnergyDetails?.[0]?.electricity_utility?.message}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    )}
                </Card>

                {/* ------------Proposal map------------- */}

                {formStep === 2 && (
                    <div>
                        <UpdateProposalMap
                            solarPanels={solarPanels}
                            setSolarPanel={setSolarPanels}
                            onDeleteAllPanels={handleDeleteAllPanels}
                            address={mapAddress}
                            setMapAddress={setMapAddress}
                            mapCenter={mapCenter}
                            onCenterChange={setMapCenter}
                            mapZoom={mapZoom}
                            onZoomChange={setMapZoom}
                            formStep={formStep}
                            singleSolarData={singleProjectData?.panel_details}
                            setIsCapturing={setIsCapturing}
                            onDesignSaved={handleDesignSaved}
                            setIsDesignSaved={setIsDesignSaved}
                            project_title={`${getValues("project_title")}_${new Date().toJSON()}.png`}

                        />
                    </div>
                )}

                {/* ------------Add accessories------------- */}

                {formStep === 3 && (
                    <Card>
                        <Card.Body>
                            <div className="container">
                                <h1>Total Panel Count: {solarPanels ? solarPanels.length : 0}</h1>
                                <Row>
                                    {/* Left Column */}
                                    <Col md={6}>
                                        <form onClick={e => e.preventDefault()}>
                                            <div className="mb-3">
                                                <label htmlFor="panelSelect" className="form-label">Select Panel</label>
                                                <select id="panelSelect" className={`form-select form-control ${errorsAccessories.panels ? 'is-invalid' : ''}`}
                                                    {...registerAccessories('panels')}
                                                    onChange={(e) => handlePanelChange(e)}
                                                >
                                                    <option value="">Select a panel</option>
                                                    {props.panelList?.data?.map((panel) => (
                                                        <option key={panel.manufacturer} value={panel.manufacturer}>
                                                            {panel.manufacturer}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errorsAccessories.panels && <div className="text-danger">{errorsAccessories.panels.message}</div>}
                                            </div>

                                            <div className="mb-3">
                                                <h4>Inverter Components</h4>
                                                {inverterFields.map((field, index) => (
                                                    <div key={field.id} className="mb-3 row g-2 align-items-center">
                                                        {/* Inverter Name Select */}
                                                        <div className="col-12 col-md-6">
                                                            <div className={`d-flex flex-column ${errorsAccessories.inverterComponents?.[index]?.inverterName ? 'mt-4' : ''}`}>
                                                                <select
                                                                    className={`form-select ${errorsAccessories.inverterComponents?.[index]?.inverterName ? 'is-invalid' : ''}`}
                                                                    {...registerAccessories(`inverterComponents.${index}.inverterName`)}
                                                                    onChange={(e) => handleInverterChange(index, e)}
                                                                >
                                                                    <option value="">Select Inverter</option>
                                                                    {props.inverterList && props.inverterList.data?.map((inverter, k) => {
                                                                        const unitSelected = inverterFields.slice(0, index).some(field => field.inverterName === inverter.manufacturer);
                                                                        return !unitSelected && <option key={inverter.manufacturer} value={inverter.manufacturer}>{(inverter.manufacturer)}</option>;
                                                                    })}
                                                                </select>
                                                                {errorsAccessories.inverterComponents?.[index]?.inverterName && (
                                                                    <div className="invalid-feedback d-block">
                                                                        {errorsAccessories.inverterComponents[index].inverterName.message}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        {/* Quantity Select */}
                                                        <div className="col-12 col-md-6">
                                                            <div className={`d-flex flex-column ${errorsAccessories.inverterComponents?.[index]?.quantity ? 'mt-4' : ''}`}>
                                                                <select
                                                                    className={`form-select ${errorsAccessories.inverterComponents?.[index]?.quantity ? 'is-invalid' : ''}`}
                                                                    {...registerAccessories(`inverterComponents.${index}.quantity`)}
                                                                    onChange={(e) => handleInverterQuantityChange(index, e)}
                                                                >
                                                                    <option value="">Select Quantity</option>
                                                                    {quantityOptions?.map((option, i) => (
                                                                        <option key={i} value={option}>{option}</option>
                                                                    ))}
                                                                </select>
                                                                {errorsAccessories.inverterComponents?.[index]?.quantity && (
                                                                    <div className="invalid-feedback d-block">
                                                                        {errorsAccessories.inverterComponents[index].quantity.message}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            <div className="mb-3">
                                                <h4>Battery Components</h4>
                                                {batteryFields.map((field, index) => (
                                                    <div key={field.id} className="mb-3 row g-2 align-items-center">
                                                        {/* Battery Name Select */}
                                                        <div className="col-12 col-md-6">
                                                            <div className={`d-flex flex-column ${errorsAccessories.batteryComponents?.[index]?.batteryName ? 'is-invalid' : 'mt-4'}`}>
                                                                <select
                                                                    className={`form-select ${errorsAccessories.batteryComponents?.[index]?.batteryName ? 'is-invalid' : ''}`}
                                                                    {...registerAccessories(`batteryComponents.${index}.batteryName`)}
                                                                    onChange={(e) => handleBatteryChange(index, e)}
                                                                >
                                                                    <option value="">Select Battery</option>
                                                                    {props.batteryList && props.batteryList.data?.map((battery, k) => {
                                                                        const unitSelected = batteryFields.slice(0, index).some(field => field.batteryName === battery.manufacturer);
                                                                        return !unitSelected && <option key={battery.manufacturer} value={battery.manufacturer}>{(battery.manufacturer)}</option>;
                                                                    })}
                                                                </select>
                                                                {errorsAccessories.batteryComponents?.[index]?.batteryName && (
                                                                    <div className="invalid-feedback d-block">
                                                                        {errorsAccessories.batteryComponents[index].batteryName.message}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        {/* Quantity Select */}
                                                        <div className="col-12 col-md-6">
                                                            <div className={`d-flex flex-column ${errorsAccessories.batteryComponents?.[index]?.quantity ? 'is-invalid' : 'mt-4'}`}>
                                                                <select
                                                                    className={`form-select ${errorsAccessories.batteryComponents?.[index]?.quantity ? 'is-invalid' : ''}`}
                                                                    {...registerAccessories(`batteryComponents.${index}.quantity`)}
                                                                    onChange={(e) => handleBatteryQuantityChange(index, e)}
                                                                >
                                                                    <option value="">Select Quantity</option>
                                                                    {quantityOptions?.map((option, i) => (
                                                                        <option key={i} value={option}>{option}</option>
                                                                    ))}
                                                                </select>
                                                                {errorsAccessories.batteryComponents?.[index]?.quantity && (
                                                                    <div className="invalid-feedback d-block">
                                                                        {errorsAccessories.batteryComponents[index].quantity.message}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </form>
                                    </Col>

                                    {/* Right Column */}
                                    <Col md={6}>
                                        <div className="summary">
                                            <Card className="summaryCard p-2">
                                                <Row className="mb-3 d-flex justify-content-between">
                                                    <Col>
                                                        <Card.Title className="pl-2 pt-2">
                                                            Summary
                                                        </Card.Title>
                                                    </Col>
                                                    <Col className="mb-3 d-flex justify-content-end">
                                                        <Button type="submit" variant="success" onClick={handleSubmitAccessories(onCalculateSummary)}>Calculate Summary</Button>
                                                    </Col>
                                                </Row>
                                                {/* <hr /> */}
                                                <Card.Title> Hardware</Card.Title>
                                                <Card.Body>
                                                    <ul>
                                                        <li>
                                                            {panelSummary || "Select panel"} * {solarPanels?.length || 0} = {currency} {TotalPanelPrice || 0}
                                                        </li>
                                                        {inverterElements?.length > 0 ? (
                                                            <li>
                                                                {inverterSummary} * {summaryData?.no_of_inverters || 0} = {currency} {TotalInverterPrice || 0}
                                                            </li>
                                                        ) : null}
                                                        {batteryElements?.length > 0 ? (
                                                            <li>
                                                                {batterySummary} * {summaryData?.no_of_batteries || 0} = {currency} {TotalBatteryPrice || 0}
                                                            </li>
                                                        ) : null}
                                                    </ul>

                                                    <Card.Footer>
                                                        <h5>Total Cost - {currency} {TotalCost || 0} </h5>
                                                    </Card.Footer>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                        </Card.Body>
                    </Card>
                )}

                {/* ------------Final Proposal PDF Page------------- */}

                {formStep === 4 && (
                    <Card>
                        <Card.Body>
                            <ProposalSummary firstname={getValues("firstname")} lastname={getValues("lastname")} project_title={getValues("project_title")}
                                email={getValues("email")} address={getValues("address")} panelName={panelSummary} inverterName={inverterSummary}
                                batteryName={batterySummary} systemCost={TotalCost} panelQuantity={solarPanels?.length} inverterQuantity={summaryData?.no_of_inverters}
                                batteryQuantity={summaryData?.no_of_batteries} panelPower={summaryData?.panel_power}
                                totalPanelPowerr={TotalPanelPower} componentRef={componentRef}
                            />
                        </Card.Body>
                    </Card>
                )}

                <br />
                {renderButton()}
                <br />
                <br />
            </form>
            {isCapturing && (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                </div>
            )}
        </Container >
    )
}


const mapStateToProps = (state) => {
    return {
        loading: state.project.isLoading,
        updateProjectRes: state.project.updateProject,
        fetchSingleProjectRes: state.project.fetchSingleViewProject,
        fetchCountryCodeRes: state.project.fetchCountryCode,
        fetchContactThroughEmailRes: state.customer.fetchContactThroughEmail,
        validateEmailRes: state.customer.validateEmail,
        list: state.lead.fetchAllLead,
        panelList: state.price.fetchAllPanelPricing,
        inverterList: state.price.fetchAllInverterPricing,
        batteryList: state.price.fetchAllBatteryPricing,
        fetchPanelsRes: state.price.fetchSinglePanelDetails,
        fetchInverterRes: state.price.fetchSingleInverterDetails,
        fetchBatteryRes: state.price.fetchSingleBatteryDetails,
        sendEmailRes: state.customer.triggerEmailforProposals,
        proposalurlRes: state.customer.generate_proposal_url,
        assignToUserList: state.access.portalUserList,
        getRoofTypeListRes: state.project.fetchRoofTypeList,
        getDataSourceListRes: state.project.fetchDataSourceList,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateProject: (id, data, panels, inverterData, batteryData, solarPanels, TotalCost, clientEmail, inverterQuantity, batteryQuantity) => dispatch(updateProjectAction(id, data, panels, inverterData, batteryData, solarPanels, TotalCost, clientEmail, inverterQuantity, batteryQuantity)),
        getSingleProjectData: (id) => dispatch(fetchSingleViewProjectAction(id)),
        getCountryCode: () => dispatch(fetchCountryCodeAction()),
        fetchContactDataThroughEmailApi: (id) => dispatch(fetchContactThroughEmailAction(id)),
        validateEmailApi: (id) => dispatch(validateProjectEmailAction(id)),
        fetchAllLeadApi: () => dispatch(fetchAllLeadAction()),
        fetchAllPrice: () => dispatch(fetchAllPanelPricingAction()),
        fetchAllInverterPrice: () => dispatch(fetchAllInverterPricingAction()),
        fetchAllBatteryPrice: () => dispatch(fetchAllBatteryPricingAction()),
        fetchAllPanelDetails: (manufacturer) => dispatch(fetchSinglePanelManufacturerAction(manufacturer)),
        fetchAllInverterDetails: (manufacturer) => dispatch(fetchSingleInverterManufacturerAction(manufacturer)),
        fetchAllBatteryDetails: (manufacturer) => dispatch(fetchSingleBatteryManufacturerAction(manufacturer)),
        triggerEmail: (sender_firstname, sender_org, client_firstname, client_lastname, client_email, client_address, proposal_link) => dispatch(triggerProposalEmail(sender_firstname, sender_org, client_firstname, client_lastname, client_email, client_address, proposal_link)),
        urlGenerate: (formData) => dispatch(generateProposalURL(formData)),
        getPortalUserList: () => dispatch(getPortalUserListAction()),
        getRoofTypeList: () => dispatch(fetchRoofTypeAction()),
        getDataSourceList: () => dispatch(fetchDataSourceAction()),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateProject)

const latiLongi = (a, b) => {
    return Geocode.fromLatLng(a, b).then(res => { return res })
    // console.log("Gaadin",res); 
}