import { Card, Col, Container, Row } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchSingleLeadEventAction, fetchTimeSlotsAction, updateLeadEventTypeAction } from "../../../../store/actions/leadAction";
import PureBreadcrumbs from "../../Breadcrums"
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Select from 'react-select';

const UpdateSchedule = (props) => {
    const { id } = useParams();
    const today = new Date().toISOString().split('T')[0];
    // console.log("props", id, props);

    useEffect(() => {
        props.fetchSingleLeadEventApi(id);
        props.fetchTimeSlotsApi();
    }, [id])


    const singleData = props.fetchSingleLeadEventRes && props.fetchSingleLeadEventRes.data;
    // console.log("singleData", singleData)

    const formatDateForBackend = (date) => {
        const dateObj = new Date(date); // Convert string to Date object

        // Extract the year, month, and day from the Date object
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
        const day = String(dateObj.getDate()).padStart(2, '0');

        // Return the formatted date as "YYYY-MM-DD"
        return `${year}-${month}-${day}`;
    };

    const validationSchema = Yup.object().shape({
        emails: Yup.array()
            .of(
                Yup.object().shape({
                    email_id: Yup.string()
                        .email("Invalid email")
                        .required("Email I'd is required"),
                })
            )
            .required("At least one email is required"),
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        startDate: Yup.date().required("Start date is required").nullable(),
        startTime: Yup.string().required("Start time is required"),
        endTime: Yup.string().required("End time is required"),
    });

    const { register, control, handleSubmit, setValue, getValues, reset, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            emails: [{ email_id: '' }],
            title: "",
            description: "",
            startDate: null,
            startTime: "",
            endTime: "",
        },
    });
    const startTime = watch('startTime');
    const endTime = watch('endTime');
    // console.log("startTime: ", startTime)
    // console.log("endTime: ", endTime)

    const { fields, append, remove } = useFieldArray({
        control,
        name: "emails",
    });

    useEffect(() => {

        if (singleData) {
            const { email, title, description, date, starttime, endtime } = singleData;
            setValue("emails", email);
            setValue("title", title);
            setValue("description", description);
            setValue("startDate", date);
            setValue("startTime", starttime);
            setValue("endTime", endtime);
        }

    }, [setValue, singleData])

    const onSubmit = (data) => {

        const formattedStartDate = formatDateForBackend(data.startDate);
        const payload = {
            ...data,
            startDate: formattedStartDate,
        };
        // console.log(payload);
        props.updateLeadEventTypeApi(payload, id)
    };


    useEffect(() => {
        if (props.updateLeadEventTypeRes.status_code === 200) {
            alert(props.updateLeadEventTypeRes.message)
            window.location.reload();
        } else if (props.updateLeadEventTypeRes.status_code === 405) {
            alert(props.updateLeadEventTypeRes.message)
            window.location.reload();
        }

    }, [props.updateLeadEventTypeRes])

    return (
        <Container fluid className="pb-4">
            <div className="row">
                <div className="col-lg-12 col-sm-12 mt-4 mb-3">
                    <PureBreadcrumbs />
                </div>
            </div>
            <Row className="pb-5">
                <Col lg={12}>
                    <Card className="tariffcard">
                        <Card.Title>
                            Update Schedule Event
                        </Card.Title>
                        <Card.Body>
                            <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "10px" }}>
                                {/* First Row: Email and Title side-by-side */}
                                <Row className="">
                                    <Col md={7} className="form-group">
                                        <label className="form-label">Email Addresses</label>
                                        {fields && fields?.map((item, index) => (
                                            <div key={item.id} className="mb-3">
                                                <div className="d-flex align-items-center">
                                                    <input
                                                        type="email"
                                                        className={`form-control ${errors.emails?.[index]?.email_id ? "is-invalid" : ""}`}
                                                        placeholder="Enter email"
                                                        id={`emails.${index}.email_id`}
                                                        autoComplete="off"
                                                        {...register(`emails.${index}.email_id`)}
                                                    />
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger mx-2"
                                                        onClick={() => remove(index)}
                                                        style={{ marginLeft: "10px" }}
                                                        disabled={fields?.length === 1}
                                                    >
                                                        Remove
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-primary"
                                                        onClick={() => append({ email_id: "" })}
                                                    >
                                                        Add Email
                                                    </button>
                                                </div>

                                                {errors.emails?.[index]?.email_id && (
                                                    <p className="invalid-feedback d-block">
                                                        {errors.emails[index].email_id.message}
                                                    </p>
                                                )}
                                            </div>
                                        ))}
                                    </Col>

                                    <Col md={5} className="form-group">
                                        <label className="form-label">Title</label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.title ? "is-invalid" : ""}`}
                                            placeholder="Enter title"
                                            {...register("title")}
                                        />
                                        {errors.title && <p className="invalid-feedback">{errors.title.message}</p>}
                                    </Col>
                                </Row>

                                {/* Second Row: Start Date and End Date side-by-side */}
                                <Row className="">
                                    <Col md={4} className="form-group">
                                        <label className="form-label d-block">Schedule Date</label>
                                        <input
                                            type="date"
                                            min={today}
                                            className={`form-control ${errors.startDate ? "is-invalid" : ""}`}
                                            {...register("startDate")}
                                        />
                                        {errors.startDate && <p className="invalid-feedback">{errors.startDate.message}</p>}
                                    </Col>

                                    {/* <Col md={4} className="form-group">
                                        <label className="form-label">Start Time</label>

                                        <select
                                            className={`input_css ${errors.startTime ? 'is-invalid' : ''}`}
                                            name="startTime"
                                            {...register("startTime")}
                                            id="floatingInputGridStartTime"
                                        >
                                            {startTime && (
                                                <option value={startTime} disabled hidden>
                                                    {startTime}
                                                </option>
                                            )}
                                            <option value="">{("Select Start Time")}</option>
                                            {props.fetchTimeSlotsRes &&
                                                props.fetchTimeSlotsRes?.hours &&
                                                props.fetchTimeSlotsRes?.hours
                                                    .map((v, k) => (
                                                        <option key={v.key} value={v.value}>
                                                            {v.value}
                                                        </option>
                                                    ))}
                                        </select>
                                        {errors.startTime && <p className="invalid-feedback">{errors.startTime.message}</p>}
                                    </Col>

                                    <Col md={4} className="form-group">
                                        <label className="form-label">End Time</label>
                                        <select
                                            className={`input_css ${errors.endTime ? 'is-invalid' : ''}`}
                                            name="endTime"
                                            {...register("endTime")}
                                            id="floatingInputGridEndTime"

                                        >
                                            {endTime && (
                                                <option value={endTime} disabled hidden>
                                                    {endTime}
                                                </option>
                                            )}
                                            <option value="">{("Select End Time")}</option>
                                            {props.fetchTimeSlotsRes &&
                                                props.fetchTimeSlotsRes.hours &&
                                                props.fetchTimeSlotsRes.hours
                                                    ?.map((v, k) => {
                                                        const isDisabled = startTime !== "23:59" && v.value < startTime;
                                                        // console.log("isDisabled", isDisabled)
                                                        return (
                                                            <option key={v.key} value={v.value} disabled={isDisabled}>
                                                                {v.value}
                                                            </option>
                                                        );
                                                    })}

                                        </select>
                                        {errors.endTime && <p className="invalid-feedback">{errors.endTime.message}</p>}
                                    </Col> */}
                                    <Col md={4} className="form-group">
                                        <label className="form-label">Start Time</label>
                                        <Select
                                            className={` ${errors.startTime ? 'is-invalid' : ''}`}
                                            name="startTime"
                                            id="floatingInputGridStartTime"
                                            options={props.fetchTimeSlotsRes?.hours?.map((v) => ({
                                                value: v.value,
                                                label: v.value
                                            }))}
                                            placeholder={startTime ? startTime : "Select Start Time"}
                                            onChange={(selectedOption) => setValue("startTime", selectedOption?.value)}
                                            isClearable
                                        />
                                        {errors.startTime && <p className="invalid-feedback">{errors.startTime.message}</p>}
                                    </Col>

                                    <Col md={4} className="form-group">
                                        <label className="form-label">End Time</label>
                                        <Select
                                            className={` ${errors.endTime ? 'is-invalid' : ''}`}
                                            name="endTime"
                                            id="floatingInputGridEndTime"
                                            options={props.fetchTimeSlotsRes?.hours?.map((v) => ({
                                                value: v.value,
                                                label: v.value,
                                                isDisabled: startTime !== "23:59" && v.value < startTime
                                            }))}
                                            placeholder={endTime ? endTime : "Select End Time"}
                                            onChange={(selectedOption) => setValue("endTime", selectedOption?.value)}
                                            isClearable
                                        />
                                        {errors.endTime && <p className="invalid-feedback">{errors.endTime.message}</p>}
                                    </Col>
                                </Row>

                                {/* Third Row: Description */}
                                <Row className="">
                                    <Col md={12} className="form-group">
                                        <label className="form-label">Description</label>
                                        <textarea
                                            className={`form-control ${errors.description ? "is-invalid" : ""}`}
                                            placeholder="Enter description"
                                            rows="2"
                                            {...register("description")}
                                        />
                                        {errors.description && <p className="invalid-feedback">{errors.description.message}</p>}
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col lg={12} md={6} className='mt-2 d-md-flex justify-content-end '>
                                        {props.loading ?
                                            <button className="lgn-btn" type="button" disabled>
                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                &nbsp;
                                                {("Saving...")}
                                            </button>
                                            :
                                            <button className="lgn-btn" type="submit">{("Update Schedule")}</button>
                                        }
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.lead.isLoading,
        fetchSingleLeadEventRes: state.lead.fetchSingleLeadEventType,
        updateLeadEventTypeRes: state.lead.updateLeadEventType,
        fetchTimeSlotsRes: state.lead.fetchTimeSlots,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateLeadEventTypeApi: (data, id) => dispatch(updateLeadEventTypeAction(data, id)),
        fetchSingleLeadEventApi: (id) => dispatch(fetchSingleLeadEventAction(id)),
        fetchTimeSlotsApi: () => dispatch(fetchTimeSlotsAction()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateSchedule);
