//Local Base Url
// export const ADMIN_BASE_URL = "http://localhost:12001";
// export const PAYMENT_URL = "http://localhost:12004";
// export const LEAD_BASE_URL = "http://localhost:12005";
// export const CUSTOMER_BASE_URL = "http://localhost:12006";
// export const EXTERNAL_BASE_URL = "http://localhost:12008";


//UAT
export const ADMIN_BASE_URL = "https://uat.solar.gaadin.live";
export const PAYMENT_URL = "https://uat.solar.gaadin.live";
export const LEAD_BASE_URL = "https://uat.solar.gaadin.live";
export const CUSTOMER_BASE_URL = "https://uat.solar.gaadin.live";
export const EXTERNAL_BASE_URL = "https://uat.solar.gaadin.live";

//Main,Common URL
export const FETCH_ALL_CITIES_URL = "/admin/secure/api/v1/cities";
export const FETCH_ALL_STATES_URL = "/admin/secure/api/v1/states";
export const FETCH_ALL_COUNTRIES_URL = "/admin/secure/api/v1/countries";
export const FETCH_ALL_ROLES_URL = "/admin/secure/api/v1/roles";
export const FETCH_ALL_PRIVILEGES_URL = "/admin/secure/api/v1/privileges";
export const FETCH_ALL_ORGANIZATION = "/admin/secure/api/v1/organisation";
export const FETCH_ALL_SUBROLES_URL = "/admin/secure/api/v1/subroles";
export const FETCH_NEW_PRIVILEGES_API = "/admin/secure/api/v1/privileges/all"

//Auth URL
export const LOGIN_URL = "/admin/api/v1/login";

//Admin Service URL 
export const SAVE_ACCESS_CONTROL_URL = "/admin/secure/api/v1/createuser";
export const PORTAL_USER_LIST_URL = "/admin/secure/api/v1/dashboard/userlist";
export const VIEW_SINGLE_USERDATA_URL = "/admin/secure/api/v1/dashboard/viewuserdata";
export const EDIT_PORTAL_USER_URL = "/admin/secure/api/v1/dashboard/edituser";
export const DEACTIVATE_USER_URL = "/admin/secure/api/v1/dashboard/deactivateuser";
export const USER_APPROVAL_URL = "/admin/secure/api/v1/dashboard/userapproval"
export const CHANGE_USER_PASSWORD_API = "/admin/secure/api/v1/dashboard/user/changepass"
export const GET_SEND_OTP_API = "/admin/api/v1/dashboard/otplogin"
export const GET_RESEND_OTP_API = "/admin/api/v1/dashboard/resendOTP"
export const VALIDATE_OTP = "/admin/api/v1/dashboard/validateotp"
export const FORGET_PASSWORD_API = "/admin/api/v1/forget"

//Project Service URL
export const NEW_PROJECT_URL = "/customer/secure/api/v1/project/create";
export const UPDATE_PROJECT_URL = "/customer/secure/api/v1/project/update";
export const FETCH_ALL_PROJECT_URL = "/customer/secure/api/v1/project/all";
export const FETCH_SINGLE_VIEW_PROJECT_API = "/customer/secure/api/v1/project/singlefetch"
export const FETCH_COUNTRY_CODE_API = "/lead/secure/api/v1/fetch/all"
export const FETCH_CONTACT_THROUGH_EMAIL_API = "/lead/secure/api/v1/fetch/email/lead"
export const CALCULATE_PROJECT_SUMMARY_PRICE_API = "/customer/secure/api/v1/total/project_price"
export const PROPOSAL_URL_API = "/customer/secure/api/v1/update/project/proposalurl"
export const SEND_PROPOSAL_EMAIL_API = "/customer/secure/api/v1/project/proposal/email"
export const FETCH_INSIGHT_PROJECT_COUNT_API = "/customer/secure/api/v1/insight/project_status/count"
export const FETCH_INSIGHT_PROJECT_STATUS_API = "/customer/secure/api/v1/insight/project_status/list"

//Customer servive URL
export const FETCH_ALL_CONTACT_URL = "/customer/secure/api/v1/fetch/all/contacts";
export const GET_SINGLE_COTACT_URL = "/customer/secure/api/v1/fetch/contact";
export const UPDATE_SINGLE_COTACT_URL = "/customer/secure/api/v1/update/contact";
export const DELETE_SINGLE_COTACT_URL = "/customer/secure/api/v1/delete/contact";
export const VALIDATE_PROJECT_EMAIL_API = "/customer/secure/api/v1/project/email/exist"
export const CREATE_CONTACT_API = "/customer/secure/api/v1/create/contact"
export const VALIDATE_CONTACT_EMAIL_API = "/customer/secure/api/v1/contact/email/exist"
export const DOWNLOAD_PROPOSAL_API = "/customer/secure/api/v1/fetch/project/proposal"
export const FETCH_PROJECT_GRAPH_API = "/customer/secure/api/v1/fetch/project/graph"
export const UPDATE_PROJECT_STATUS_API = "/customer/secure/api/v1/project/status/update"
export const FETCH_ROOF_TYPE_LIST = "/customer/secure/api/v1/project/all/rooftype"
export const FETCH_DATA_SOURCE_LIST = "/customer/secure/api/v1/project/all/data_source"

// Lead service URL
export const CREATE_LEAD_API = "/lead/secure/api/v1/lead/create"
export const UPDATE_LEAD_API = "/lead/secure/api/v1/lead/update"
export const VIEW_ALL_LEAD_API = "/lead/secure/api/v1/lead/all"
export const VIEW_SINGLE_LEAD_API = "/lead/secure/api/v1/lead/fetch/singlelead"
export const VALIDATE_LEAD_EMAIL_API = "/lead/secure/api/v1/lead/email/exist"
export const FETCH_LEAD_GRAPH_API = "/lead/secure/api/v1/monthly/lead/graph"
export const CREATE_EMAIL_CAMPAIGN_API = "/lead/secure/api/v1/create/email/campaign"
export const CREATE_SMS_CAMPAIGN_API = "/lead/secure/api/v1/create/sms/campaign"
export const FETCH_ALL_EMAIL_CAMPAIGN = "/lead/secure/api/v1/fetch/all_email/campaign"
export const FETCH_ALL_SMS_CAMPAIGN = "/lead/secure/api/v1/fetch/all_sms/campaign"
export const FETCH_SINGLE_EMAIL_API = "/lead/secure/api/v1/fetch/one_email/campaign"
export const FETCH_SINGLE_SMS_API = "/lead/secure/api/v1/fetch/one_sms/campaign"
export const FETCH_CAMPAIGN_GRAPH_API = "/lead/secure/api/v1/monthly/campaign/graph"
export const UPDATE_LEAD_STATUS_API = "/lead/secure/api/v1/status/update"
export const INSIGHT_LEAD_CARD_COUNT_API = "/lead/secure/api/v1/insight/lead_status/count"
export const INSIGHT_LEAD_CARD_STATUS_DATA_API = "/lead/secure/api/v1/insight/lead_status/list"

// Panel Pricing URL 
export const CREATE_PANEL_PRICING_API = "/customer/secure/api/v1/create/panel_price"
export const FETCH_ALL_PANEL_PRICING_API = "/customer/secure/api/v1/fetch/all/panel_price"
export const FETCH_SINGLE_PANEL_PRICING_API = "/customer/secure/api/v1/fetch/single/panel_price"
export const UPDATE_PANEL_PRICING_API = "/customer/secure/api/v1/update/panel_price"
export const DELETE_PANEL_PRICING_API = "/customer/secure/api/v1/delete/single/panel_price"
export const FETCH_SINGLE_PANEL_MANUFACTURER_API = "/customer/secure/api/v1/single_detail/panel_price"

// Battery pricing URL
export const CREATE_BATTERY_PRICING_API = "/customer/secure/api/v1/create/battery_price"
export const FETCH_ALL_BATTERY_PRICING_API = "/customer/secure/api/v1/fetch/all/battery_price"
export const FETCH_SINGLE_BATTERY_PRICING_API = "/customer/secure/api/v1/fetch/single/battery_price"
export const DELETE_BATTERY_PRICING_API = "/customer/secure/api/v1/delete/single/battery_price"
export const UPDATE_BATTERY_PRICING_API = "/customer/secure/api/v1/update/battery_price"
export const FETCH_SINGLE_BATTERY_MANUFACTURER_API = "/customer/secure/api/v1/single_detail/battery_price"

// Inverter pricing URL
export const CREATE_INVERTER_PRICING_API = "/customer/secure/api/v1/create/inverter_price"
export const FETCH_ALL_INVERTER_PRICING_API = "/customer/secure/api/v1/fetch/all/inverter_price"
export const FETCH_SINGLE_INVERTER_PRICING_API = "/customer/secure/api/v1/fetch/single/inverter_price"
export const DELETE_INVERTER_PRICING_API = "/customer/secure/api/v1/delete/single/inverter_price"
export const UPDATE_INVERTER_PRICING_API = "/customer/secure/api/v1/update/inverter_price"
export const FETCH_SINGLE_INVERTER_MANUFACTURER_API = "/customer/secure/api/v1/single_detail/inverter_price"

// FINANCE API URL ENDPOINT
export const FETCH_FINANCE_NUMBER_OF_MONTHS = "/payment/secure/api/v1/dropdown/allmonths"
export const FETCH_PROJECT_LIST_WITH_EMAIL = "/customer/secure/api/v1/fetch/all/project_title"
export const FETCH_ALL_FINANCE_URL = "/payment/secure/api/v1/fetch_all/finance_details"
export const SAVE_FINANCE_URL = "/payment/secure/api/v1/create/finance_details"
export const FETCH_SINGLE_FINANCE_URL = "/payment/secure/api/v1/fetch_single/finance_details"
export const UPDATE_FINANCE_URL = "/payment/secure/api/v1/update/finance_details"

// EXTERNAL API URL ENDPOINT
export const SAVE_LEAD_EVENT_TYPE_API = "/external/secure/api/v1/create/eventtype"
export const UPDATE_LEAD_EVENT_TYPE_API = "/external/secure/api/v1/update/eventtype"
export const FETCH_ALL_LEAD_EVENT_API = "/external/secure/api/v1/all/event"
export const FETCH_SINGLE_LEAD_EVENT_API = "/external/secure/api/v1/fetch/single_event"
export const FETCH_TIME_SLOTS = "/external/secure/api/v1/event/time/dropdown"
export const DELETE_EVENT_SCHEDULE_API = "/external/secure/api/v1/delete/single_event"