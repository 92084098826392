import Axios from "axios";
import { GET_SINGLE_COTACT_URL, FETCH_ALL_CONTACT_URL, UPDATE_SINGLE_COTACT_URL, DELETE_SINGLE_COTACT_URL, CONATCT_BASE_URL, FETCH_CONTACT_THROUGH_EMAIL_API, CUSTOMER_BASE_URL, VALIDATE_PROJECT_EMAIL_API, CREATE_CONTACT_API, VALIDATE_CONTACT_EMAIL_API, DOWNLOAD_PROPOSAL_API, LEAD_BASE_URL, FETCH_PROJECT_GRAPH_API, CALCULATE_PROJECT_SUMMARY_PRICE_API, PROPOSAL_URL_API, SEND_PROPOSAL_EMAIL_API, FETCH_ROOF_TYPE_LIST, FETCH_DATA_SOURCE_LIST } from "../../components/config/config";
import { ApiHandler } from "./apiHandler";

// Create new contact api
export const createContactAction = (lead) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    // console.log("lead data", lead)
    const { firstname, lastname, email, mobile, address, city, state, country, postal_code, latitude, longitude } = lead
    const coordinates = { 'latitude': parseFloat(latitude), 'longitude': parseFloat(longitude) }

    return async dispatch => {
        dispatch({ type: 'CUSTOMER_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + CREATE_CONTACT_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "firstname": firstname,
                "lastname": lastname,
                "email": email,
                "mobile": parseInt(mobile),
                "address": address,
                "contact_source": "Lead",
                "city": city,
                "state": state,
                "country": country,
                "postal_code": postal_code,
                "coordinates": coordinates,
                "role": role,
                "operator": organisation,
            })
        })

        if (data) {
            dispatch({ type: 'CREATE_CONTACT_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// fetchAllContact api
export const getAllContactAction = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    // console.log(bearer)
    return async dispatch => {
        dispatch({ type: 'CUSTOMER_LOADING', payload: '' })
        // console.log(CUSTOMER_BASE_URL + FETCH_ALL_CONTACT_URL)
        const data = await ApiHandler(CUSTOMER_BASE_URL + FETCH_ALL_CONTACT_URL + "?role=" + role + "&organisation=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            dispatch({ type: 'FETCH_ALL_CONTACT', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// fetch single contact api
export const getSingleContactAction = (id) => {
    // console.log("id from action", id)
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'CUSTOMER_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + GET_SINGLE_COTACT_URL + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            dispatch({ type: 'GET_SINGLE_COTACT', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// FETCH CONTACT DATA THROUGH EMAIL API
export const fetchContactThroughEmailAction = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'CUSTOMER_LOADING', payload: '' })
        const data = await ApiHandler(LEAD_BASE_URL + FETCH_CONTACT_THROUGH_EMAIL_API + "?email=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            dispatch({ type: 'FETCH_CONTACT_THROUGH_EMAIL', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}
// VALIDATE PROJECT EMAIL API 
export const validateProjectEmailAction = (email, projectTitle) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'CUSTOMER_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + VALIDATE_PROJECT_EMAIL_API + "?email=" + email + "&project_title=" + projectTitle, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            // console.log("VALIDATE_PROJECT_EMAIL", data)
            dispatch({ type: 'VALIDATE_PROJECT_EMAIL', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// VALIDATE CONTACT EMAIL API
export const validateContactEmailAction = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'CUSTOMER_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + VALIDATE_CONTACT_EMAIL_API + "?email=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            dispatch({ type: 'VALIDATE_CONTACT_EMAIL_REDUCER', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// DOWNLOAD PROPOSAL API
export const downloadProposalAction = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'CUSTOMER_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + DOWNLOAD_PROPOSAL_API + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            dispatch({ type: 'DOWNLOAD_PROPOSAL_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// Fetch project graph api
export const fetchProjectGraphAction = () => {
    const { token, role } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'CUSTOMER_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + FETCH_PROJECT_GRAPH_API + "?role=" + role, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            dispatch({ type: 'FETCH_PROJECT_GRAPH_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}


// Calculate summary price for project
export const fetchSummaryPriceAction = (record) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    const { panel_price, battery_price, inverter_price } = record;
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'CUSTOMER_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + CALCULATE_PROJECT_SUMMARY_PRICE_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "panel_price": panel_price,
                "battery_price": battery_price,
                "inverter_price": inverter_price,
            })
        })

        if (data) {
            dispatch({ type: 'CALCULATE_PROJECT_SUMMARY_PRICE_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// Generate proposal URL for project
// import axios from 'axios';

export const generateProposalURL = (formData) => {
    // console.log(formData);
    return async dispatch => {
        const { token } = JSON.parse(localStorage.getItem('user'));
        const bearer = 'basic ' + token;

        // Start loading
        dispatch({ type: 'CUSTOMER_LOADING', payload: '' });

        // console.log("Form Data:");
        // console.log("project_title:", formData.get('project_title')); // Replace 'field1' with your actual field name
        // console.log("user_email:", formData.get('user_email')); // Replace 'field2' with your actual field name
        // console.log("proposal_url:", formData.get('proposal_url'));
        // console.log("image_url:", formData.get('image_url'));

        try {

            // Making the POST request using axios
            // console.log(CUSTOMER_BASE_URL + PROPOSAL_URL_API, formData)
            const response = await Axios.post(CUSTOMER_BASE_URL + PROPOSAL_URL_API, formData, {
                headers: {
                    'Authorization': bearer,
                    'content-type': 'application/x-www-form-urlencoded',
                    'x-ms-blob-type': 'BlockBlob',
                },
            });

            const data = response.data;
            console.log("data", data);
            // console.log("PDF uploaded successfully:", data.data.proposal_url);

            // Save proposal URL to local storage
            localStorage.setItem("Proposal_URL", data.data.proposal_url);

            // Dispatch success action with the data
            dispatch({ type: 'PROPOSAL_URL_GENERATE', payload: data });

        } catch (error) {
            console.error('Error generating proposal URL:', error);
        }
    };
};

// Send Proposal email
export const triggerProposalEmail = (sender_firstname, sender_org, client_firstname, client_lastname, client_email, client_address, proposal_link) => {
    // console.log(sender_firstname, sender_org, client_firstname, client_lastname, client_email, client_address, proposal_link)
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'CUSTOMER_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + SEND_PROPOSAL_EMAIL_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "sender_firstname": sender_firstname,
                "sender_org": sender_org,
                "client_firstname": client_firstname,
                "client_lastname": client_lastname,
                "client_email": client_email,
                "client_address": client_address,
                "proposal_link": proposal_link,
            })
        })

        if (data) {
            dispatch({ type: 'SEND_PROPOSAL_EMAIL', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}





