import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Card } from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import Geocode from "react-geocode";
import PureBreadcrumbs from "../../Breadcrums.js"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import "./leadGeneration.css"
import { fetchCountryCodeAction } from "../../../../store/actions/projectAction.js";
import { getPortalUserListAction } from "../../../../store/actions/accessAction.js";
import { createLeadAction, validateLeadEmailAction } from "../../../../store/actions/leadAction.js";


const CreateLead = (props) => {
    const [address, setAddress] = useState("");
    const [apiLoaded, setApiLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Define the initMap function that the Google Maps script will call
        window.initMap = () => {
            setApiLoaded(true);
        };

        // Check if the script is already loaded
        if (window.google && window.google.maps) {
            setApiLoaded(true);
        }
    }, []);


    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required('Firstname is required'),
        lastname: Yup.string().required('Lastname is required'),
        email: Yup.string().required('Email is required').email('Email is invalid'),
        mobile: Yup.number().required('Phone number is a required').typeError('Phone number is a required'),
        lead_status: Yup.string().required('Lead status is required'),
        address: Yup.string().required('Address is required'),
        latitude: Yup.number().required('Latitude is required').typeError('Latitude must be a number'),
        longitude: Yup.number().required('Longitude is required').typeError('Longitude must be a number'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        country: Yup.string().required('Country is required'),
        postal_code: Yup.string().required('Postal code is required'),
        leadsource: Yup.string().required('Lead Source is required'),
        description: Yup.string().required('Description is required'),

    });

    useEffect(() => {
        props.getCountryCode();
        props.getUserList();
    }, []);

    const { register, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "all"
    });

    const handleEmailChange = (e) => {
        const value = e.target.value;
        // console.log("value", value);

        if (value) {
            props.validateEmailApi(value)
        }
    }

    // Geolocation placeAutoComplete logic
    const handleSelect = async (address) => {
        try {
            const results = await geocodeByAddress(address);
            if (!results || results.length === 0) {
                throw new Error('No results found');
            }

            const latLng = await getLatLng(results[0]);
            const { lat, lng } = latLng;

            const res = await latiLongi(lat, lng);
            if (!res || !res.results || res.results.length === 0) {
                throw new Error('No response from latiLongi');
            }

            setError('')
            setAddress(address);
            setValue("address", address);
            setValue("latitude", lat);
            setValue("longitude", lng);

            let city, state, country, postCode;
            res.results[0].address_components.forEach((component) => {
                component.types.forEach((type) => {
                    switch (type) {
                        case "locality":
                            city = component.long_name;
                            setValue("city", city);
                            break;
                        case "administrative_area_level_1":
                            state = component.long_name;
                            setValue("state", state);
                            break;
                        case "country":
                            country = component.long_name;
                            setValue("country", country);
                            break;
                        case "postal_code":
                            postCode = component.long_name;
                            setValue("postal_code", postCode);
                            break;
                        default:
                            break;
                    }
                });
            });
        } catch (error) {
            console.error('Error:', error);
            setError('Failed to retrieve location details. Please try again.');
        }
    };

    const onSubmit = (data) => {
        //   console.log("datataaa",data)
        props.saveLead(data);

    };

    if (props.saveLeadRes && props.saveLeadRes.statuscode === 200) {
        alert("Lead has been created successfully");
        window.location.reload();
        // return <Redirect to='/dashboard/view_project' />;
    }
    if (props.saveLeadRes && props.saveLeadRes.statuscode === 405) {
        alert(props.saveLeadRes.error);
        window.location.reload();
        // return <Redirect to='/dashboard/view_project' />;
    }

    return (
        <Container fluid>
            <div className="row">
                <div className="col-lg-6 col-sm-12 mt-2">
                    <PureBreadcrumbs />
                </div>
            </div>
            <Row className="mb-2">
                <Col className="mt-3">
                    <h4>Create New Lead</h4>
                </Col>
            </Row>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row className="d-flex justify-content-center">
                    <Col lg={10}>
                        <Card className="tariffcard">
                            <Card.Body>
                                <Row className="py-2">
                                    <Col>
                                        <Card.Title>
                                            <span>Customer</span>
                                        </Card.Title>

                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    className={` ${errors.firstname ? 'is-invalid' : ''}`}
                                                    name="firstname"
                                                    placeholder="Firstname"
                                                    {...register("firstname")}
                                                    id="floatingInputGridFirstname"
                                                />
                                                <div className="invalid-feedback">{errors.firstname?.message}</div>
                                            </Col>
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    className={` ${errors.lastname ? 'is-invalid' : ''}`}
                                                    name="lastname"
                                                    placeholder="Lastname"
                                                    {...register("lastname")}
                                                    id="floatingInputGridLastname"
                                                />
                                                <div className="invalid-feedback">{errors.lastname?.message}</div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    className={` ${errors.email ? 'is-invalid' : ''}`}
                                                    name="email"
                                                    placeholder="Email"
                                                    {...register("email")}
                                                    onChange={handleEmailChange}
                                                    id="floatingInputGridEmail"
                                                />
                                                <div className="invalid-feedback">{errors.email?.message}</div>
                                                {props.validateEmailRes?.statuscode === 200 && <div className="text-danger">{props.validateEmailRes?.message}</div>}
                                            </Col>
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    className={` ${errors.mobile ? 'is-invalid' : ''}`}
                                                    name="mobile"
                                                    placeholder="Mobile number"
                                                    {...register("mobile")}
                                                    id="floatingInputGridMobileNumber"
                                                />
                                                <div className="invalid-feedback">{errors.mobile?.message}</div>
                                            </Col>
                                        </Row>

                                        { /*Address */}
                                        <Row className="mt-3">
                                            <Col md={12}>
                                                <Card.Title>
                                                    <span>Address Information</span>
                                                </Card.Title>
                                                {error && <div className="alert alert-danger">{error}</div>}
                                                {apiLoaded ? (
                                                    <PlacesAutocomplete
                                                        value={address}
                                                        onChange={(value) => setAddress(value)}
                                                        onSelect={handleSelect}
                                                    >
                                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                            <div>
                                                                <input
                                                                    {...getInputProps({
                                                                        placeholder: 'Search Places ...',
                                                                        className: `location-search-input ${errors.address ? 'is-invalid' : ''}`
                                                                    })}
                                                                    name="address"
                                                                />
                                                                <div className="invalid-feedback">{errors.address?.message}</div>
                                                                <div className="autocomplete-dropdown-container">
                                                                    {loading && <div>Loading...</div>}

                                                                    {suggestions.map((suggestion, index) => {
                                                                        const className = suggestion.active
                                                                            ? 'suggestion-item--active'
                                                                            : 'suggestion-item';
                                                                        const style = suggestion.active
                                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                        return (
                                                                            <div
                                                                                key={index}
                                                                                {...getSuggestionItemProps(suggestion, {
                                                                                    className,
                                                                                    style,
                                                                                })}
                                                                            >
                                                                                <span>{suggestion.description}</span>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </PlacesAutocomplete>
                                                ) : (
                                                    <div>Loading Google Maps...</div>
                                                )}
                                            </Col>
                                        </Row>
                                        { /*Address */}

                                        <Row className="mt-3">
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={` ${errors.latitude ? 'is-invalid' : ''}`}
                                                    name="latitude"
                                                    placeholder="Latitude"
                                                    {...register("latitude")}
                                                    id="floatingInputGridLatitude"
                                                />
                                                <div className="invalid-feedback">{errors.latitude?.message}</div>
                                            </Col>
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={` ${errors.longitude ? 'is-invalid' : ''}`}
                                                    name="longitude"
                                                    placeholder="Longitude"
                                                    {...register("longitude")}
                                                    id="floatingInputGridLongitude"
                                                />
                                                <div className="invalid-feedback">{errors.longitude?.message}</div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={` ${errors.city ? 'is-invalid' : ''}`}
                                                    name="city"
                                                    placeholder="City"
                                                    {...register("city")}
                                                    id="floatingInputGridCity"
                                                />
                                                <div className="invalid-feedback">{errors.city?.message}</div>
                                            </Col>
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={` ${errors.state ? 'is-invalid' : ''}`}
                                                    name="state"
                                                    placeholder="State/Province"
                                                    {...register("state")}
                                                    id="floatingInputGridState"
                                                />
                                                <div className="invalid-feedback">{errors.state?.message}</div>
                                            </Col>

                                        </Row>

                                        <Row className="mt-3">
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={` ${errors.country ? 'is-invalid' : ''} `}
                                                    name="country"
                                                    placeholder="Country"
                                                    {...register("country")}
                                                    id="floatingInputGridCountry"
                                                />
                                                <div className="invalid-feedback">{errors.country?.message}</div>
                                            </Col>
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={` ${errors.postal_code ? 'is-invalid' : ''}`}
                                                    name="postal_code"
                                                    placeholder="Postal Code"
                                                    {...register("postal_code")}
                                                    id="floatingInputGridPostal_code"
                                                />
                                                <div className="invalid-feedback">{errors.postal_code?.message}</div>
                                            </Col>

                                        </Row>

                                        <Row className="mt-3">
                                            <Col>
                                                <select
                                                    className={`input_css ${errors.leadsource ? 'is-invalid' : ''}`}
                                                    name="leadsource"
                                                    {...register("leadsource")}
                                                    id="floatingInputGridLeadStatus"
                                                >
                                                    <option value="">Select Lead Source</option>
                                                    <option value="Call">Call</option>
                                                    <option value="Email">Email</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.leadsource?.message}</div>
                                            </Col>

                                            <Col>
                                                <select
                                                    className={`input_css ${errors.lead_status ? 'is-invalid' : ''}`}
                                                    name="lead_status"
                                                    {...register("lead_status")}
                                                    id="floatingInputGridLeadStatus"
                                                >
                                                    <option value="" className="select-default-option">Select Lead Status</option>
                                                    <option value="prospecting">Prospecting</option>
                                                    <option value="contacted">Contacted</option>
                                                    <option value="proposal_delivered">Proposal Delivered</option>
                                                    <option value="closed_Lost">Closed/Lost</option>
                                                    <option value="closed_Won">Closed/Won</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.lead_status?.message}</div>
                                            </Col>
                                        </Row>

                                        <div className="mt-3">
                                            <input type="text" className={` ${errors.description ? 'is-invalid' : ''}`} name="description" id="floatingInputGridDescription" placeholder="Description" {...register("description")} />
                                            <div className="invalid-feedback">{errors.description?.message}</div>
                                        </div>

                                        <Row className="mt-3 d-flex justify-content-sm-center">
                                            <Col lg={6} md={3} sm={12} xs={12} className="mt-2 d-flex justify-content-start justify-content-md-start">
                                                <button className="lgn-btn" type="button" onClick={() => {
                                                    window.location.reload();
                                                }}> {("RESET")} </button>
                                            </Col>
                                            <Col lg={6} md={9} sm={12} xs={12} className='d-md-flex justify-content-end mt-2'>
                                                {props.loading ?
                                                    <button className="lgn-btn" type="button" disabled>
                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp;
                                                        {("Saving...")}
                                                    </button>
                                                    :
                                                    <button className="lgn-btn" type="submit" disabled={(props.validateEmailRes?.statuscode === 200)} onClick={handleSubmit(onSubmit)}>{("CREATE NEW LEAD")}</button>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </form >
        </Container >
    );
};

const mapStateToProps = (state) => ({
    loading: state.lead.isLoading,
    fetchCountryCodeRes: state.project.fetchCountryCode,
    list: state.access.portalUserList,
    saveLeadRes: state.lead.createNewLead,
    validateEmailRes: state.lead.validateEmail,
});

const mapDispatchToProps = (dispatch) => ({
    getCountryCode: () => dispatch(fetchCountryCodeAction()),
    getUserList: () => dispatch(getPortalUserListAction()),
    saveLead: (data) => dispatch(createLeadAction(data)),
    validateEmailApi: (id) => dispatch(validateLeadEmailAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateLead);
const latiLongi = (a, b) => {
    return Geocode.fromLatLng(a, b).then(res => { return res })
    // console.log("Gaadin",res); 
}