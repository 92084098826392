// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emi-calculator-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.emi-details {
    width: 50%;
}

.emi-details h2,
.emi-details h3 {
    margin: 10px 0;
    font-weight: bold;
}

.emi-value,
.interest-value,
.total-payment {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.chart-container {
    width: 40%;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/finance/finance.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,2CAA2C;AAC/C;;AAEA;IACI,UAAU;AACd;;AAEA;;IAEI,cAAc;IACd,iBAAiB;AACrB;;AAEA;;;IAGI,eAAe;IACf,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,UAAU;AACd","sourcesContent":[".emi-calculator-container {\n    display: flex;\n    justify-content: space-between;\n    padding: 20px;\n    background-color: #f8f8f8;\n    border-radius: 10px;\n    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);\n}\n\n.emi-details {\n    width: 50%;\n}\n\n.emi-details h2,\n.emi-details h3 {\n    margin: 10px 0;\n    font-weight: bold;\n}\n\n.emi-value,\n.interest-value,\n.total-payment {\n    font-size: 24px;\n    font-weight: bold;\n    color: #333;\n}\n\n.chart-container {\n    width: 40%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
