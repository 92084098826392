import { CREATE_BATTERY_PRICING_API, CREATE_INVERTER_PRICING_API, CREATE_PANEL_PRICING_API, CUSTOMER_BASE_URL, DELETE_BATTERY_PRICING_API, DELETE_INVERTER_PRICING_API, DELETE_PANEL_PRICING_API, FETCH_ALL_BATTERY_PRICING_API, FETCH_ALL_INVERTER_PRICING_API, FETCH_ALL_PANEL_PRICING_API, FETCH_ALL_PRICING_API, FETCH_SINGLE_BATTERY_MANUFACTURER_API, FETCH_SINGLE_BATTERY_PRICING_API, FETCH_SINGLE_INVERTER_MANUFACTURER_API, FETCH_SINGLE_INVERTER_PRICING_API, FETCH_SINGLE_PANEL_MANUFACTURER_API, FETCH_SINGLE_PANEL_PRICING_API, FETCH_SINGLE_PRICING_API, PAYMENT_URL, UPDATE_BATTERY_PRICING_API, UPDATE_INVERTER_PRICING_API, UPDATE_PANEL_PRICING_API, UPDATE_PRICING_API } from "../../components/config/config";
import { ApiHandler } from "./apiHandler";

// Create panel pricing api
export const createPanelPricingAction = (data) => {
    const { token, name, role, organisation, userid } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    // console.log("data data", data)
    const { manufacture, technology, ratedOutputPower, Imp, Isc, Vmp, Voc, height, panelAdditionalCharge, pricePerModule, thickness, weight, width } = data;
    return async dispatch => {
        dispatch({ type: 'PRICING_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + CREATE_PANEL_PRICING_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "manufacturer": manufacture,
                "technology": technology,
                "rated_output_power": ratedOutputPower,
                "width": width,
                "height": height,
                "thickness": thickness,
                "weight": weight,
                "vmp": Vmp,
                "voc": Voc,
                "isc": Isc,
                "imp": Imp,
                "price_per_module": pricePerModule,
                "panel_additional_charges": panelAdditionalCharge,
                "role": role,
                "operator": organisation,

            })
        })

        if (data) {
            dispatch({ type: 'CREATE_PANEL_PRICING_API', payload: data })
        } else {
            console.log("No data found from server")
        }

    }
}

// Fetch all panel pricing api
export const fetchAllPanelPricingAction = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
    // console.log("organisation", organisation)
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'PRICING_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + FETCH_ALL_PANEL_PRICING_API + "?role=" + role + "&organisation=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            dispatch({ type: 'FETCH_ALL_PANEL_PRICING_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// fetch single panel pricing api
export const fetchSinglePanelPricingAction = (id) => {
    const { token, role } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'PRICING_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + FETCH_SINGLE_PANEL_PRICING_API + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            dispatch({ type: 'FETCH_SINGLE_PANEL_PRICING_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// Update panel pricing api
export const updatePanelPricingAction = (data, id) => {
    const { token, name, role, organisation, userid } = JSON.parse(localStorage.getItem('user'))
    // console.log("Data", data)
    var bearer = 'basic ' + token;
    const { manufacture, technology, ratedOutputPower, Imp, Isc, Vmp, Voc, height, panelAdditionalCharge, pricePerModule, thickness, weight, width } = data;

    return async dispatch => {
        dispatch({ type: 'PRICING_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + UPDATE_PANEL_PRICING_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "id": id,
                "manufacturer": manufacture,
                "technology": technology,
                "rated_output_power": ratedOutputPower,
                "width": width,
                "height": height,
                "thickness": thickness,
                "weight": weight,
                "vmp": Vmp,
                "voc": Voc,
                "isc": Isc,
                "imp": Imp,
                "price_per_module": pricePerModule,
                "panel_additional_charges": panelAdditionalCharge,
                "role": role,
                "operator": organisation,

            })
        })

        if (data) {
            dispatch({ type: 'UPDATE_PANEL_PRICING_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// delete single panel pricing api
export const deleteSinglePanelPricingAction = (id) => {
    const { token, role } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'PRICING_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + DELETE_PANEL_PRICING_API + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            dispatch({ type: 'DELETE_PANEL_PRICING_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

////////////////// Battery Pricing API ///////////////////////

// Create battery pricing api
export const createBatteryPricingAction = (data) => {
    const { token, name, role, organisation, userid } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    // console.log("data data", data)
    const { manufacture, sku, total_energy, max_continuous_power, battery_additional_charges, price_per_battery, efficiency } = data;
    return async dispatch => {
        dispatch({ type: 'PRICING_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + CREATE_BATTERY_PRICING_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "manufacturer": manufacture,
                "sku": sku,
                "total_energy": total_energy,
                "max_continuous_power": max_continuous_power,
                "price_per_battery": price_per_battery,
                "efficiency": efficiency,
                "battery_additional_charges": battery_additional_charges,
                "role": role,
                "operator": organisation,
            })
        })

        if (data) {
            dispatch({ type: 'CREATE_BATTERY_PRICING_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// FETCH_ALL_BATTERY_API
export const fetchAllBatteryPricingAction = () => {
    const { token, role } = JSON.parse(localStorage.getItem('user'))
    // console.log("organisation", organisation)
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'PRICING_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + FETCH_ALL_BATTERY_PRICING_API + "?role=" + role, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            dispatch({ type: 'FETCH_ALL_BATTERY_PRICING_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// DELETE_BATTERY_PRICING_API
export const deleteBatteryPricingApi = (id) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
    // console.log("organisation", organisation)
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'PRICING_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + DELETE_BATTERY_PRICING_API + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            dispatch({ type: 'DELETE_BATTERY_PRICING_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// fetch single battery pricing api
export const fetchSingleBatteryPricingAction = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'PRICING_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + FETCH_SINGLE_BATTERY_PRICING_API + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            // console.log(" FETCH_SINGLE_BATTERY_PRICING_API----", data);
            dispatch({ type: 'FETCH_SINGLE_BATTERY_PRICING_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}


export const updateBatteryPricingAction = (data, id) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
    // console.log("Data", data)
    var bearer = 'basic ' + token;
    const { manufacturer, sku, total_energy, max_continuous_power, battery_additional_charges, price_per_battery, efficiency } = data;

    return async dispatch => {
        dispatch({ type: 'PRICING_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + UPDATE_BATTERY_PRICING_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "id": id,
                "manufacturer": manufacturer,
                "sku": sku,
                "total_energy": total_energy,
                "max_continuous_power": max_continuous_power,
                "price_per_battery": price_per_battery,
                "efficiency": efficiency,
                "battery_additional_charges": battery_additional_charges,
                "role": role,
                "operator": organisation,
            })
        })

        if (data) {
            // console.log(" UPDATE_BATTERY_PRICING_API----", data);
            dispatch({ type: 'UPDATE_BATTERY_PRICING_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

////////////////// Inverter Pricing API ///////////////////////

// Create INVERTER pricing api
export const createInverterPricingAction = (data) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    // console.log("data data", data)
    const { manufacturer, efficiency, technology, rated_output_power, rated_output_voltage, number_of_mppts, maximum_vmpp, minimum_vmpp, phase, max_ac_output_current, price_per_inverter, inverter_additional_charges } = data;
    return async dispatch => {
        dispatch({ type: 'PRICING_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + CREATE_INVERTER_PRICING_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "manufacturer": manufacturer,
                "technology": technology,
                "rated_output_power": rated_output_power,
                "rated_output_voltage": rated_output_voltage,
                "number_of_mppts": number_of_mppts,
                "minimum_vmpp": minimum_vmpp,
                "maximum_vmpp": maximum_vmpp,
                "efficiency": efficiency,
                "phase": phase,
                "max_ac_output_current": max_ac_output_current,
                "price_per_inverter": price_per_inverter,
                "inverter_additional_charges": inverter_additional_charges,
                "role": role,
                "operator": organisation,
            })
        })

        if (data) {
            // console.log(" CREATE_INVERTER_PRICING_API----", data);
            dispatch({ type: 'CREATE_INVERTER_PRICING_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// FETCH_ALL_INVERTER_API
export const fetchAllInverterPricingAction = () => {
    const { token, role } = JSON.parse(localStorage.getItem('user'))
    // console.log("organisation", organisation)
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'PRICING_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + FETCH_ALL_INVERTER_PRICING_API + "?role=" + role, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            // console.log(" FETCH_ALL_INVERTER_PRICING_API----", data);
            dispatch({ type: 'FETCH_ALL_INVERTER_PRICING_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// DELETE_INVERTER_PRICING_API
export const deleteInverterPricingApi = (id) => {
    const { token, } = JSON.parse(localStorage.getItem('user'))
    // console.log("organisation", organisation)
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'PRICING_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + DELETE_INVERTER_PRICING_API + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            // console.log(" DELETE_INVERTER_PRICING_API----", data);
            dispatch({ type: 'DELETE_INVERTER_PRICING_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// fetch single INVERTER pricing api
export const fetchSingleInverterPricingAction = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'PRICING_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + FETCH_SINGLE_INVERTER_PRICING_API + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            // console.log(" FETCH_SINGLE_INVERTER_PRICING_API----", data);
            dispatch({ type: 'FETCH_SINGLE_INVERTER_PRICING_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

// UPDATE INVERTER PRICE
export const updateInverterPricingAction = (data, id) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
    // console.log("Data", data)
    var bearer = 'basic ' + token;
    const { manufacturer, efficiency, technology, rated_output_power, rated_output_voltage, number_of_mppts, maximum_vmpp, minimum_vmpp, phase, max_ac_output_current, price_per_inverter, inverter_additional_charges } = data;

    return async dispatch => {
        dispatch({ type: 'PRICING_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + UPDATE_INVERTER_PRICING_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "id": id,
                "manufacturer": manufacturer,
                "technology": technology,
                "rated_output_power": rated_output_power,
                "rated_output_voltage": rated_output_voltage,
                "number_of_mppts": number_of_mppts,
                "minimum_vmpp": minimum_vmpp,
                "maximum_vmpp": maximum_vmpp,
                "efficiency": efficiency,
                "phase": phase,
                "max_ac_output_current": max_ac_output_current,
                "price_per_inverter": price_per_inverter,
                "inverter_additional_charges": inverter_additional_charges,
                "role": role,
                "operator": organisation,
            })
        })

        if (data) {
            // console.log(" UPDATE_INVERTER_PRICING_API----", data);
            dispatch({ type: 'UPDATE_INVERTER_PRICING_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchSinglePanelManufacturerAction = (manufacturer) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    // console.log(CUSTOMER_BASE_URL + FETCH_SINGLE_PANEL_MANUFACTURER_API + "?manufacturer=" + manufacturer)
    return async dispatch => {
        dispatch({ type: 'PRICING_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + FETCH_SINGLE_PANEL_MANUFACTURER_API + "?manufacturer=" + manufacturer, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            // console.log(" FETCH_SINGLE_PANEL_DETAILS_API----", data);
            dispatch({ type: 'FETCH_SINGLE_PANEL_DETAILS_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchSingleInverterManufacturerAction = (manufacturer) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'PRICING_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + FETCH_SINGLE_INVERTER_MANUFACTURER_API + "?manufacturer=" + manufacturer, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            // console.log(" FETCH_SINGLE_INVERTER_DETAILS_API----", data);
            dispatch({ type: 'FETCH_SINGLE_INVERTER_DETAILS_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}

export const fetchSingleBatteryManufacturerAction = (manufacturer) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return async dispatch => {
        dispatch({ type: 'PRICING_LOADING', payload: '' })
        const data = await ApiHandler(CUSTOMER_BASE_URL + FETCH_SINGLE_BATTERY_MANUFACTURER_API + "?manufacturer=" + manufacturer, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (data) {
            // console.log(" FETCH_SINGLE_BATTERY_DETAILS_API----", data);
            dispatch({ type: 'FETCH_SINGLE_BATTERY_DETAILS_API', payload: data })
        } else {
            console.log("No data found from server")
        }
    }
}
