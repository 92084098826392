// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dot {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: inline-block;
    line-height: 25px;
    color: white;
    font-weight: bold;
    text-align: center;
    background-color: #007bff;
}

.three-dots {
    cursor: pointer;
    font-size: 24px;
}

.popup-btn {
    background: var(--button-color);
    color: var(--background);
    position: relative;
    width: 70%;
    max-width: 57px;
    min-width: 78px;
    height: 29px;
    border-radius: 6px !important;
    border: 2px solid var(--button-color);
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 400;
    transition: background 0.3s ease, color 0.3s ease;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/lead/LeadGeneration/leadGeneration.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,iBAAiB;IACjB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,+BAA+B;IAC/B,wBAAwB;IACxB,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,eAAe;IACf,YAAY;IACZ,6BAA6B;IAC7B,qCAAqC;IACrC,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,iDAAiD;AACrD","sourcesContent":[".dot {\n    width: 25px;\n    height: 25px;\n    border-radius: 50%;\n    display: inline-block;\n    line-height: 25px;\n    color: white;\n    font-weight: bold;\n    text-align: center;\n    background-color: #007bff;\n}\n\n.three-dots {\n    cursor: pointer;\n    font-size: 24px;\n}\n\n.popup-btn {\n    background: var(--button-color);\n    color: var(--background);\n    position: relative;\n    width: 70%;\n    max-width: 57px;\n    min-width: 78px;\n    height: 29px;\n    border-radius: 6px !important;\n    border: 2px solid var(--button-color);\n    cursor: pointer;\n    font-size: 0.8rem;\n    font-weight: 400;\n    transition: background 0.3s ease, color 0.3s ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
