import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Card } from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import Geocode from "react-geocode";
import PureBreadcrumbs from "../../Breadcrums.js"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import "../customerCard.css"
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import { getSingleContactAction } from "../../../../store/actions/customerAction.js";
import { FaAngleDoubleDown } from "react-icons/fa";


const ViewSingleContact = (props) => {
    const { id } = useParams();
    const [address, setAddress] = useState("");


    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required('Firstname is required'),
        lastname: Yup.string().required('Lastname is required'),
        email: Yup.string().required('Email is required').email('Email is invalid'),
        mobile: Yup.number().required('Phone number is a required').typeError('Phone number is a required'),
    });

    useEffect(() => {
        props.getSingleContactData(id);
    }, []);

    const { register, handleSubmit, reset, getValues, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "all"
    });

    const singleData = props.getSingleContactRes && props.getSingleContactRes.data;
    // console.log("singleData", singleData);

    useEffect(() => {
        if (singleData) {
            const {
                firstname,
                lastname,
                email,
                mobile,
                address,
                country,
                state,
                city,
                postal_code,
                coordinates: { lat, lng } = {},
            } = singleData;

            setValue("firstname", firstname);
            setValue("lastname", lastname);
            setValue("email", email);
            setValue("mobile", mobile);
            setValue("address", address);
            setAddress(address)
            setValue("country", country);
            setValue("state", state);
            setValue("city", city);
            setValue("postal_code", postal_code);
            setValue("latitude", lat);
            setValue("longitude", lng);

        }
    }, [singleData, setValue]);

    return (
        <Container fluid className="pb-4">
            <div className="row">
                <div className="col-lg-6 col-sm-12 mt-2">
                    <PureBreadcrumbs />
                </div>
            </div>
            <Row className="mb-2">
                <Col className="mt-3">
                    <h4>View Contact</h4>
                </Col>
            </Row>
            <form>
                <Row className="d-flex justify-content-center">
                    <Col lg={10}>
                        <Card className="tariffcard">
                            <Card.Body>
                                <Row className="py-2">
                                    <Col>
                                        <Card.Title>
                                            <span>Customer</span>
                                        </Card.Title>

                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    className={`input_login ${errors.email ? 'is-invalid' : ''}`}
                                                    name="email"
                                                    placeholder="Email"
                                                    {...register("email")}
                                                    disabled
                                                />
                                                <div className="invalid-feedback">{errors.email?.message}</div>
                                            </Col>
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    className={`input_login ${errors.mobile ? 'is-invalid' : ''}`}
                                                    name="mobile"
                                                    placeholder="Mobile number"
                                                    {...register("mobile")}
                                                    disabled
                                                />
                                                <div className="invalid-feedback">{errors.mobile?.message}</div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    className={`input_login ${errors.firstname ? 'is-invalid' : ''}`}
                                                    name="firstname"
                                                    placeholder="Firstname"
                                                    {...register("firstname")}
                                                    disabled
                                                />
                                                <div className="invalid-feedback">{errors.firstname?.message}</div>
                                            </Col>
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    className={`input_login ${errors.lastname ? 'is-invalid' : ''}`}
                                                    name="lastname"
                                                    placeholder="Lastname"
                                                    {...register("lastname")}
                                                    disabled
                                                />
                                                <div className="invalid-feedback">{errors.lastname?.message}</div>
                                            </Col>
                                        </Row>


                                        <Row className="mt-3">
                                            <Col md={12}>
                                                <input
                                                    type="text"
                                                    className={`input_login `}
                                                    name="address"
                                                    placeholder="address"
                                                    {...register("address")}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={`input_login `}
                                                    name="latitude"
                                                    placeholder="Latitude"
                                                    {...register("latitude")}
                                                    disabled
                                                />
                                                {/* <div className="invalid-feedback">{errors.latitude?.message}</div> */}
                                            </Col>
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={`input_login `}
                                                    name="longitude"
                                                    placeholder="Longitude"
                                                    {...register("longitude")}
                                                    disabled
                                                />
                                                {/* <div className="invalid-feedback">{errors.longitude?.message}</div> */}
                                            </Col>

                                        </Row>

                                        <Row className="mt-3">
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={`input_login `}
                                                    name="city"
                                                    placeholder="City"
                                                    {...register("city")}
                                                    disabled
                                                />
                                                {/* <div className="invalid-feedback">{errors.city?.message}</div> */}
                                            </Col>
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={`input_login `}
                                                    name="state"
                                                    placeholder="State/Province"
                                                    {...register("state")}
                                                    disabled
                                                />
                                                {/* <div className="invalid-feedback">{errors.state?.message}</div> */}
                                            </Col>
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={`input_login `}
                                                    name="country"
                                                    placeholder="Country"
                                                    {...register("country")}
                                                    disabled
                                                />
                                                {/* <div className="invalid-feedback">{errors.country?.message}</div> */}
                                            </Col>
                                            <Col>
                                                <input
                                                    type="text"
                                                    className={`input_login `}
                                                    name="postal_code"
                                                    placeholder="Postal Code"
                                                    {...register("postal_code")}
                                                    disabled
                                                />
                                                {/* <div className="invalid-feedback">{errors.postal_code?.message}</div> */}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </form >
        </Container >
    );
};

const mapStateToProps = (state) => ({
    loading: state.customer.isLoading,
    getSingleContactRes: state.customer.getSingleContact,
});

const mapDispatchToProps = (dispatch) => ({
    getSingleContactData: (id) => dispatch(getSingleContactAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleContact);
const latiLongi = (a, b) => {
    return Geocode.fromLatLng(a, b).then(res => { return res })
    // console.log("Gaadin",res); 
}