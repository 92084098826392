import React, { useEffect } from 'react'
import { connect } from "react-redux";
import { Row, Col, Card } from 'react-bootstrap';
import "./projectInsight.css"
import { fetchInsightProjectCountAction } from '../../../../store/actions/projectAction';
import StatusCard from '../statusCardComponent';

function ProjectInsightCard(props) {

    useEffect(() => {
        props.fetchAllProjectCountApi();
    }, [])


    const projectData = props.list && props.list;
    // console.log(projectData)
    const newProject = projectData?.new;
    const inprogressProject = projectData?.in_progress;
    const completedProject = projectData?.completed;
    const lostProject = projectData?.lost;
    const totalProject = (newProject + inprogressProject + completedProject + lostProject)

    const percentage = (partialValue, totalValue) => {
        // console.log("percentage", Math.round((100 * partialValue) / totalValue));
        return Math.round((100 * partialValue) / totalValue);
    }
    const newProjectCountPercentage = percentage(newProject, totalProject)
    const inprogressProjectCountPercentage = percentage(inprogressProject, totalProject)
    const completedProjectCountPercentage = percentage(completedProject, totalProject)
    const lostProjectPercentage = percentage(lostProject, totalProject)

    const leadStatusCardData = [
        { title: 'New', path: 'New', count: newProject, percentage: newProjectCountPercentage, color: '#4a90e2' },
        { title: 'On Going', path: 'In Progress', count: inprogressProject, percentage: inprogressProjectCountPercentage, color: '#eded37' },
        { title: 'Completed', path: 'Completed', count: completedProject, percentage: completedProjectCountPercentage, color: '#7cd16f' },
        { title: 'Lost', path: 'Lost', count: lostProject, percentage: lostProjectPercentage, color: '#db2121' },
    ];

    // console.log("leadStatusCardData", leadStatusCardData);

    return (
        <>
            <Row className='mt-2'>
                {leadStatusCardData?.map((status, idx) => (
                    <Col xs={12} md={6} className="mt-3" key={idx}>
                        <StatusCard
                            key={idx}
                            link={`/dashboard/home/insightProjectStatus/${status.path}`}
                            title={status.title}
                            count={status.count}
                            percentage={status.percentage}
                            color={status.color}
                        />
                    </Col>
                ))}
            </Row>
        </>

    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.project.isLoading,
        list: state.project.fetchProjectCount
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchAllProjectCountApi: () => dispatch(fetchInsightProjectCountAction()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectInsightCard)
