import { ADMIN_BASE_URL, LOGIN_URL, VALIDATE_OTP, GET_SEND_OTP_API, GET_RESEND_OTP_API, FORGET_PASSWORD_API } from "../../components/config/config"
import { ApiHandler } from "./apiHandler";
export const authAction = (userid, password) => {
  return async dispatch => {
    dispatch({ type: 'AUTH_LOADING', payload: '' });

    try {
      const response = await fetch(ADMIN_BASE_URL + LOGIN_URL, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',

        },
        body: JSON.stringify({
          "userid": userid,
          'password': password
        })
      });

      if (!response.ok) {
        // Handle non-200 responses (e.g., 400, 500)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("login", data);
      localStorage.setItem('user', JSON.stringify(data));
      dispatch({ type: 'LOGIN_USER', payload: data });

    } catch (error) {
      // Handle errors here
      console.error("An error occurred:", error);
    }
  }
}


export const logoutAction = () => {
  return dispatch => {
    localStorage.removeItem('user');
    dispatch({ type: 'LOGOUT_USER', payload: {} })
  }
}

export const validateOTP = (data2) => {
  const { userid, otp } = data2
  // console.log("validateOTP", userid,otp);
  return async dispatch => {

    dispatch({ type: 'LOGIN_LOADING', payload: '' })
    const data = await ApiHandler(ADMIN_BASE_URL + VALIDATE_OTP, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "userid": userid,
        "otp": otp,
      })
    })

    if (data) {
      // console.log("data from validate action otp",data);
      localStorage.setItem("forgotpassword", JSON.stringify(data2))
      dispatch({ type: 'VALIDATE_USER_OTP', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const getSendOTP = (data) => {
  const { userid } = data
  // console.log("userid from action send otp",userid)
  return async dispatch => {
    dispatch({ type: 'LOGIN_LOADING', payload: '' })
    const data = await ApiHandler(ADMIN_BASE_URL + GET_SEND_OTP_API + "?userid=" + userid, {
      method: "GET",
      headers: {

        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'GET_SEND_OTP', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}
export const getReSendOTP = (data) => {
  const { userid } = data
  // console.log("userid from action resend otp",userid)
  return async dispatch => {
    dispatch({ type: 'LOGIN_LOADING', payload: '' })
    const data = await ApiHandler(ADMIN_BASE_URL + GET_RESEND_OTP_API + "?userid=" + userid, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'GET_RESEND_OTP', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}
export const forgetPassword = (record) => {
  const { newpassword } = record;
  const { userid } = JSON.parse(localStorage.getItem("forgotpassword"));
  // console.log(newpassword)
  return async dispatch => {
    dispatch({ type: 'LOGIN_LOADING', payload: '' })
    const data = await ApiHandler(ADMIN_BASE_URL + FORGET_PASSWORD_API, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        'userid': userid,
        'password': newpassword
      })
    })
    if (data) {
      dispatch({ type: 'FORGET_USER_PASSWORD', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}