import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import { fetchSingleViewProjectAction } from "../../../../store/actions/projectAction.js";
import PureBreadcrumbs from "../../Breadcrums.js";
import { useParams } from "react-router-dom";
import "../homecard.css";

const ViewSingleProject = (props) => {
    const { id } = useParams();
    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`; // Return in dd/mm/yyyy hh:mm:ss format
    };

    useEffect(() => {
        props.getSingleProjectData(id);
    }, [id]);

    const data = props.fetchSingleProjectRes && props.fetchSingleProjectRes.data;
    // console.log(data)

    return (
        <Container fluid className="mt-4 pb-4">
            <PureBreadcrumbs />
            <Row className="mt-4">
                <Col lg={8}>
                    <Card>
                        <Card.Header as="h5">Project Details</Card.Header>
                        <Card.Body>

                            {/* Contact Details */}
                            <Row className="mt-3 separator-row">
                                <Col md={12}>
                                    <Card.Title>Contact Data</Card.Title>
                                    <Form>
                                        <Row className="pt-2">
                                            <Col md={4}>
                                                <Form.Group controlId="contactFirstName">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={data?.contact_data?.firstname || 'N/A'}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="contactLastName">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={data?.contact_data?.lastname || 'N/A'}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="contactEmail">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        value={data?.contact_data?.email || 'N/A'}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2">
                                            <Col md={4}>
                                                <Form.Group controlId="contactMobile">
                                                    <Form.Label>Mobile</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={data?.contact_data?.mobile || 'N/A'}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="contactAddress">
                                                    <Form.Label>Address</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={data?.contact_data?.address || 'N/A'}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="contactCity">
                                                    <Form.Label>City</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={data?.contact_data?.city || 'N/A'}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2">
                                            <Col md={4}>
                                                <Form.Group controlId="contactState">
                                                    <Form.Label>State</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={data?.contact_data?.state || 'N/A'}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="contactCountry">
                                                    <Form.Label>Country</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={data?.contact_data?.country || 'N/A'}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="contactPostalCode">
                                                    <Form.Label>Postal Code</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={data?.contact_data?.postal_code || 'N/A'}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>

                            {/* Site and Energy Information */}

                            <Row className="mt-3 separator-row">
                                <Col md={12}>
                                    <Card.Title>Site Details</Card.Title>
                                    <Form>
                                        <Row className="pt-2">
                                            <Col md={4}>
                                                <Form.Group controlId="siteRoofType">
                                                    <Form.Label>Roof Type</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={data?.site_details?.roof_type || 'N/A'}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="siteEnergyDataSource">
                                                    <Form.Label>Energy Data Source</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={data?.site_details?.energy_data_source || 'N/A'}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="siteNumberOfStories">
                                                    <Form.Label>Number of Stories</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={data?.site_details?.number_of_stories || 'N/A'}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2">
                                            <Col md={4}>
                                                <Form.Group controlId="siteCellularCoverage">
                                                    <Form.Label>Cellular Coverage</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={data?.site_details?.cellular_coverage || 'N/A'}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="sitePhase">
                                                    <Form.Label>Phase</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={data?.site_details?.phase || 'N/A'}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="siteElectricityUtility">
                                                    <Form.Label>Electricity Utility</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={data?.site_details?.electricity_utility || 'N/A'}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>

                            {/* Battery,Inverter and Panel */}

                            <Row className="mt-3">
                                <Col md={4} className="separator_col">
                                    <Card.Title>Panel Details</Card.Title>
                                    <Form>
                                        <Form.Group controlId="panelManufacturer">
                                            <Form.Label>Manufacturer</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.panel?.manufacturer || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="panelTechnology">
                                            <Form.Label>Technology</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.panel?.technology || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="panelRatedOutputPower">
                                            <Form.Label>Rated Output Power</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.panel?.rated_output_power || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="panelWidth">
                                            <Form.Label>Width</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.panel?.width || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="panelHeight">
                                            <Form.Label>Height</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.panel?.height || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="panelThickness">
                                            <Form.Label>Thickness</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.panel?.thickness || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="panelWeight">
                                            <Form.Label>Weight</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.panel?.weight || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="panelVmp">
                                            <Form.Label>Vmp</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.panel?.vmp || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="panelVoc">
                                            <Form.Label>Voc</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.panel?.voc || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="panelIsc">
                                            <Form.Label>Isc</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.panel?.isc || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="panelImp">
                                            <Form.Label>Imp</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.panel?.imp || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="panelPricePerModule">
                                            <Form.Label>Price per Module</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.panel?.price_per_module || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="panelAdditionalCharges">
                                            <Form.Label>Panel Additional Charges</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.panel?.panel_additional_charges || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col md={4} className="separator_col">
                                    <Card.Title>Batteries</Card.Title>
                                    <Form>
                                        <Form.Group controlId="batteriesManufacturer">
                                            <Form.Label>Manufacturer</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.batteries?.manufacturer || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="batteriesSku">
                                            <Form.Label>SKU</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.batteries?.sku || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="batteriesTotalEnergy">
                                            <Form.Label>Total Energy</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.batteries?.total_energy || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="batteriesMaxContinuousPower">
                                            <Form.Label>Max Continuous Power</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.batteries?.max_continuous_power || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="batteriesEfficiency">
                                            <Form.Label>Efficiency</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.batteries?.efficiency || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="batteriesPricePerBattery">
                                            <Form.Label>Price per Battery</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.batteries?.price_per_battery || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="batteriesAdditionalCharges">
                                            <Form.Label>Battery Additional Charges</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.batteries?.battery_additional_charges || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col md={4}>
                                    <Card.Title>Inverters</Card.Title>
                                    <Form>
                                        <Form.Group controlId="invertersManufacturer">
                                            <Form.Label>Manufacturer</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.inverters?.manufacturer || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="invertersTechnology">
                                            <Form.Label>Technology</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.inverters?.technology || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="invertersRatedOutputPower">
                                            <Form.Label>Rated Output Power</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.inverters?.rated_output_power || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="invertersRatedOutputVoltage">
                                            <Form.Label>Rated Output Voltage</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.inverters?.rated_output_voltage || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="invertersNumberOfMppts">
                                            <Form.Label>Number of MPPTs</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.inverters?.number_of_mppts || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="invertersMinimumVmpp">
                                            <Form.Label>Minimum Vmpp</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.inverters?.minimum_vmpp || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="invertersMaximumVmpp">
                                            <Form.Label>Maximum Vmpp</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.inverters?.maximum_vmpp || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="invertersEfficiency">
                                            <Form.Label>Efficiency</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.inverters?.efficiency || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="invertersPhase">
                                            <Form.Label>Phase</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.inverters?.phase || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="invertersMaxAcOutputCurrent">
                                            <Form.Label>Max AC Output Current</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.inverters?.max_ac_output_current || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="invertersPricePerInverter">
                                            <Form.Label>Price per Inverter</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.inverters?.price_per_inverter || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="invertersAdditionalCharges">
                                            <Form.Label>Inverter Additional Charges</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data?.inverters?.inverter_additional_charges || 'N/A'}
                                                readOnly
                                            />
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card className="tariffcard shadow-sm border-0 rounded" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        {/* <Card.Body> */}
                        <Card.Title className="text-center mb-4">
                            <Card.Header as="h5" className="text-primary">Activity Tab</Card.Header>
                            {/* <span className="h5 text-primary">Activity Tab</span> */}
                        </Card.Title>
                        {data?.project_updates && data?.project_updates.length > 0 ? (
                            data?.project_updates?.map((update, index) => (
                                <div
                                    key={index}
                                    className="update-info row mb-3 p-3 rounded bg-light align-items-center"
                                >
                                    <div className="col-12 col-md-6 text-md-start text-center mb-2 mb-md-0">
                                        <h6 className="mb-1 text-dark">Updated By:</h6>
                                        <span className="text-muted">{update?.updated_by}</span>
                                    </div>
                                    <div className="col-12 col-md-6 text-md-end text-center">
                                        <h6 className="mb-1 text-dark">Updated At:</h6>
                                        <span className="text-muted">{formatDateTime(update?.updatedat)}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-center text-muted">No updates available</p>
                        )}

                        {/* </Card.Body> */}
                    </Card>
                </Col>
            </Row>
        </Container >
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.project.isLoading,
        fetchSingleProjectRes: state.project.fetchSingleViewProject
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSingleProjectData: (id) => dispatch(fetchSingleViewProjectAction(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleProject);
