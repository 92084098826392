const initState = {
    isLoading: false,
    saveFinance: '',
    updateFinance: '',
    fetchNoOfMonthsList: '',
    fetchProjectList: '',
    fetchAllFinance: '',
    fetchSingleFinance: '',
}

const financeReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FINANCE_LOADING':
            return { ...state, isLoading: true }
        case 'SAVE_FINANCE_REDUCER':
            return { ...state, saveFinance: action.payload, isLoading: false }
        case 'UPDATE_FINANCE_REDUCER':
            return { ...state, updateFinance: action.payload, isLoading: true }
        case 'FETCH_NO_OF_MONTHS':
            return { ...state, fetchNoOfMonthsList: action.payload, isLoading: false }
        case 'FETCH_PROJECT_LIST_REDUCER':
            return { ...state, fetchProjectList: action.payload, isLoading: false }
        case 'FETCH_ALL_FINANCE_REDUCER':
            return { ...state, fetchAllFinance: action.payload, isLoading: false }
        case 'FETCH_SINGLE_FINANCE_REDUCER':
            return { ...state, fetchSingleFinance: action.payload, isLoading: false }
        default:
            return state;
    }
}

export default financeReducer;