import React, { useMemo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, Col, Container, Row, Modal, Spinner, Button } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import "../../reacttable/reactTable.css";
import { fetchAllProjectApi } from "../../../../store/actions/projectAction";
import Table from "../../reacttable/table";
import { ImCloudDownload } from "react-icons/im";
import { downloadProposalAction } from "../../../../store/actions/customerAction.js";
import PureBreadcrumbs from "../../Breadcrums.js";
import TanStackTable from "../../reacttable/TanStackTable.js";

function DownloadProposalList(props) {

    useEffect(() => {
        props.fetchAllProject();
    }, []);

    const data = props.list?.data;
    // console.log("data", data)

    const handleDownload = (downloadUrl, project_title) => {
        // console.log(downloadUrl, project_title)

        if (downloadUrl) {
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'proposal.pdf');
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    };

    const columns = useMemo(
        () => [
            {
                header: 'First Name',
                accessorKey: 'contact_data.firstname',
            },
            {
                header: 'Last Name',
                accessorKey: 'contact_data.lastname',
            },
            {
                header: 'Email',
                accessorKey: 'contact_data.email'
            },
            // {
            //     header: 'Project Id',
            //     accessorKey: 'project_id'
            // },
            {
                header: "Actions",
                id: "proposal_url",
                accessorKey: "proposal_url",
                cell: ({ row }) => {
                    const proposal_value = row.original.proposal_url;
                    const image_value = row.original.image_url;
                    const rowData = row.original; // Access all row data
                    const project_title = rowData.project_title;
                    // console.log("project_title: ", project_title)
                    const buttonStyle = {
                        fontSize: "1em",
                        cursor: "pointer",
                        margin: "5px",
                        // minWidth: "150px",
                    };

                    const buttonContainerStyle = {
                        display: "flex",
                        // flexDirection: "column",
                        alignItems: "flex-center",
                        justifyContent: "center",
                    };

                    return (
                        <div style={buttonContainerStyle}>
                            <Row className="mb-2">
                                <Col xs={12} md={6}>
                                    {proposal_value ? (
                                        <Button
                                            variant="success"
                                            style={buttonStyle}
                                            onClick={() => handleDownload(proposal_value, project_title)}
                                        >
                                            Download Proposal
                                        </Button>
                                    ) : (
                                        <Button variant="secondary" style={buttonStyle} disabled>
                                            File is not available
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    {image_value ? (
                                        <Button
                                            variant="success"
                                            style={buttonStyle}
                                            onClick={() => handleDownload(image_value, project_title)}
                                        >
                                            Download Site Image
                                        </Button>
                                    ) : (
                                        <Button variant="secondary" style={buttonStyle} disabled>
                                            File is not available
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    );
                },
            },
        ],
        []
    );

    return (
        <Container fluid className="pb-5">
            <Row>
                <Col lg={6} sm={12} className="mt-4 mb-3">
                    <PureBreadcrumbs />
                </Col>
            </Row>

            <Row className="pt-5">
                <Col lg={12} sm={12} className="pad_t1">
                    <Card className="graphcard">
                        <Card.Header>
                            <Row>
                                <Col xs={12} md={8}>
                                    <b>
                                        <div className="text-left">Proposal File</div>
                                    </b>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <div className="">
                                {props.loading ? (
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh" }}>
                                        <h3>Loading</h3>
                                        <Loader
                                            type="ThreeDots"
                                            color="#232959"
                                            height="40"
                                            width="40"
                                        />
                                    </div>
                                ) : data && data.length > 0 ? (
                                    // <Table data={data} columns={columns} />
                                    <TanStackTable data={data} columns={columns} />
                                ) : (
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "300px" }}>
                                        <h3>No data found</h3>
                                    </div>
                                )}
                            </div>
                            {/* {data && data.length > 0 ? (
                                <div className="App">
                                    <Table data={data} columns={columns} />
                                </div>
                            ) : (
                                <div className="d-flex align-items-center justify-content-center">
                                    <span>Loading...</span>
                                    <h3 className="loader">
                                        <Loader
                                            type="ThreeDots"
                                            color="#232959"
                                            height="40"
                                            width="40"
                                            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                        />
                                    </h3>
                                </div>
                            )} */}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Container>
    );
}

const mapStateToProps = (state) => ({
    loading: state.customer.isLoading,
    list: state.project.fetchAllProject,
    downloadProposalRes: state.customer.downloadProposal,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAllProject: () => dispatch(fetchAllProjectApi()),
    fetchProposalURL: (id) => dispatch(downloadProposalAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadProposalList);
