import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, Button, Modal, CardTitle, Spinner } from 'react-bootstrap';
import PureBreadcrumbs from "../../Breadcrums"
import moment from "moment";
import { FaEdit, FaEye } from "react-icons/fa";
import "./leadGeneration.css"
import Loader from "react-loader-spinner";
import TanStackTable from "../../reacttable/TanStackTable";
import { deleteScheduleEventAction, fetchAllLeadEventAction } from "../../../../store/actions/leadAction";
import ScheduleEventCalendar from "./scheduleCalendar";
import { MdDelete } from "react-icons/md";



function ViewSchedule(props) {
    useEffect(() => {
        props.fetchAllLeadEventApi();
    }, [])

    const data = props.list && props.list.data;
    // console.log("data", data);

    const columns = useMemo(() => [
        {
            id: 'column1',
            header: 'Event Title',
            accessorKey: 'title',
            meta: {
                isVisible: true, // Set initial visibility here
            },
        },
        {
            header: 'Date',
            id: 'date', // optional custom id if needed
            accessorFn: (row) => moment(row.date).local().format('DD-MM-YYYY'),
        },
        {
            header: 'Start Time',
            id: 'starttime', // optional custom id if needed
            accessorFn: (row) => row.starttime,
        },
        {
            header: 'End Time',
            id: 'endtime', // optional custom id if needed
            accessorFn: (row) => row.endtime,

        },
        {
            header: 'Actions',
            accessorKey: 'id', // key used for linking actions
            cell: ({ cell }) => {
                const id = cell.getValue();
                return (
                    <div>
                        <span>
                            <MdDelete style={{ color: "var(--mandatory-field-streak-color)", fontSize: "20px", cursor: "pointer" }} onClick={(e) => props.deleteEventApi(id)} />
                        </span>
                        &nbsp;&nbsp;
                        <span>
                            <a href={`/dashboard/view_schedule/updateSingleEvent/${id}`} rel="tooltip" title="update">
                                <FaEdit />
                            </a>
                        </span>
                    </div>
                );
            },
        },
    ], []);

    useEffect(() => {
        if (props.deleteEventRes && props.deleteEventRes.status_code === 200) {
            alert(props.deleteEventRes.message);
            window.location.reload();
        } else if (props.deleteEventRes && props.deleteEventRes.status_code === 405) {
            alert(props.deleteEventRes.error)
            window.location.reload();
        }

    }, [props.deleteEventRes])
    // console.log("props.deleteEventRes", props.deleteEventRes)

    return (
        <Container fluid className="pb-5">
            <div className="row">
                <div className="col-lg-6 col-sm-12 mt-4 mb-3">
                    <PureBreadcrumbs />
                </div>
            </div>

            <Row className="my-3">
                <Col lg={12}>
                    <Card className="tariffcard">
                        <Card.Title>
                            Scheduled Event Calendar
                        </Card.Title>
                        <Card.Body>
                            {props.loading ? (
                                // Show loading spinner when data is being fetched
                                <div className="text-center d-flex justify-content-center align-items-center">
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                </div>
                            ) : data && data.length > 0 ? (
                                // Show the calendar if data exists
                                <ScheduleEventCalendar events={data} />
                            ) : (
                                // Show a message if no data is available
                                <div className="text-center d-flex justify-content-center align-items-center">
                                    <h5>No Event is available</h5>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <div className="row mt-3">
                <div className="col-lg-12 col-sm-12 pad_t1">
                    <Card className="graphcard">
                        <Card.Header>
                            <Row>
                                <Col xs={12} md={8}>
                                    <b><div className="text-left">Event List</div></b>
                                </Col>
                                <Col xs={12} md={4}>
                                    <b><div className="text-right">Total Events : {data && data.length ? data.length : 0}</div></b>
                                </Col>
                            </Row>
                        </Card.Header>
                        <div style={{ minHeight: "300px" }}>
                            {props.loading ? (
                                // Loading State
                                <div className="d-flex justify-content-center align-items-center" style={{ textAlign: "center" }}>
                                    <h3>Loading...</h3>
                                    <Loader
                                        type="ThreeDots"
                                        color="#232959"
                                        height={40}
                                        width={40}
                                    />
                                </div>
                            ) : data && data.length > 0 ? (
                                // Data State
                                <div className="">
                                    <TanStackTable data={data} columns={columns} />
                                </div>
                            ) : (
                                <div className="d-flex justify-content-center align-items-center" style={{ textAlign: "center" }}>
                                    <h3>No Data Found</h3>
                                </div>
                            )}
                        </div>
                    </Card>
                </div>
            </div>

        </Container>
    )

}

const mapStateToProps = (state) => {
    return {
        loading: state.lead.isLoading,
        list: state.lead.fetchAllLeadEventType,
        deleteEventRes: state.lead.deleteEvent,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        deleteEventApi: (id) => dispatch(deleteScheduleEventAction(id)),
        fetchAllLeadEventApi: () => dispatch(fetchAllLeadEventAction()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewSchedule);

