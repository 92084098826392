const initState = {
    isLoading: false,
    createNewLead: '',
    fetchAllLead: '',
    fetchSingleLead: '',
    updateLead: '',
    validateEmail: '',
    fetchLeadGraph: '',
    createEmailCampaign: '',
    createSmsCampaign: '',
    fetchAllEmail: '',
    fetchAllSms: '',
    fetchSingleEmail: '',
    fetchSingleSms: '',
    fetchCampaignGraph: '',
    updateLeadStatus: '',
    fetchInsightLeadCount: '',
    fetchInsightLeadStatus: '',
    saveLeadEventType: '',
    fetchAllLeadEventType: '',
    fetchSingleLeadEventType: '',
    updateLeadEventType: '',
    fetchTimeSlots: '',
    deleteEvent: '',
}

const leadReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LEAD_LOADING':
            return { ...state, isLoading: true }
        case 'CREATE_NEW_LEAD':
            return { ...state, createNewLead: action.payload, isLoading: false }
        case 'FETCH_ALL_LEAD':
            return { ...state, fetchAllLead: action.payload, isLoading: false }
        case 'FETCH_SINGLE_LEAD':
            return { ...state, fetchSingleLead: action.payload, isLoading: false }
        case 'UPDATE_LEAD_API':
            return { ...state, updateLead: action.payload, isLoading: false }
        case 'VALIDATE_LEAD_EMAIL_API':
            return { ...state, validateEmail: action.payload, isLoading: false }
        case 'FETCH_LEAD_GRAPH_API':
            return { ...state, fetchLeadGraph: action.payload, isLoading: false }
        case 'CREATE_EMAIL_CAMPAIGN_API':
            return { ...state, createEmailCampaign: action.payload, isLoading: false }
        case 'CREATE_SMS_CAMPAIGN_API':
            return { ...state, createSmsCampaign: action.payload, isLoading: false }
        case 'FETCH_ALL_EMAIL_API':
            return { ...state, fetchAllEmail: action.payload, isLoading: false }
        case 'FETCH_ALL_SMS_API':
            return { ...state, fetchAllSms: action.payload, isLoading: false }
        case 'FETCH_SINGLE_EMAIL_API':
            return { ...state, fetchSingleEmail: action.payload, isLoading: false }
        case 'FETCH_SINGLE_SMS_API':
            return { ...state, fetchSingleSms: action.payload, isLoading: false }
        case 'FETCH_CAMPAIGN_GRAPH_API':
            return { ...state, fetchCampaignGraph: action.payload, isLoading: false }
        case 'UPDATE_LEAD_STATUS_ACTION':
            return { ...state, updateLeadStatus: action.payload, isLoading: false }
        case 'FETCH_INSIGHT_LEAD_COUNT_API':
            return { ...state, fetchInsightLeadCount: action.payload, isLoading: false }
        case 'FETCH_INSIGHT_LEAD_STATUS_API':
            return { ...state, fetchInsightLeadStatus: action.payload, isLoading: false }
        case 'SAVE_LEAD_EVENT_TYPE_REDUCER':
            return { ...state, saveLeadEventType: action.payload, isLoading: false }
        case 'FETCH_ALL_LEAD_EVENT_TYPE_REDUCER':
            return { ...state, fetchAllLeadEventType: action.payload, isLoading: false }
        case 'FETCH_SINGLE_LEAD_EVENT_TYPE_REDUCER':
            return { ...state, fetchSingleLeadEventType: action.payload, isLoading: false }
        case 'UPDATE_LEAD_EVENT_TYPE_REDUCER':
            return { ...state, updateLeadEventType: action.payload, isLoading: false }
        case 'FETCH_TIME_SLOTS_REDUCER':
            return { ...state, fetchTimeSlots: action.payload, isLoading: false }
        case 'DELETE_SCHEDULED_EVENT_REDUCER':
            return { ...state, deleteEvent: action.payload, isLoading: false }
        default:
            return state;
    }
}

export default leadReducer;