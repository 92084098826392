import { FETCH_ALL_PROJECT_URL, FETCH_COUNTRY_CODE_API, FETCH_SINGLE_VIEW_PROJECT_API, NEW_PROJECT_URL, CUSTOMER_BASE_URL, UPDATE_PROJECT_URL, UPDATE_PROJECT_STATUS_API, FETCH_INSIGHT_PROJECT_COUNT_API, FETCH_INSIGHT_PROJECT_STATUS_API, FETCH_ROOF_TYPE_LIST, FETCH_DATA_SOURCE_LIST } from "../../components/config/config";
import { ApiHandler } from "./apiHandler";

//Create Project Action
export const createProjectAction = (data, panels, inverterData, batteryData, solarPanels, TotalCost, clientEmail, inverterQuantity, batteryQuantity, status) => {
  // console.log("clientEmailclientEmailclientEmail", clientEmail)
  // console.log("inverterQuantity", inverterQuantity)
  // console.log("batteryQuantity", batteryQuantity)
  // console.log("data", data)
  // console.log("panels", panels)
  // console.log("inverterData", inverterData)
  // console.log("batteryData", batteryData)
  // console.log("solarPanels", solarPanels)
  // console.log("TotalCost", TotalCost)
  // console.log("clientEmail", clientEmail)
  // console.log("inverterQuantity", inverterQuantity)
  // console.log("batteryQuantity", batteryQuantity)
  const { token, name, role, organisation } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  const { firstname, lastname, email, mobile, address, city, state, country, postal_code, latitude, longitude, project_title, assigned_to } = data
  const coordinates = { 'lat': parseFloat(latitude), 'lng': parseFloat(longitude) }
  const { cellular_coverage, data_source, electricity_utility, number_of_stories, phase, roof_type } = data.siteAndEnergyDetails ? data.siteAndEnergyDetails?.[0] : {};
  const site_details = { 'roof_type': roof_type, 'number_of_stories': parseInt(number_of_stories), 'cellular_coverage': cellular_coverage, 'phase': phase, 'electricity_utility': electricity_utility, 'energy_data_source': data_source }
  const contact_data = {
    "firstname": firstname,
    "lastname": lastname,
    "email": email,
    "mobile": mobile,
    "address": address,
    "coordinates": coordinates,
    "city": city,
    "state": state,
    "country": country,
    "postal_code": postal_code,
  }
  const panelObject = panels ? panels[0] : {};
  const inverterObject = inverterData ? inverterData[0] : {};
  const batteryObject = batteryData ? batteryData[0] : {};
  // console.log("site_details", site_details)
  return async dispatch => {
    dispatch({ type: 'PROJECT_LOADING', payload: '' })
    const data = await ApiHandler(CUSTOMER_BASE_URL + NEW_PROJECT_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "contact_data": contact_data,
        "site_details": site_details,
        "panel": panelObject,
        "batteries": batteryObject,
        "inverters": inverterObject,
        "panel_details": solarPanels,
        "total_project_cost": TotalCost,
        "user_email": clientEmail,
        "inverter_quantity": Number(inverterQuantity),
        "battery_quantity": Number(batteryQuantity),
        "project_title": project_title,
        "assigned_to": assigned_to,
        "status": status,
        "role": role,
        "operator": organisation,
      })
    })

    if (data) {
      // console.log("CREATE_NEW_PROJECT", data)
      dispatch({ type: 'CREATE_NEW_PROJECT', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

//Fetch All Project action
export const fetchAllProjectApi = () => {
  const { token, name, organisation, role } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'PROJECT_LOADING', payload: '' })
    // console.log(CUSTOMER_BASE_URL + FETCH_ALL_PROJECT_URL + "?name=" + name + "&role=" + role + "&organisation=" + organisation)
    const data = await ApiHandler(CUSTOMER_BASE_URL + FETCH_ALL_PROJECT_URL + "?name=" + name + "&role=" + role + "&organisation=" + organisation, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })

    if (data) {
      // console.log("FETCH_ALL_PROJECTS", data)
      dispatch({ type: 'FETCH_ALL_PROJECTS', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

//Fetch Single View Project action
export const fetchSingleViewProjectAction = (id) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'PROJECT_LOADING', payload: '' })
    const data = await ApiHandler(CUSTOMER_BASE_URL + FETCH_SINGLE_VIEW_PROJECT_API + "?id=" + id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })

    if (data) {
      // console.log("FETCH_SINGLE_VIEW_PROJECT", data)
      dispatch({ type: 'FETCH_SINGLE_VIEW_PROJECT', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

//Update Project action
export const updateProjectAction = (id, data, panels, inverterData, batteryData, solarPanels, TotalCost, clientEmail, inverterQuantity, batteryQuantity) => {
  const { token, name, role, organisation, userid } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  // console.log("id", id)
  // console.log("data", data)
  // console.log("panels", panels)
  // console.log("inverterData", inverterData)
  // console.log("batteryData", batteryData)
  // console.log("solarPanels", solarPanels)
  // console.log("TotalCost", TotalCost)
  // console.log("clientEmail", clientEmail)
  // console.log("inverterQuantity", inverterQuantity)
  // console.log("batteryQuantity", batteryQuantity)
  const { firstname, lastname, email, mobile, address, city, state, country, postal_code, latitude, longitude, project_title, assigned_to, status } = data
  const coordinates = { 'lat': parseFloat(latitude), 'lng': parseFloat(longitude) }
  const { cellular_coverage, data_source, electricity_utility, number_of_stories, phase, roof_type } = data.siteAndEnergyDetails ? data.siteAndEnergyDetails?.[0] : {};
  const site_details = { 'roof_type': roof_type, 'number_of_stories': parseInt(number_of_stories), 'cellular_coverage': cellular_coverage, 'phase': phase, 'electricity_utility': electricity_utility, 'energy_data_source': data_source }
  const contact_data = {
    "firstname": firstname,
    "lastname": lastname,
    "email": email,
    "mobile": mobile,
    "address": address,
    "coordinates": coordinates,
    "city": city,
    "state": state,
    "country": country,
    "postal_code": postal_code,
  }
  const panelObject = panels ? panels[0] : {};
  const inverterObject = inverterData ? inverterData[0] : {};
  const batteryObject = batteryData ? batteryData[0] : {};

  // Get today's date in the required format
  const today = new Date();
  const updatedAt = today.toISOString(); // This gives the format "YYYY-MM-DDTHH:MM:SSZ"
  const project_updates = [
    {
      updated_by: userid,
      updatedat: updatedAt
    }
  ];

  const actionData = {
    id: id,
    contact_data: contact_data,
    site_details: site_details,
    panel: panelObject,
    batteries: batteryObject,
    inverters: inverterObject,
    panel_details: solarPanels,
    total_project_cost: TotalCost,
    user_email: clientEmail,
    inverter_quantity: Number(inverterQuantity),
    battery_quantity: Number(batteryQuantity),
    project_title: project_title,
    assigned_to: assigned_to,
    status: status,
    project_updates: project_updates,
    role: role,
    operator: organisation,
  };

  // Console log the data
  // console.log("data from update action", actionData);

  return async dispatch => {
    dispatch({ type: 'PROJECT_LOADING', payload: '' })
    const data = await ApiHandler(CUSTOMER_BASE_URL + UPDATE_PROJECT_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "id": id,
        "contact_data": contact_data,
        "site_details": site_details,
        "panel": panelObject,
        "batteries": batteryObject,
        "inverters": inverterObject,
        "panel_details": solarPanels,
        "total_project_cost": TotalCost,
        "user_email": clientEmail,
        "inverter_quantity": Number(inverterQuantity),
        "battery_quantity": Number(batteryQuantity),
        "project_title": project_title,
        "assigned_to": assigned_to,
        "status": status,
        "project_updates": project_updates,
        "role": role,
        "operator": organisation,
      })
    })

    if (data) {
      // console.log("UPDATE_PROJECT_API", data)
      dispatch({ type: 'UPDATE_PROJECT_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchCountryCodeAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'PROJECT_LOADING', payload: '' })
    const data = await ApiHandler(CUSTOMER_BASE_URL + FETCH_COUNTRY_CODE_API, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })

    if (data) {
      // console.log("FETCH_COUNTRY_CODE_API", data)
      dispatch({ type: 'FETCH_COUNTRY_CODE_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

// update project status api
export const updateProjectStatusAction = (id, status) => {
  // console.log("id and status", id, status)
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'PROJECT_LOADING', payload: '' })
    const data = await ApiHandler(CUSTOMER_BASE_URL + UPDATE_PROJECT_STATUS_API, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "id": id,
        "status": status,
      })
    })

    if (data) {
      // console.log("UPDATE_PROJECT_STATUS_API", data)
      dispatch({ type: 'UPDATE_PROJECT_STATUS_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}
// FETCH INSIGHT PROJECT COUNT API
export const fetchInsightProjectCountAction = () => {
  const { token, role } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'PROJECT_LOADING', payload: '' })
    // console.log(CUSTOMER_BASE_URL + FETCH_INSIGHT_PROJECT_COUNT_API + "?role=" + role)
    const data = await ApiHandler(CUSTOMER_BASE_URL + FETCH_INSIGHT_PROJECT_COUNT_API + "?role=" + role, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })

    if (data) {
      // console.log("FETCH_PROJECT_COUNT_API", data)
      dispatch({ type: 'FETCH_PROJECT_COUNT_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

// FETCH INSIGHT PROJECT STATUS LIST API
export const fetchInsightProjectStatusAction = (status) => {
  // console.log("status from project action", status)
  const { token, role } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'PROJECT_LOADING', payload: '' })
    // console.log(CUSTOMER_BASE_URL + FETCH_INSIGHT_PROJECT_STATUS_API + "?role=" + role + "&status=" + status)
    const data = await ApiHandler(CUSTOMER_BASE_URL + FETCH_INSIGHT_PROJECT_STATUS_API + "?role=" + role + "&status=" + status, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })

    if (data) {
      // console.log("FETCH_INSIGHT_PROJECT_STATUS_LIST_API", data)
      dispatch({ type: 'FETCH_INSIGHT_PROJECT_STATUS_LIST_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

// Fetch roof type API with try-catch
export const fetchRoofTypeAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token;

  return async dispatch => {
    dispatch({ type: 'PROJECT_LOADING', payload: '' });

    try {
      const response = await fetch(CUSTOMER_BASE_URL + FETCH_ROOF_TYPE_LIST, {
        method: "GET",
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("FETCH_ROOF_TYPE_LIST----", data);
      dispatch({ type: 'FETCH_ROOF_TYPE_LIST', payload: data });
    } catch (error) {
      console.error("Error fetching roof type list:", error);
    }
  };
};

// Fetch data source API with try-catch
export const fetchDataSourceAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token;

  return async dispatch => {
    dispatch({ type: 'PROJECT_LOADING', payload: '' });

    try {
      const response = await fetch(CUSTOMER_BASE_URL + FETCH_DATA_SOURCE_LIST, {
        method: "GET",
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("FETCH_DATA_SOURCE_LIST----", data);
      dispatch({ type: 'FETCH_DATA_SOURCE_LIST', payload: data });
    } catch (error) {
      console.error("Error fetching roof type list:", error);
    }
  };
};