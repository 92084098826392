import { ADMIN_BASE_URL, SAVE_ACCESS_CONTROL_URL, PORTAL_USER_LIST_URL, VIEW_SINGLE_USERDATA_URL, EDIT_PORTAL_USER_URL, DEACTIVATE_USER_URL, USER_APPROVAL_URL, CHANGE_USER_PASSWORD_API } from "../../components/config/config"
import { ApiHandler } from "./apiHandler";

export const saveAccessControlAction = (userdata, access) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  const { first_name, last_name, userid, address, password, userRole, email, phone, organisation_type, organisation, country, city, state, postal_code } = userdata
  // console.log(userdata, role, access)
  return async dispatch => {
    dispatch({ type: 'ACCESS_LOADING', payload: '' })
    // console.log(ADMIN_BASE_URL + SAVE_ACCESS_CONTROL_URL)
    const data = await ApiHandler(ADMIN_BASE_URL + SAVE_ACCESS_CONTROL_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "first_name": first_name,
        "last_name": last_name,
        "userid": userid,
        "password": password,
        "role": userRole,
        "access": access,
        "email": email,
        "phone": parseInt(phone),
        "organisation_type": organisation_type,
        "organisation": organisation,
        "address": address,
        "country": country,
        "city": city,
        "state": state,
        "postal_code": postal_code,
        "subrole": []
      })
    })
    if (data) {
      dispatch({ type: 'SAVE_ACCESS_CONTROL', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const getPortalUserListAction = () => {
  const { token, organisation, role, user } = JSON.parse(localStorage.getItem('user'))
  // console.log("organisation", organisation, role)
  var bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'ACCESS_LOADING', payload: '' })
    // console.log("Adf",ADMIN_BASE_URL + PORTAL_USER_LIST_URL + "?role=" + role + "&organisation=" + organisation)
    const data = await ApiHandler(ADMIN_BASE_URL + PORTAL_USER_LIST_URL + "?role=" + role + "&organisation=" + organisation, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })

    if (data) {
      dispatch({ type: 'PORTAL_USER_LIST', payload: data })
    } else {
      console.log("No data found from server")
    }

  }
}

export const viewPortalUserData = (userid) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'ACCESS_LOADING', payload: '' })
    const data = await ApiHandler(ADMIN_BASE_URL + VIEW_SINGLE_USERDATA_URL + "?userid=" + userid, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      dispatch({ type: 'PORTAL_USER_VIEW', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const editPortalUserData = (userdata, role, access) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  const { first_name, last_name, userid, email, phone, organisation_type, organisation, country, city, state, postal_code, address } = userdata
  return async dispatch => {
    dispatch({ type: 'ACCESS_LOADING', payload: '' })
    const data = await ApiHandler(ADMIN_BASE_URL + EDIT_PORTAL_USER_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "first_name": first_name,
        "last_name": last_name,
        "userid": userid,
        "role": role,
        "access": access,
        "email": email,
        "phone": parseInt(phone),
        "organisation_type": organisation_type,
        "organisation": organisation,
        "address": address,
        "country": country,
        "city": city,
        "state": state,
        "postal_code": postal_code,
        "subrole": [],
      })
    })

    if (data) {
      dispatch({ type: 'PORTAL_USER_EDIT', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}


export const deactivatePortalUserData = (userid, accountstatus) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'ACCESS_LOADING', payload: '' })
    const data = await ApiHandler(ADMIN_BASE_URL + DEACTIVATE_USER_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "userid": userid,
        "accountstatus": accountstatus,
      })
    })

    if (data) {
      dispatch({ type: 'PORTAL_USER_DEACTIVATE', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const userApporvalBySuperAdmin = (userid, status) => {
  const { token } = JSON.parse(localStorage.getItem('user'))
  var bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'ACCESS_LOADING', payload: '' })
    const data = await ApiHandler(ADMIN_BASE_URL + USER_APPROVAL_URL, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "userid": userid,
        "status": status,
      })
    })

    if (data) {
      dispatch({ type: 'USER_APPROVAL_BYSUPERADMIN', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const updateUserPasswordAction = (record) => {
  const { password } = record;
  const { token, userid } = JSON.parse(localStorage.getItem('user'));
  // console.log("userid, password",userid)
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'ACCESS_LOADING', payload: '' })
    const data = await ApiHandler(ADMIN_BASE_URL + CHANGE_USER_PASSWORD_API, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        'userid': userid,
        'password': password
      })
    })
    if (data) {
      dispatch({ type: 'CHANGE_USER_PASSWORD_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}