import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import PureBreadcrumbs from "../../Breadcrums"
import moment from "moment";
import { FaEdit, FaEye } from "react-icons/fa";
import "./leadGeneration.css"
import Table from "../../reacttable/table";
import Loader from "react-loader-spinner";
import { fetchAllLeadAction, fetchTimeSlotsAction, saveLeadEventTypeAction, updateLeadAction, updateLeadStatusAction } from "../../../../store/actions/leadAction";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import TanStackTable from "../../reacttable/TanStackTable";

const validationSchema = Yup.object().shape({
    emails: Yup.array()
        .of(
            Yup.object().shape({
                email_id: Yup.string()
                    .email("Invalid email")
                    .required("Email I'd is required"),
            })
        )
        .required("At least one email is required"),
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    startDate: Yup.date().required("Start date is required").nullable(),
    startTime: Yup.string().required("Start time is required"),
    endTime: Yup.string().required("End time is required"),
});

const SchedulingModal = (props) => {
    // console.log("props", props);
    const { leadId, email, loading, fetchTimeSlotsRes } = props
    const [showModal, setShowModal] = useState(false);
    const today = new Date().toISOString().split('T')[0];
    const handleModalToggle = () => setShowModal(!showModal);

    const formatDateForBackend = (date) => {
        const dateObj = new Date(date); // Convert string to Date object

        // Extract the year, month, and day from the Date object
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
        const day = String(dateObj.getDate()).padStart(2, '0');

        // Return the formatted date as "YYYY-MM-DD"
        return `${year}-${month}-${day}`;
    };

    const { register, control, handleSubmit, reset, setValue, getValues, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            emails: [{ email_id: email }],
            title: "",
            description: "",
            startDate: null,
            startTime: "",
            endTime: "",
        },
    });
    const startTime = watch('startTime');

    const { fields, append, remove } = useFieldArray({
        control,
        name: "emails",
    });

    // Here we are resetting the form whenever pop-up opens
    useEffect(() => {
        if (showModal) {
            reset();
        }
    }, [showModal])

    // console.log("error", errors)
    // console.log("fetchTimeSlotsRes", fetchTimeSlotsRes.hours)

    const onSubmit = (data) => {
        const formattedStartDate = formatDateForBackend(data.startDate);
        const payload = {
            ...data,
            startDate: formattedStartDate,
        };
        // console.log(payload, leadId);
        props.saveLeadEventTypeApi(payload, leadId)
    };

    return (
        <>
            <div>
                <button
                    className="popup-btn"
                    onClick={handleModalToggle}
                    style={{ marginLeft: "10px", width: "100px", borderRadius: "4px" }}
                >
                    Schedule
                </button>

                <Modal
                    show={showModal}
                    onHide={handleModalToggle}
                    centered
                    size="lg" // Ensure the modal is wide enough for horizontal layout
                >
                    <Modal.Header closeButton>
                        <Modal.Title style={{ fontWeight: "600" }}>Scheduling Options</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <p style={{ fontSize: "14px" }}>Email ID: {email}</p> */}
                        <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "10px" }}>
                            {/* First Row: Email and Title side-by-side */}
                            <Row className="">
                                <Col md={7} className="form-group">
                                    <label className="form-label">Email Addresses</label>
                                    {fields && fields?.map((item, index) => (
                                        <div key={item.id} className="mb-3">
                                            <div className="d-flex align-items-center">
                                                <input
                                                    type="email"
                                                    className={`form-control ${errors.emails?.[index]?.email_id ? "is-invalid" : ""}`}
                                                    placeholder="Enter email"
                                                    disabled={index === 0 ? true : false}
                                                    {...register(`emails.${index}.email_id`)}
                                                />
                                                <button
                                                    type="button"
                                                    className="btn btn-danger mx-2"
                                                    onClick={() => remove(index)}
                                                    style={{ marginLeft: "10px", fontWeight: "bold" }}
                                                    disabled={index === 0}                                                 >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary"
                                                    onClick={() => append({ email_id: "" })}
                                                >
                                                    Add Email
                                                </button>
                                            </div>

                                            {errors.emails?.[index]?.email_id && (
                                                <p className="invalid-feedback d-block">
                                                    {errors.emails[index].email_id.message}
                                                </p>
                                            )}
                                        </div>
                                    ))}
                                </Col>

                                <Col md={5} className="form-group">
                                    <label className="form-label">Title</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.title ? "is-invalid" : ""}`}
                                        placeholder="Enter title"
                                        {...register("title")}
                                    />
                                    {errors.title && <p className="invalid-feedback">{errors.title.message}</p>}
                                </Col>
                            </Row>

                            {/* Second Row: Start Date and End Date side-by-side */}
                            <Row className="">
                                <Col md={4} className="form-group">
                                    <label className="form-label d-block">Schedule Date</label>
                                    <input
                                        type="date"
                                        min={today}
                                        className={`form-control ${errors.startDate ? "is-invalid" : ""}`}
                                        {...register("startDate")}
                                    />
                                    {errors.startDate && <p className="invalid-feedback">{errors.startDate.message}</p>}
                                </Col>

                                <Col md={4} className="form-group">
                                    <label className="form-label">Start Time</label>

                                    <select
                                        className={`input_css ${errors.startTime ? 'is-invalid' : ''}`}
                                        name="startTime"
                                        {...register("startTime")}
                                        id="floatingInputGridStartTime"
                                    >
                                        <option value="">{("Select Start Time")}</option>
                                        {fetchTimeSlotsRes &&
                                            fetchTimeSlotsRes?.hours &&
                                            fetchTimeSlotsRes?.hours
                                                .map((v, k) => (
                                                    <option key={v.key} value={v.value}>
                                                        {v.value}
                                                    </option>
                                                ))}
                                    </select>
                                    {errors.startTime && <p className="invalid-feedback">{errors.startTime.message}</p>}
                                </Col>
                                <Col md={4} className="form-group">
                                    <label className="form-label">End Time</label>
                                    <select
                                        className={`input_css ${errors.endTime ? 'is-invalid' : ''}`}
                                        name="endTime"
                                        {...register("endTime")}
                                        id="floatingInputGridEndTime"
                                    >
                                        <option value="">{("Select End Time")}</option>
                                        {fetchTimeSlotsRes &&
                                            fetchTimeSlotsRes.hours &&
                                            fetchTimeSlotsRes.hours
                                                ?.map((v, k) => {
                                                    const isDisabled = startTime !== "23:59" && v.value < startTime;
                                                    // console.log("isDisabled", isDisabled)
                                                    return (
                                                        <option key={v.key} value={v.value} disabled={isDisabled}>
                                                            {v.value}
                                                        </option>
                                                    );
                                                })}

                                    </select>
                                    {errors.endTime && <p className="invalid-feedback">{errors.endTime.message}</p>}
                                </Col>
                            </Row>

                            {/* Third Row: Description */}
                            <Row className="">
                                <Col md={12} className="form-group">
                                    <label className="form-label">Description</label>
                                    <textarea
                                        className={`form-control ${errors.description ? "is-invalid" : ""}`}
                                        placeholder="Enter description"
                                        rows="2"
                                        {...register("description")}
                                    />
                                    {errors.description && <p className="invalid-feedback">{errors.description.message}</p>}
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col lg={6} md={6} className="mt-2">
                                    <button className="lgn-btn" onClick={() => reset()}>RESET</button>
                                </Col>
                                <Col lg={6} md={6} className='mt-2 d-md-flex justify-content-end '>
                                    {loading ?
                                        <button className="lgn-btn" type="button" disabled>
                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                            &nbsp;
                                            {("Saving...")}
                                        </button>
                                        :
                                        <button className="lgn-btn" type="submit">{("Schedule")}</button>
                                    }
                                </Col>
                            </Row>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalToggle}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

function ViewLead(props) {
    // const [customLoading, setCustomLoading] = useState(false);
    useEffect(() => {
        props.fetchAllLeadApi();
        props.fetchTimeSlotsApi();
    }, [])

    const data = props.list && props.list.data;
    const timeSlots = props.fetchTimeSlotsRes?.hours
    // console.log("timeSlots", timeSlots);
    const NewleadCount = data && data.filter(en => en.lead_status === "prospecting").length;
    const contactedCount = data && data.filter(en => en.lead_status === "contacted").length;
    const proposaldelivered = data && data.filter(en => en.lead_status === "proposal_delivered").length;
    const closedLostCount = data && data.filter(en => en.lead_status === "closed_Lost").length;
    const closedWonCount = data && data.filter(en => en.lead_status === "closed_Won").length;

    const handleStatusChange = (lead_id, e) => {
        const status = e.target.value;
        // console.log("data & status", lead_id, status)
        if (status) props.updateLeadStatusApi(lead_id, status);
    };

    if (props.updateStatusLeadRes.statuscode === 200) {
        window.location.reload();
    }

    useEffect(() => {
        if (props.saveLeadEventTypeRes.status_code === 200) {
            alert(props.saveLeadEventTypeRes.message)
            window.location.reload();
        } else if (props.saveLeadEventTypeRes.status_code === 405) {
            alert(props.saveLeadEventTypeRes.message)
            window.location.reload();
        }

    }, [props.saveLeadEventTypeRes])

    const columns = useMemo(() => [
        {
            id: 'column1',
            header: 'First Name',
            accessorKey: 'firstname',
            meta: {
                isVisible: true, // Set initial visibility here
            },
        },
        {
            header: 'Last Name',
            accessorKey: 'lastname',
        },
        {
            header: 'Email',
            accessorKey: 'email',
        },
        {
            header: 'Mobile',
            accessorKey: 'mobile',
        },
        {
            header: 'Lead Source',
            accessorKey: 'leadsource',
        },
        {
            header: 'Creation Date',
            id: 'createat', // optional custom id if needed
            accessorFn: (row) => moment(row.createat).local().format('DD-MM-YYYY'), // accessorFn replaces accessor for computed values
        },
        {
            header: 'Status',
            id: 'id1',
            accessorKey: 'lead_id', // set the key for accessing lead_id in row data
            cell: ({ row }) => {
                const status = row.original.lead_status;
                const lead_id = row.original.lead_id;

                return (
                    <div>
                        <select className="" onChange={(e) => handleStatusChange(lead_id, e)}>
                            <option value={status} hidden>
                                {status}
                            </option>
                            <option value="" disabled>
                                Select Lead Status
                            </option>
                            <option value="prospecting">Prospecting</option>
                            <option value="contacted">Contacted</option>
                            <option value="proposal_delivered">Proposal Delivered</option>
                            <option value="closed_Lost">Closed/Lost</option>
                            <option value="closed_Won">Closed/Won</option>
                        </select>
                    </div>
                );
            },
        },
        {
            header: 'Actions',
            accessorKey: 'id', // key used for linking actions
            cell: ({ cell }) => {
                const id = cell.getValue();
                return (
                    <div>
                        <span>
                            <a href={`/dashboard/lead/viewSingleLead/${id}`} rel="tooltip" title="View">
                                <FaEye />
                            </a>
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                            <a href={`/dashboard/updateLead/${id}`} rel="tooltip" title="Edit">
                                <FaEdit />
                            </a>
                        </span>
                    </div>
                );
            },
        },
        {
            header: "Scheduling",
            id: "popup-scheduling",
            accessorKey: "lead_id",
            cell: ({ cell }) => {
                const id = cell.getValue();
                const email = cell.row?.original?.email;
                // console.log("email", email)
                return (
                    <SchedulingModal
                        leadId={id}
                        email={email}
                        {...props}
                    />
                );
            },
        },
    ], [props]);

    return (
        <Container fluid className="pb-5">
            <div className="row">
                <div className="col-lg-6 col-sm-12 mt-4 mb-3">
                    <PureBreadcrumbs />
                </div>
            </div>

            <Row className=' d-flex flex-row flex-nowrap'>
                <Col xs={12} md={6} lg={2} className="mt-3 flex-grow-1">
                    <Card className='statuscard'>
                        <div style={{ textAlign: "center" }}>
                            <span className="cardtext"> &nbsp;Prospecting</span>
                        </div>
                        <span className="count" style={{ textAlign: "center" }}>
                            {NewleadCount ? NewleadCount : 0}
                        </span>
                    </Card>
                </Col>

                <Col xs={12} md={6} lg={2} className="mt-3 flex-grow-1">
                    <Card className='statuscard'>
                        <div style={{ textAlign: "center" }}>
                            <span className="cardtext"> &nbsp;Contacted</span>
                        </div>
                        <span className="count" style={{ textAlign: "center" }}>
                            {contactedCount ? contactedCount : 0}
                        </span>
                    </Card>
                </Col>

                <Col xs={12} md={6} lg={2} className="mt-3 flex-grow-1">
                    <Card className='statuscard'>
                        <div style={{ textAlign: "center" }}>
                            <span className="cardtext"> &nbsp;Proposal Delivered</span>
                        </div>
                        <span className="count" style={{ textAlign: "center" }}>
                            {proposaldelivered ? proposaldelivered : 0}
                        </span>
                    </Card>
                </Col>

                <Col xs={12} md={6} lg={2} className="mt-3 flex-grow-1">
                    <Card className='statuscard'>
                        <div style={{ textAlign: "center" }}>
                            <span className="cardtext"> &nbsp;Closed/Lost</span>
                        </div>
                        <span className="count" style={{ textAlign: "center" }}>
                            {closedLostCount ? closedLostCount : 0}
                        </span>
                    </Card>
                </Col>

                <Col xs={12} md={6} lg={2} className="mt-3 flex-grow-1">
                    <Card className='statuscard'>
                        <div style={{ textAlign: "center" }}>
                            <span className="cardtext"> &nbsp;Closed/Won</span>
                        </div>
                        <span className="count" style={{ textAlign: "center" }}>
                            {closedWonCount ? closedWonCount : 0}
                        </span>
                    </Card>
                </Col>
            </Row>
            <div className="row mt-3">
                <div className="col-lg-12 col-sm-12 pad_t1">
                    <Card className="graphcard">
                        <Card.Header>
                            <Row>
                                <Col xs={12} md={8}>
                                    <b><div className="text-left">Lead List</div></b>
                                </Col>
                                <Col xs={12} md={4}>
                                    <b><div className="text-right">Total Leads : {data && data.length ? data.length : 0}</div></b>
                                </Col>
                            </Row>
                        </Card.Header>
                        <div style={{ minHeight: "300px" }}>
                            {props.loading ? (
                                // Loading State
                                <div className="d-flex justify-content-center align-items-center" style={{ textAlign: "center", height: "300px" }}>
                                    <h3>Loading...</h3>
                                    <Loader
                                        type="ThreeDots"
                                        color="#232959"
                                        height={40}
                                        width={40}
                                    />
                                </div>
                            ) : data && data.length > 0 ? (
                                // Data State
                                <div className="">
                                    <TanStackTable data={data} columns={columns} />
                                </div>
                            ) : (
                                // No Data Found State
                                <div style={{ textAlign: "center" }}>
                                    <h3>No Data Found</h3>
                                </div>
                            )}
                        </div>
                    </Card>
                </div>
            </div>
        </Container>
    )

}

const mapStateToProps = (state) => {
    return {
        loading: state.lead.isLoading,
        list: state.lead.fetchAllLead,
        updateStatusLeadRes: state.lead.updateLeadStatus,
        saveLeadEventTypeRes: state.lead.saveLeadEventType,
        fetchTimeSlotsRes: state.lead.fetchTimeSlots,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateLeadStatusApi: (lead_id, lead_status) => dispatch(updateLeadStatusAction(lead_id, lead_status)),
        saveLeadEventTypeApi: (data, leadId) => dispatch(saveLeadEventTypeAction(data, leadId)),
        fetchAllLeadApi: () => dispatch(fetchAllLeadAction()),
        fetchTimeSlotsApi: () => dispatch(fetchTimeSlotsAction()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewLead);

