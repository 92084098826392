import React, { useState } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getGroupedRowModel,
    getExpandedRowModel,
    flexRender,
} from '@tanstack/react-table';
import { Table, Button, InputGroup, Row, Col, Container } from 'react-bootstrap';
import { FaFilter } from 'react-icons/fa'; // Importing the filter icon

const TanStackTable = ({ columns, data }) => {
    const [sorting, setSorting] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [rowSelection, setRowSelection] = useState({});
    const [filterVisible, setFilterVisible] = useState(false); // State to toggle filter visibility

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            columnFilters,
            globalFilter,
            rowSelection,
        },
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        enableRowSelection: true,
    });

    const handleToggleFilters = () => {
        setFilterVisible((prev) => !prev); // Toggle visibility state
    };

    return (
        <Container fluid className="mt-4">
            {/* Global Filter */}
            <Row className="mb-2 d-flex justify-content-end">
                <Col className="d-flex justify-content-end">
                    <InputGroup className="mb-1 d-flex justify-content-around align-items-center" style={{ width: '20rem' }}>
                        <input
                            type="text"
                            value={globalFilter}
                            onChange={(e) => setGlobalFilter(e.target.value)}
                            placeholder="🔍 Search..."
                            className="SearchFilter"
                        />
                        <FaFilter
                            className="ms-2"
                            style={{ cursor: 'pointer' }}
                            size={20}
                            onClick={handleToggleFilters}
                        />
                    </InputGroup>
                </Col>
            </Row>

            {filterVisible && (
                <Row className="mb-3">
                    <Col>
                        <div style={{ border: '1px solid #ced4da', padding: '7px', borderRadius: '5px' }}>
                            <h6 className="mb-3">Toggle Column Visibility:</h6>
                            <Row>
                                {table.getAllColumns().map((column) => (
                                    <Col key={column.id} xs={6} md={4} lg={2} className="mb-1">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="checkbox"
                                                checked={column.getIsVisible()}
                                                onChange={() => column.toggleVisibility()}
                                                style={{ marginRight: '10px' }}
                                            />
                                            <label>{column.columnDef.header}</label>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Col>
                </Row>
            )}

            {/* Responsive Scrollable Table */}
            <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
                <table className="table table-hover">
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th key={header.id} style={{ position: 'relative', cursor: 'pointer' }}>
                                        {header.isPlaceholder ? null : (
                                            <div onClick={() => header.column.toggleSorting()} className="d-flex justify-content-center align-items-center">
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {header.column.getIsSorted() === 'asc' ? ' 🔼' : header.column.getIsSorted() === 'desc' ? ' 🔽' : ''}
                                            </div>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Pagination Controls */}
            <Row className="justify-content-center align-items-center mt-4">
                <Col lg={2} md={4} xs="4" className="m-2 justify-content-start">
                    <span>
                        Page <strong>{table.getState().pagination.pageIndex + 1}</strong> of <strong>{table.getPageCount()}</strong>
                    </span>
                </Col>

                <Col lg={4} md={4} xs="4" className="m-2 d-flex justify-content-center align-items-center">
                    <span className="mr-2">Page:</span>
                    <input
                        type="number"
                        defaultValue={table.getState().pagination.pageIndex + 1}
                        onChange={(e) => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            table.setPageIndex(page);
                        }}
                        className="inputPage"
                        style={{ width: '50px', height: '25px' }}
                    />
                    <span className="mr-2 ml-3">Rows:</span>
                    <select
                        className="inputPage"
                        value={table.getState().pagination.pageSize}
                        onChange={(e) => {
                            table.setPageSize(Number(e.target.value));
                        }}
                        style={{ width: '50px', height: '25px' }}
                    >
                        {[10, 25, 50, 75, 100, 500, 1000].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </Col>

                <Col lg={4} md={4} xs="4" className="m-2 d-flex justify-content-end align-items-center">
                    <button
                        variant="outline-secondary"
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                        style={{ marginRight: '5px' }}
                        className="columnsBtn btn-sm mr-2"
                    >
                        &lt;&lt; First
                    </button>
                    <button
                        variant="outline-secondary"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                        style={{ marginRight: '5px' }}
                        className="columnsBtn btn-sm mr-2"
                    >
                        Previous
                    </button>
                    <button
                        variant="outline-secondary"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                        style={{ marginRight: '5px' }}
                        className="columnsBtn btn-sm mr-2"
                    >
                        Next
                    </button>
                    <button
                        variant="outline-secondary"
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                        className="columnsBtn btn-sm mr-2"
                    >
                        Last &gt;&gt;
                    </button>
                </Col>
            </Row>
        </Container>
    );
};

export default TanStackTable;
