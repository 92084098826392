import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const handleSelectEvent = (event) => {
    const startDate = moment(event.start).format('YYYYMMDDTHHmmSS');
    const endDate = moment(event.end).format('YYYYMMDDTHHmmSS');
    const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event.title)}&dates=${startDate}/${endDate}&details=${encodeURIComponent(event.description || '')}`;
    window.open(googleCalendarUrl, '_blank');
};

// Helper function to convert your event data into calendar events
const mapEventsToCalendar = (events) => {
    return events && events.map((event) => {
        const { date, starttime, endtime, title, description } = event;

        // Combine date with start and end times
        const startDateTime = moment(`${date} ${starttime}`, 'YYYY-MM-DD HH:mm').toDate();
        const endDateTime = moment(`${date} ${endtime}`, 'YYYY-MM-DD HH:mm').toDate();

        return {
            id: event.id,
            title: title || 'No Title', // Default title if none is provided
            start: startDateTime,        // Calendar expects a Date object
            end: endDateTime,            // Calendar expects a Date object
            description: description || '', // Optional, can be used to show more details
            allDay: false, // This is a timed event, not all-day
        };
    });
};

// Function to add gradient background to event styles
const eventStyleGetter = (event, start, end, isSelected) => {
    const gradientStyle = {
        background: 'linear-gradient(135deg, #84fab0 0%, #8fd3f4 100%)', // Example gradient
        color: 'black', // Text color
        border: 'none', // Remove default border
        borderRadius: '4px', // Rounded corners
        padding: '4px', // Padding inside the event box
    };

    return {
        style: gradientStyle,
    };
};

function ScheduleEventCalendar(props) {
    // Map your event data into the calendar format
    const calendarEvents = mapEventsToCalendar(props.events);

    return (
        <div>
            <Calendar
                events={calendarEvents || []} // Pass the mapped events to the calendar
                localizer={localizer}
                style={{ height: "80vh" }} // Adjust height as needed
                onSelectEvent={handleSelectEvent} // Callback for event selection
                eventPropGetter={eventStyleGetter} // Apply the gradient styling
            />
        </div>
    );
}

export default ScheduleEventCalendar;
